export const StationAccessibility = {
  features: [
    {
      type: 'Feature',
      properties: { id: 1, name: 'Магазин Локомотив' },
      geometry: {
        type: 'Point',
        coordinates: [92.759221809457259, 56.041280621511113],
      },
    },
    {
      type: 'Feature',
      properties: { id: 2, name: 'Стадион Локомотив' },
      geometry: {
        type: 'Point',
        coordinates: [92.762104285368395, 56.04069965737397],
      },
    },
    {
      type: 'Feature',
      properties: { id: 3, name: 'Северо-Западный район' },
      geometry: {
        type: 'Point',
        coordinates: [92.770175217919544, 56.038235028746108],
      },
    },
    {
      type: 'Feature',
      properties: { id: 4, name: 'Северо-Западный район' },
      geometry: {
        type: 'Point',
        coordinates: [92.76964731511643, 56.039202638969826],
      },
    },
    {
      type: 'Feature',
      properties: { id: 5, name: 'Северо-Западный район' },
      geometry: {
        type: 'Point',
        coordinates: [92.76846655890364, 56.039647561600738],
      },
    },
    {
      type: 'Feature',
      properties: { id: 6, name: 'База УМТС' },
      geometry: {
        type: 'Point',
        coordinates: [92.773839855292536, 56.044176645818148],
      },
    },
    {
      type: 'Feature',
      properties: { id: 7, name: 'База УМТС' },
      geometry: {
        type: 'Point',
        coordinates: [92.773714364294037, 56.044286450441795],
      },
    },
    {
      type: 'Feature',
      properties: { id: 8, name: 'База УМТС' },
      geometry: {
        type: 'Point',
        coordinates: [92.770531456242026, 56.047614813969297],
      },
    },
    {
      type: 'Feature',
      properties: { id: 9, name: 'База УМТС' },
      geometry: {
        type: 'Point',
        coordinates: [92.769550344799441, 56.048208044143834],
      },
    },
    {
      type: 'Feature',
      properties: { id: 10, name: 'Пожарная часть' },
      geometry: {
        type: 'Point',
        coordinates: [92.762659748156338, 56.049696823716552],
      },
    },
    {
      type: 'Feature',
      properties: { id: 11, name: 'Пожарная часть' },
      geometry: {
        type: 'Point',
        coordinates: [92.761621595350789, 56.050039071894197],
      },
    },
    {
      type: 'Feature',
      properties: { id: 12, name: 'Ботанический бульвар' },
      geometry: {
        type: 'Point',
        coordinates: [92.755044726203863, 56.041066465503782],
      },
    },
    {
      type: 'Feature',
      properties: { id: 13, name: 'Батанический бульвар' },
      geometry: {
        type: 'Point',
        coordinates: [92.750686766075276, 56.041271814410365],
      },
    },
    {
      type: 'Feature',
      properties: { id: 14, name: 'Поселок ГЭС' },
      geometry: {
        type: 'Point',
        coordinates: [92.747319963480038, 56.041054096406498],
      },
    },
    {
      type: 'Feature',
      properties: { id: 15, name: 'Поселок ГЭС' },
      geometry: {
        type: 'Point',
        coordinates: [92.746350467069803, 56.041282213208909],
      },
    },
    {
      type: 'Feature',
      properties: { id: 16, name: 'КПАТП-5' },
      geometry: {
        type: 'Point',
        coordinates: [92.781457151602012, 56.041944896411863],
      },
    },
    {
      type: 'Feature',
      properties: { id: 17, name: 'КПАТП-5' },
      geometry: {
        type: 'Point',
        coordinates: [92.780225058162515, 56.042720658947843],
      },
    },
  ],
};
