import { useUnit } from 'effector-react';
import { useMemo } from 'react';

import { Alert, Box } from '@mui/material';

import { EAgScenarioStatusType } from '@features/ag-forecast/constants/agScenarioStatuses';
import { $FeatureSettings } from '@features/ag-forecast/stores/settings';
import { $UI } from '@features/ag-forecast/stores/ui';

export const RecalculationNotification = () => {
  const { scenarioStatus } = useUnit($FeatureSettings);
  const { filter, inspector } = useUnit($UI);

  const offset = useMemo(() => {
    if (filter && inspector) return 0;
    else if (filter) return 290;
    else if (inspector) return -290;
    else return 0;
  }, [filter, inspector]);

  if (
    scenarioStatus === EAgScenarioStatusType.Queue ||
    scenarioStatus === EAgScenarioStatusType.Pending
  )
    return (
      <Box
        sx={{
          position: 'absolute',
          top: theme => theme.spacing(10),
          left: 'calc(50% - 200px)',
          zIndex: 10,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Alert
          variant="filled"
          severity="info"
          icon={false}
          sx={{
            height: 'min-content',
            width: '400px',
            justifyContent: 'center',
            transform: `translateX(${offset}px)`,
          }}
        >
          {scenarioStatus === EAgScenarioStatusType.Queue
            ? 'Сценарий в очереди на расчет'
            : 'Сценарий рассчитывается'}
        </Alert>
      </Box>
    );
};
