import { useUnit } from 'effector-react';
import { useCallback, useMemo, useState } from 'react';

import { Box, Divider, IconButton, List, ListItem, Stack } from '@mui/material';

import { BasketIcon, PathWithBorderIcon } from '@components/icons';
import { AddTerminalRailwayStation } from '@components/icons/AddTerminalRailwayStation';
import { FreeCircleIcon } from '@components/icons/FreeCircleIcon/FreeCircleIcon';

import {
  $EditorMap,
  CustomRailwayStationItem,
  deleteStationFx,
} from '@features/pt-forecast-new/stores/map/editor';

export const AssociatedStations = () => {
  const [selectedItem, setSelected] = useState('');
  const { graphs, stations } = useUnit($EditorMap);

  const handleSelectItem = useCallback((id: string) => setSelected(id), []);

  const listObjects = useMemo(
    () =>
      graphs.map(graph => {
        const foundStations: CustomRailwayStationItem[] = [];
        graph.geometry.forEach(coord => {
          const station = stations.find(
            station =>
              (coord.lat === station.geometry.lat &&
                coord.lon === station.geometry.lon) ||
              (Math.abs(coord.lat - station.geometry.lat) < 0.000001 &&
                Math.abs(coord.lon - station.geometry.lon) < 0.000001),
          );
          station && foundStations.push(station);
        });

        return {
          graph,
          stations: foundStations,
        };
      }),
    [graphs, stations],
  );

  const stationExists = useCallback(
    (station: CustomRailwayStationItem, data: typeof listObjects) =>
      data.some(item =>
        item.stations.some(
          s =>
            s.geometry.lat === station.geometry.lat &&
            s.geometry.lon === station.geometry.lon,
        ),
      ),
    [],
  );

  const missingStations = stations.filter(
    station => !stationExists(station, listObjects),
  );

  const handleDeleteGraph = useCallback(() => {}, []);

  const handleDeleteStation = useCallback((id: string) => {
    console.log(id);
  }, []);

  const handleDeletestationExist = useCallback((tpuId: string) => {
    deleteStationFx({ tpuId });
  }, []);

  return (
    <Stack>
      {listObjects.map((object, i) => (
        <Box
          key={'railway-' + i}
          sx={{ px: 3 }}
        >
          {!!i && <Divider sx={{ pt: 2, mb: 2 }} />}

          <List>
            <ListItem
              key={object.graph.id + i}
              className={selectedItem === object.graph.id ? 'selected' : ''}
              sx={{
                pl: 0.5,
                py: 0,
                columnGap: 1,
                '&:hover, &.selected': {
                  bgcolor: 'customs.bgHover',
                  borderRadius: '4px',
                },
              }}
              secondaryAction={
                selectedItem === object.graph.id && (
                  <IconButton
                    size="small"
                    color="inherit"
                    sx={{ p: 0.4 }}
                    onClick={handleDeleteGraph}
                  >
                    <BasketIcon />
                  </IconButton>
                )
              }
              onClick={() => handleSelectItem(object.graph.id)}
            >
              <FreeCircleIcon
                color="white"
                border="#7A5A5A"
                size={14}
              />

              {object.graph.name}
            </ListItem>

            {object.stations.map((station, j) => (
              <Box key={station.id + ' ' + object.graph.id}>
                <Box sx={{ my: -1 }}>
                  <PathWithBorderIcon
                    path="white"
                    border="#7A5A5A"
                  />
                </Box>

                <ListItem
                  className={
                    selectedItem === station.id + ' ' + object.graph.id
                      ? 'selected'
                      : ''
                  }
                  sx={{
                    pl: 0,
                    py: 0,
                    columnGap: 0.5,
                    '&:hover, &.selected': {
                      bgcolor: 'customs.bgHover',
                      borderRadius: '4px',
                    },
                  }}
                  secondaryAction={
                    selectedItem === station.id + ' ' + object.graph.id &&
                    j !== 0 &&
                    j !== object.stations.length - 1 && (
                      <IconButton
                        size="small"
                        color="inherit"
                        sx={{ p: 0.4 }}
                        onClick={() => handleDeleteStation(station.id)}
                      >
                        <BasketIcon />
                      </IconButton>
                    )
                  }
                  onClick={() =>
                    handleSelectItem(station.id + ' ' + object.graph.id)
                  }
                >
                  <AddTerminalRailwayStation
                    width="32px"
                    height="32px"
                  />
                  {station.name}
                </ListItem>
              </Box>
            ))}
          </List>
        </Box>
      ))}

      {!!listObjects.length && !!missingStations.length && (
        <Divider sx={{ pt: 3, mb: 3 }} />
      )}

      {missingStations.map((station, i) => (
        <Box
          key={'station-' + i}
          sx={{ px: 3 }}
        >
          {!!i && <Divider sx={{ pt: 2, mb: 2 }} />}

          <List>
            <ListItem
              key={station.id + i}
              className={selectedItem === station.id ? 'selected' : ''}
              sx={{
                pl: 0,
                py: 0,
                columnGap: 0.5,
                '&:hover, &.selected': {
                  bgcolor: 'customs.bgHover',
                  borderRadius: '4px',
                },
              }}
              secondaryAction={
                selectedItem === station.id && (
                  <IconButton
                    size="small"
                    color="inherit"
                    sx={{ p: 0.4 }}
                    onClick={() => handleDeletestationExist(station.id)}
                  >
                    <BasketIcon />
                  </IconButton>
                )
              }
              onClick={() => handleSelectItem(station.id)}
            >
              <AddTerminalRailwayStation
                width="32px"
                height="32px"
              />

              {station.name}
            </ListItem>
          </List>
        </Box>
      ))}
    </Stack>
  );
};
