import { Divider, Grid, Typography } from '@mui/material';

import { EFilterMapLayout } from '@components/MapFilters/interface';
import { AirportIcon } from '@components/icons/Airport';
import { BusStationIcon } from '@components/icons/BusStation';
import { RailsIcon } from '@components/icons/Rails';
import { TrainIcon } from '@components/icons/Train';

import { CircleIcon } from './CircleIcon';
import { LineIcon } from './LineIcon';

type LegendProps = {
  layout: EFilterMapLayout;
  isVisibleInspector?: boolean;
};

export const Legend = (props: LegendProps) => {
  const { layout, isVisibleInspector } = props;

  return (
    <Grid
      container
      direction={'column'}
      flexWrap={'nowrap'}
      sx={{
        backgroundColor: theme => theme.palette.common.white,
        maxHeight: 'calc(100% - 260px);',
        overflow: 'hidden',
        width: '420px',
        borderRadius: theme => theme.shape.borderRadius,
        position: 'absolute',
        top: theme => theme.typography.pxToRem(16 + 132 + 40),
        right: theme =>
          theme.typography.pxToRem(isVisibleInspector ? 500 + 24 : 24),
        zIndex: 1,
      }}
      p={'44px 12px 32px 44px'}
    >
      <Grid
        item
        mb={3}
      >
        <Typography
          variant={'subtitle2'}
          gutterBottom
        >
          {layout === EFilterMapLayout.Infrastructure
            ? 'Инфраструктура'
            : layout === EFilterMapLayout.RouteNetwork
            ? 'Маршрутная сеть'
            : 'Пассажиропотоки'}
        </Typography>
      </Grid>

      <Grid
        container
        item
        gap={1.5}
        sx={{
          overflowY: 'auto',
        }}
        pr={3}
      >
        {layout === EFilterMapLayout.Infrastructure ? (
          <>
            <Grid
              display={'grid'}
              container
              gridTemplateColumns={'2fr 10fr'}
              gap={2}
              alignItems={'center'}
            >
              <LineIcon color={'transportAreaInspectorBoundaries'} />
              <Typography
                variant="body2"
                color="text.secondary"
              >
                Границы транспортных районов
              </Typography>
            </Grid>

            <Grid
              display={'grid'}
              container
              gridTemplateColumns={'2fr 10fr'}
              gap={2}
              alignItems={'center'}
            >
              <CircleIcon color={'transportAreaInspectorBoundaries'} />
              <Typography
                variant="body2"
                color="text.secondary"
              >
                Центры транспортных районов
              </Typography>
            </Grid>

            <Divider
              sx={{
                borderColor: theme => theme.palette.border.divider,
                my: 2,
                width: '100%',
              }}
              flexItem
            />
          </>
        ) : null}

        <Grid
          display={'grid'}
          container
          gridTemplateColumns={'2fr 10fr'}
          gap={2}
          alignItems={'center'}
        >
          {layout === EFilterMapLayout.Infrastructure ? (
            <RailsIcon
              sx={{
                color: theme => theme.palette.customs.train,
                width: theme => theme.typography.pxToRem(48),
              }}
            />
          ) : (
            <LineIcon
              color={'train'}
              height={4}
            />
          )}
          <Typography
            variant="body2"
            color="text.secondary"
          >
            Железные дороги
          </Typography>
        </Grid>

        {layout === EFilterMapLayout.Infrastructure ? (
          <Grid
            display={'grid'}
            container
            gridTemplateColumns={'2fr 10fr'}
            gap={2}
            alignItems={'center'}
          >
            <RailsIcon
              sx={{
                color: theme => theme.palette.customs.trainPlanned,
                width: theme => theme.typography.pxToRem(48),
              }}
            />
            <Typography
              variant="body2"
              color="text.secondary"
            >
              Планируемые железные дороги
            </Typography>
          </Grid>
        ) : null}

        {layout === EFilterMapLayout.Infrastructure ? (
          <Grid
            display={'grid'}
            container
            gridTemplateColumns={'2fr 10fr'}
            gap={2}
            alignItems={'center'}
          >
            <RailsIcon
              sx={{
                color: theme => theme.palette.customs.trainHighSpeed,
                width: theme => theme.typography.pxToRem(48),
              }}
            />
            <Typography
              variant="body2"
              color="text.secondary"
            >
              Высокоскоростные железные дороги
            </Typography>
          </Grid>
        ) : null}

        {layout === EFilterMapLayout.Infrastructure ? (
          <Grid
            display={'grid'}
            container
            gridTemplateColumns={'2fr 10fr'}
            gap={2}
            alignItems={'center'}
          >
            <RailsIcon
              sx={{
                color: theme => theme.palette.customs.trainPlannedHighSpeed,
                width: theme => theme.typography.pxToRem(48),
              }}
            />
            <Typography
              variant="body2"
              color="text.secondary"
            >
              Планируемые высокоскоростные железные дороги
            </Typography>
          </Grid>
        ) : null}

        <Grid
          display={'grid'}
          container
          gridTemplateColumns={'2fr 10fr'}
          gap={2}
          alignItems={'center'}
        >
          <TrainIcon
            sx={{
              color: theme => theme.palette.customs.train,
              width: theme => theme.typography.pxToRem(20),
              justifySelf: 'center',
            }}
          />
          <Typography
            variant="body2"
            color="text.secondary"
          >
            Железнодорожные станции
          </Typography>
        </Grid>

        {layout === EFilterMapLayout.Infrastructure ? (
          <Grid
            display={'grid'}
            container
            gridTemplateColumns={'2fr 10fr'}
            gap={2}
            alignItems={'center'}
          >
            <TrainIcon
              sx={{
                color: theme => theme.palette.customs.trainPlanned,
                width: theme => theme.typography.pxToRem(20),
                justifySelf: 'center',
              }}
            />
            <Typography
              variant="body2"
              color="text.secondary"
            >
              Планируемые железнодорожные станции
            </Typography>
          </Grid>
        ) : null}

        {layout === EFilterMapLayout.RouteNetwork ? (
          <Grid
            display={'grid'}
            container
            gridTemplateColumns={'2fr 10fr'}
            gap={2}
            alignItems={'center'}
          >
            <CircleIcon color={'trainPlanned'} />
            <Typography
              variant="body2"
              color="text.secondary"
            >
              Промежуточные корреспонденции
            </Typography>
          </Grid>
        ) : null}

        <Divider
          sx={{
            borderColor: theme => theme.palette.border.divider,
            my: 2,
            width: '100%',
          }}
          flexItem
        />

        <Grid
          display={'grid'}
          container
          gridTemplateColumns={'2fr 10fr'}
          gap={2}
          alignItems={'center'}
        >
          <LineIcon color={'auto'} />
          <Typography
            variant="body2"
            color="text.secondary"
          >
            Автомобильные дороги
          </Typography>
        </Grid>

        {layout === EFilterMapLayout.Infrastructure ? (
          <Grid
            display={'grid'}
            container
            gridTemplateColumns={'2fr 10fr'}
            gap={2}
            alignItems={'center'}
          >
            <LineIcon color={'autoPlanned'} />
            <Typography
              variant="body2"
              color="text.secondary"
            >
              Планируемые автомобильные дороги
            </Typography>
          </Grid>
        ) : null}

        {layout === EFilterMapLayout.Infrastructure ? (
          <Grid
            display={'grid'}
            container
            gridTemplateColumns={'2fr 10fr'}
            gap={2}
            alignItems={'center'}
          >
            <LineIcon color={'expressways'} />
            <Typography
              variant="body2"
              color="text.secondary"
            >
              Скоростные автомобильные дороги
            </Typography>
          </Grid>
        ) : null}

        {layout === EFilterMapLayout.Infrastructure ? (
          <Grid
            display={'grid'}
            container
            gridTemplateColumns={'2fr 10fr'}
            gap={2}
            alignItems={'center'}
          >
            <LineIcon color={'expresswaysPlanned'} />
            <Typography
              variant="body2"
              color="text.secondary"
            >
              Планируемые скоростные автомобильные дороги
            </Typography>
          </Grid>
        ) : null}

        <Grid
          display={'grid'}
          container
          gridTemplateColumns={'2fr 10fr'}
          gap={2}
          alignItems={'center'}
        >
          <BusStationIcon
            sx={{
              color: theme => theme.palette.customs.auto,
              width: theme => theme.typography.pxToRem(20),
              justifySelf: 'center',
            }}
          />
          <Typography
            variant="body2"
            color="text.secondary"
          >
            Автостанции
          </Typography>
        </Grid>

        {layout === EFilterMapLayout.Infrastructure ? (
          <Grid
            display={'grid'}
            container
            gridTemplateColumns={'2fr 10fr'}
            gap={2}
            alignItems={'center'}
          >
            <BusStationIcon
              sx={{
                color: theme => theme.palette.customs.autoPlanned,
                width: theme => theme.typography.pxToRem(20),
                justifySelf: 'center',
              }}
            />
            <Typography
              variant="body2"
              color="text.secondary"
            >
              Планируемые автостанции
            </Typography>
          </Grid>
        ) : null}

        {layout === EFilterMapLayout.RouteNetwork ? (
          <Grid
            display={'grid'}
            container
            gridTemplateColumns={'2fr 10fr'}
            gap={2}
            alignItems={'center'}
          >
            <CircleIcon color={'auto'} />
            <Typography
              variant="body2"
              color="text.secondary"
            >
              Промежуточные корреспонденции
            </Typography>
          </Grid>
        ) : null}

        <Divider
          sx={{
            borderColor: theme => theme.palette.border.divider,
            my: 2,
            width: '100%',
          }}
          flexItem
        />

        {layout !== EFilterMapLayout.Infrastructure ? (
          <Grid
            display={'grid'}
            container
            gridTemplateColumns={'2fr 10fr'}
            gap={2}
            alignItems={'center'}
          >
            <LineIcon color={'avia'} />
            <Typography
              variant="body2"
              color="text.secondary"
            >
              Авиамаршруты
            </Typography>
          </Grid>
        ) : null}

        <Grid
          display={'grid'}
          container
          gridTemplateColumns={'2fr 10fr'}
          gap={2}
          alignItems={'center'}
        >
          <AirportIcon
            sx={{
              color: theme => theme.palette.customs.avia,
              width: theme => theme.typography.pxToRem(20),
              justifySelf: 'center',
            }}
          />
          <Typography
            variant="body2"
            color="text.secondary"
          >
            Аэропорты
          </Typography>
        </Grid>

        {layout === EFilterMapLayout.Infrastructure ? (
          <Grid
            display={'grid'}
            container
            gridTemplateColumns={'2fr 10fr'}
            gap={2}
            alignItems={'center'}
          >
            <AirportIcon
              sx={{
                color: theme => theme.palette.customs.aviaPlanned,
                width: theme => theme.typography.pxToRem(20),
                justifySelf: 'center',
              }}
            />
            <Typography
              variant="body2"
              color="text.secondary"
            >
              Планируемые аэропорты
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};
