import { useUnit } from 'effector-react';
import { ChangeEvent, useCallback, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
  debounce,
} from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';

import { TableProjects } from '@features/ag-projects-registry/components/TableProjects/TableProjects';
import { CurrentProjectApi } from '@features/ag-projects-registry/store/currentProject';
import { ListProjectsApi } from '@features/ag-projects-registry/store/lists/projects';
import { SettingsApi } from '@features/ag-projects-registry/store/settings/store';
import { $UIAg, UIAgApi } from '@features/ag-projects-registry/store/ui';

export const ListProjectsContainer = () => {
  const { projectCreator } = useUnit($UIAg);
  const { setStateReadonly } = useUnit(SettingsApi);
  const { setVisibleProjectCreator } = useUnit(UIAgApi);
  const { createEmptyProject } = useUnit(CurrentProjectApi);
  const { setContains } = useUnit(ListProjectsApi);
  const [searchValue, setSearchValue] = useState('');

  const handleCreateProject = useCallback(() => {
    setVisibleProjectCreator(true);
    createEmptyProject();
    setStateReadonly(false);
  }, [createEmptyProject, setStateReadonly, setVisibleProjectCreator]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetSearchValue = useCallback(
    debounce(val => {
      setContains(val);
    }, 800),
    [],
  );

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchValue(event.target.value);
      debouncedSetSearchValue(event.target.value);
    },
    [debouncedSetSearchValue],
  );

  return (
    <AutoFullHeight
      style={{
        backgroundColor: 'white',
        boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '24px',
        padding: '24px',
        width: '100%',
      }}
      mb={24}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="subtitle1"
          fontSize={theme => theme.typography.pxToRem(14)}
        >
          Исходные данные по проектам
        </Typography>

        <Button
          color="secondary"
          onClick={handleCreateProject}
          disabled={projectCreator}
        >
          Создать проект
        </Button>
      </Box>

      <Box>
        <TextField
          fullWidth
          size="medium"
          placeholder="Найти проект"
          value={searchValue}
          onChange={handleSearch}
          InputProps={{
            type: 'search',
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <TableProjects />
    </AutoFullHeight>
  );
};
