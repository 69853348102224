import { useUnit } from 'effector-react';
import { useCallback, useEffect } from 'react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Table, TableContainer, TablePagination } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { ROUTES } from '@constants/routes';

import { TableProjectBody } from '@features/ag-projects-registry/components/TableProjects/TableProjectBody';
import { TableProjectPaginationActions } from '@features/ag-projects-registry/components/TableProjects/TableProjectPaginationActions';
import { TableProjectRowProps } from '@features/ag-projects-registry/components/TableProjects/TableProjectRow';
import { TableProjectsHead } from '@features/ag-projects-registry/components/TableProjects/TableProjectsHead';
import { CurrentProjectApi } from '@features/ag-projects-registry/store/currentProject/store';
import { GetProjectFilesFx } from '@features/ag-projects-registry/store/files/store';
import {
  $ListProject,
  FetchProjects,
  ListProjectsApi,
  ProjectType,
} from '@features/ag-projects-registry/store/lists/projects';
import { SettingsApi } from '@features/ag-projects-registry/store/settings/store';
import { UIAgApi } from '@features/ag-projects-registry/store/ui/store';

export const TableProjects = () => {
  const { projects, rowsPerPage, page, selected, order, orderBy, totalCount } =
    useUnit($ListProject);

  const { setPage, setSelected, setOrder, setOrderBy, setRowsPerPage } =
    useUnit(ListProjectsApi);

  const fetchProjects = useUnit(FetchProjects);
  const { selectProject } = useUnit(CurrentProjectApi);
  const { setVisibleProjectCreator } = useUnit(UIAgApi);
  const { setStateReadonly } = useUnit(SettingsApi);

  // const fetchProjects = useUnit(FetchProjectsFx);

  const navigate = useNavigate();

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - projects.length) : 0;
  const emptyRows = rowsPerPage - projects.length;

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickRow: TableProjectRowProps['onClickRow'] = (_event, row) => {
    const selectedIndex = selected.indexOf(row.id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = [row.id];
    } else if (selectedIndex === 0) {
      return;
    }

    selectProject({
      id: String(row.id),
      name: row.name,
      selectedAgglomeration: row.agglomeration,
    });
    GetProjectFilesFx({ id: String(row.id) });
    setSelected(newSelected);
    setVisibleProjectCreator(true);
    setStateReadonly(true);
  };

  const handleNavigateProject: TableProjectRowProps['onClickProjectButton'] = (
    _event,
    row,
  ) => {
    // if (row.status !== 'A_3') return;
    navigate(`${ROUTES.AGGLOMERATION_PROJECT}?projectId=${row.id}`);
  };

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof ProjectType,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // const newSelected = [].map(n => n.id);
      // setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const fetchProjectsHandler = useCallback(() => {
    fetchProjects();
  }, [fetchProjects]);

  useEffect(() => {
    fetchProjectsHandler();
  }, [fetchProjectsHandler, page, rowsPerPage]);

  useEffect(() => {
    const projectListUpdateTimer = setTimeout(() => {
      FetchProjects();
    }, 5000);

    return () => clearTimeout(projectListUpdateTimer);
  });

  return (
    <Box sx={{ width: '100%', height: 'calc(100% - 136px)' }}>
      <Grid sx={{ width: '100%', height: 'calc(100%)' }}>
        <TableContainer
          sx={{
            height: 'calc(100% - 52px)',
            mb: 2,
            overflow: 'auto',
          }}
        >
          <Table
            size="medium"
            aria-labelledby="tableTitle"
            sx={{
              minWidth: 750,
              width: '100%',
              overflow: 'scroll',
            }}
          >
            <TableProjectsHead
              order={order}
              orderBy={orderBy}
              numSelected={selected.length}
              rowCount={5}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableProjectBody
              selected={selected}
              emptyRows={emptyRows}
              rows={projects}
              onClickRow={handleClickRow}
              onClickProjectButton={handleNavigateProject}
            />
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Показывать по"
          labelDisplayedRows={defaultLabelDisplayedRows}
          ActionsComponent={props => (
            <TableProjectPaginationActions
              {...props}
              rowsPerPage={rowsPerPage}
            />
          )}
        />
      </Grid>
    </Box>
  );
};

const defaultLabelDisplayedRows = ({
  from,
  to,
  count,
}: {
  from: number;
  to: number;
  count: number;
}) => {
  return `${from}–${to} из ${count !== -1 ? count : `более ${to}`}`;
};

export type Order = 'asc' | 'desc';
