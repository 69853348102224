export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  GenericScalar: { input: any; output: any; }
  JSONString: { input: any; output: any; }
  MultiLineString: { input: any; output: any; }
  MultiPolygonScalar: { input: any; output: any; }
  PointScalar: { input: any; output: any; }
  Time: { input: any; output: any; }
  UUID: { input: any; output: any; }
};

export type IAddVertexToItem = {
  __typename?: 'AddVertexToItem';
  instance: Maybe<IGraphItemType>;
};

export type IAgeType = {
  __typename?: 'AgeType';
  overWorkingAge: Maybe<Scalars['Float']['output']>;
  underWorkingAge: Maybe<Scalars['Float']['output']>;
  workingAge: Maybe<Scalars['Float']['output']>;
};

export type IAirportDetailType = {
  __typename?: 'AirportDetailType';
  airport: IAirportType;
  capacityCommon: Maybe<Scalars['Int']['output']>;
  capacityInner: Maybe<Scalars['Int']['output']>;
  id: Scalars['UUID']['output'];
  passTraffic: Maybe<Scalars['Int']['output']>;
  range: Maybe<Scalars['Int']['output']>;
  year: Maybe<Scalars['Int']['output']>;
};

export type IAirportType = {
  __typename?: 'AirportType';
  details: Maybe<Array<Maybe<IAirportDetailType>>>;
  geometry: Maybe<Scalars['PointScalar']['output']>;
  id: Scalars['UUID']['output'];
  name: Maybe<Scalars['String']['output']>;
  objectId: Maybe<Scalars['Int']['output']>;
  planeTimetable: Array<IPlaneTimeTableType>;
  planeTimetableEnd: Array<IPlaneType>;
  planeTimetableStPrev: Array<IPlaneTimeTableType>;
  planeTimetableStart: Array<IPlaneType>;
  region: Maybe<Scalars['String']['output']>;
  show: Scalars['Boolean']['output'];
  transportRegion: Maybe<ITransportRegionType>;
};

export type IBusStationDetailType = {
  __typename?: 'BusStationDetailType';
  busStation: IBusStationType;
  id: Scalars['UUID']['output'];
  passTraffic: Maybe<Scalars['Int']['output']>;
  year: Maybe<Scalars['Int']['output']>;
};

export type IBusStationType = {
  __typename?: 'BusStationType';
  busTimetable: Array<IBusTimeTableType>;
  busTimetableEnd: Array<IBusType>;
  busTimetableStPrev: Array<IBusTimeTableType>;
  busTimetableStart: Array<IBusType>;
  code: Maybe<Scalars['Int']['output']>;
  details: Maybe<Array<Maybe<IBusStationDetailType>>>;
  geometry: Scalars['PointScalar']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  objectId: Maybe<Scalars['Int']['output']>;
  passTraffic: Maybe<Scalars['Int']['output']>;
  region: Scalars['String']['output'];
  show: Scalars['Boolean']['output'];
  transportRegion: Maybe<ITransportRegionType>;
  year: Maybe<Scalars['Int']['output']>;
};

export type IBusTimeTableType = {
  __typename?: 'BusTimeTableType';
  arrivalTime: Maybe<Scalars['Time']['output']>;
  bus: Maybe<IBusType>;
  departureTime: Maybe<Scalars['Time']['output']>;
  id: Scalars['UUID']['output'];
  station: Maybe<IBusStationType>;
  stationPrev: Maybe<IBusStationType>;
};

export type IBusType = {
  __typename?: 'BusType';
  busName: Maybe<Scalars['String']['output']>;
  busTimetable: Array<IBusTimeTableType>;
  frequencyPerYear: Maybe<Scalars['Int']['output']>;
  id: Scalars['UUID']['output'];
  pointFrom: Maybe<Scalars['String']['output']>;
  pointTo: Maybe<Scalars['String']['output']>;
  stationEnd: Maybe<IBusStationType>;
  stationStart: Maybe<IBusStationType>;
  stations: Maybe<Array<Maybe<IBusTimeTableType>>>;
  transportRegions: Maybe<Array<Maybe<ITransportRegionType>>>;
};

/** An enumeration. */
export const enum IComputingRegistryComputingRegistryResultStatusChoices {
  /** Success */
  A_1 = 'A_1',
  /** Pending */
  A_2 = 'A_2',
  /** Failure */
  A_3 = 'A_3',
  /** Draft */
  A_4 = 'A_4',
  /** Queued */
  A_5 = 'A_5'
};

/** An enumeration. */
export const enum IComputingRegistryGraphItemTypeChoices {
  /** Высокоскоростная магистраль */
  A_1 = 'A_1',
  /** Скоростная магистраль */
  A_2 = 'A_2',
  /** Железная дорога */
  A_3 = 'A_3'
};

/** An enumeration. */
export const enum IComputingRegistryProjectSectionProjectTypeChoices {
  /** Автодороги */
  A_1 = 'A_1',
  /** Железные дороги */
  A_2 = 'A_2',
  /** ВСМ */
  A_3 = 'A_3',
  /** Авиация */
  A_4 = 'A_4'
};

/** An enumeration. */
export const enum IComputingRegistryScenarioSocEcoScenarioTypeChoices {
  /** Базовый */
  A_1 = 'A_1',
  /** Пессимистичный */
  A_2 = 'A_2',
  /** Оптимистичный */
  A_3 = 'A_3',
  /** Пользовательский */
  A_4 = 'A_4'
};

/** An enumeration. */
export const enum IComputingRegistrySocioEconomicScenarioInstanceScenarioTypeChoices {
  /** Базовый */
  A_1 = 'A_1',
  /** Пессимистичный */
  A_2 = 'A_2',
  /** Оптимистичный */
  A_3 = 'A_3',
  /** Пользовательский */
  A_4 = 'A_4'
};

/** An enumeration. */
export const enum IComputingRegistryTransportProjectListProjectStatusChoices {
  /** Строительство */
  A_1 = 'A_1',
  /** Реконструкиця */
  A_2 = 'A_2',
  /** Возобновление */
  A_3 = 'A_3'
};

/** An enumeration. */
export const enum IComputingRegistryTransportProjectListProjectTypeChoices {
  /** Автодороги */
  A_1 = 'A_1',
  /** Железные дороги */
  A_2 = 'A_2',
  /** ВСМ */
  A_3 = 'A_3',
  /** Авиация */
  A_4 = 'A_4'
};

/** An enumeration. */
export const enum IComputingRegistryTransportScenarioInstanceScenarioTypeChoices {
  /** Базовый */
  A_1 = 'A_1',
  /** Пессимистичный */
  A_2 = 'A_2',
  /** Оптимистичный */
  A_3 = 'A_3',
  /** Пользовательский */
  A_4 = 'A_4'
};

export type IComputingRegistryType = {
  __typename?: 'ComputingRegistryType';
  baseYear: Maybe<Scalars['Int']['output']>;
  computingresultsSet: Array<IComputingResultsType>;
  error: Maybe<Scalars['String']['output']>;
  finalYear: Maybe<Scalars['Int']['output']>;
  graphs: Array<IGraphType>;
  id: Scalars['UUID']['output'];
  intermediateCorrespondence: Scalars['Boolean']['output'];
  link: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  resultStatus: IComputingRegistryComputingRegistryResultStatusChoices;
  socEcoScenario: Maybe<ISocioEconomicScenarioInstanceType>;
  transportScenario: Maybe<ITransportScenarioInstanceType>;
};

export type IComputingResultsType = {
  __typename?: 'ComputingResultsType';
  auto: Maybe<Scalars['Decimal']['output']>;
  avia: Maybe<Scalars['Decimal']['output']>;
  bus: Maybe<Scalars['Decimal']['output']>;
  dailyUnevenness: Maybe<Scalars['JSONString']['output']>;
  dayTrain: Maybe<Scalars['Decimal']['output']>;
  direction: Maybe<IDirectionType>;
  durationAuto: Maybe<Scalars['Float']['output']>;
  durationAvia: Maybe<Scalars['Float']['output']>;
  durationBus: Maybe<Scalars['Float']['output']>;
  durationDayTrain: Maybe<Scalars['Float']['output']>;
  durationExpressSuburbanTrain: Maybe<Scalars['Float']['output']>;
  durationNightTrain: Maybe<Scalars['Float']['output']>;
  durationPlain: Maybe<Scalars['Float']['output']>;
  durationSuburbanTrain: Maybe<Scalars['Float']['output']>;
  expressSuburbanTrain: Maybe<Scalars['Decimal']['output']>;
  frequencyAvia: Maybe<Scalars['Float']['output']>;
  frequencyBus: Maybe<Scalars['Float']['output']>;
  frequencyDayTrain: Maybe<Scalars['Float']['output']>;
  frequencyExpressSuburbanTrain: Maybe<Scalars['Float']['output']>;
  frequencyNightTrain: Maybe<Scalars['Float']['output']>;
  frequencyPlain: Maybe<Scalars['Float']['output']>;
  frequencySuburbanTrain: Maybe<Scalars['Float']['output']>;
  id: Scalars['UUID']['output'];
  multimodal: Maybe<Scalars['Decimal']['output']>;
  nightTrain: Maybe<Scalars['Decimal']['output']>;
  passengerTrafficByAge: Maybe<IAgeType>;
  passengerTrafficByIncomeGroups: Maybe<IPassengerTrafficByIncomeGroupType>;
  passengerTrafficBySex: Maybe<ISexType>;
  priceAuto: Maybe<Scalars['Float']['output']>;
  priceAvia: Maybe<Scalars['Float']['output']>;
  priceBus: Maybe<Scalars['Float']['output']>;
  priceDayTrain: Maybe<Scalars['Float']['output']>;
  priceExpressSuburbanTrain: Maybe<Scalars['Float']['output']>;
  priceNightTrain: Maybe<Scalars['Float']['output']>;
  pricePlain: Maybe<Scalars['Float']['output']>;
  priceSuburbanTrain: Maybe<Scalars['Float']['output']>;
  registryInstance: IComputingRegistryType;
  suburbanTrain: Maybe<Scalars['Decimal']['output']>;
  totalFlow: Maybe<Scalars['Decimal']['output']>;
  unevenness: Maybe<Scalars['GenericScalar']['output']>;
  year: Maybe<Scalars['Int']['output']>;
};

export type ICreateComputingRegistry = {
  __typename?: 'CreateComputingRegistry';
  computingRegistryObj: Maybe<IComputingRegistryType>;
  errors: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  result: Maybe<Scalars['Boolean']['output']>;
};

export type ICreateScenarioGraphItemMutation = {
  __typename?: 'CreateScenarioGraphItemMutation';
  instance: Maybe<IGraphItemType>;
};

export type ICreateScenarioStationInstance = {
  __typename?: 'CreateScenarioStationInstance';
  instance: Maybe<IRailwayStationInstanceType>;
};

export type ICreateVertex = {
  __typename?: 'CreateVertex';
  vertex: Maybe<IVertexType>;
};

export type IDeleteComputingRegistry = {
  __typename?: 'DeleteComputingRegistry';
  scenarioObj: Maybe<IComputingRegistryType>;
};

export type IDeleteScenarioStationMutation = {
  __typename?: 'DeleteScenarioStationMutation';
  ok: Maybe<Scalars['Boolean']['output']>;
};

export type IDirectionType = {
  __typename?: 'DirectionType';
  computingResults: Array<IComputingResultsType>;
  directions: Array<IPassengerTrafficType>;
  endDate: Maybe<Scalars['DateTime']['output']>;
  getRailwayStationsIntersection: Maybe<Array<Maybe<IRailwayStationType>>>;
  getTransportRegionsIntersection: Maybe<Array<Maybe<ITransportRegionType>>>;
  highways: Array<IHighwayType>;
  id: Scalars['UUID']['output'];
  itinerarys: Array<IItineraryType>;
  railways: Array<IRailwayType>;
  regionFrom: Maybe<ITransportRegionType>;
  regionTo: Maybe<ITransportRegionType>;
  startDate: Scalars['DateTime']['output'];
  transportProjects: Array<ITransportProjectListType>;
};

export type IDownloadFile = {
  __typename?: 'DownloadFile';
  extension: Maybe<Scalars['String']['output']>;
  file: Maybe<Scalars['String']['output']>;
  link: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type IGraphItemType = {
  __typename?: 'GraphItemType';
  created: Scalars['DateTime']['output'];
  endVertex: Maybe<IVertexType>;
  geometry: Maybe<Scalars['MultiLineString']['output']>;
  graph: Maybe<IGraphType>;
  id: Scalars['UUID']['output'];
  name: Maybe<Scalars['String']['output']>;
  relatedVertices: Array<IVertexType>;
  speed: Maybe<Scalars['Int']['output']>;
  startVertex: Maybe<IVertexType>;
  type: IComputingRegistryGraphItemTypeChoices;
  year: Maybe<Scalars['Int']['output']>;
};

export type IGraphType = {
  __typename?: 'GraphType';
  created: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  instances: Array<IGraphItemType>;
  name: Maybe<Scalars['String']['output']>;
  scenario: IComputingRegistryType;
  scenarioStations: Array<IRailwayStationInstanceType>;
  scenarioVertices: Array<IVertexType>;
};

export type IGravModelType = {
  __typename?: 'GravModelType';
  averageMonthlySalary: Maybe<Scalars['Decimal']['output']>;
  averageNumberOfEmployeesOfOrganizationsWithoutSmp: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  levelOfMotorization: Maybe<Scalars['Int']['output']>;
  numberOfPlaces: Maybe<Scalars['Int']['output']>;
  population: Maybe<Scalars['Int']['output']>;
  revenueTourismIndustry: Maybe<Scalars['Decimal']['output']>;
  revenueWithTaxesOnProducts: Maybe<Scalars['Decimal']['output']>;
  transportRegion: ITransportRegionType;
  year: Scalars['Int']['output'];
};

export type IHighwayDetailType = {
  __typename?: 'HighwayDetailType';
  highway: IHighwayType;
  id: Scalars['UUID']['output'];
  status: IHighwayHighwayDetailStatusChoices;
  traffAll: Maybe<Scalars['Int']['output']>;
  traffBus: Maybe<Scalars['Int']['output']>;
  traffPass: Maybe<Scalars['Int']['output']>;
  year: Maybe<Scalars['Int']['output']>;
};

/** An enumeration. */
export const enum IHighwayHighwayDetailStatusChoices {
  /** функционирует */
  A_1 = 'A_1',
  /** планируется реконструкция */
  A_2 = 'A_2',
  /** планируется строительство */
  A_3 = 'A_3'
};

/** An enumeration. */
export const enum IHighwayHighwayTypeChoices {
  /** региональные/местные */
  A_1 = 'A_1',
  /** зимник */
  A_2 = 'A_2',
  /** федеральные */
  A_3 = 'A_3',
  /** зарубежные */
  A_4 = 'A_4',
  /** переправа */
  A_5 = 'A_5'
};

export type IHighwayType = {
  __typename?: 'HighwayType';
  capacity: Maybe<Scalars['Int']['output']>;
  country: Maybe<Scalars['String']['output']>;
  details: Maybe<Array<Maybe<IHighwayDetailType>>>;
  direction: Array<IDirectionType>;
  geometry: Maybe<Scalars['MultiLineString']['output']>;
  highwayName: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  lanes: Maybe<Scalars['Int']['output']>;
  lengthKm: Maybe<Scalars['Float']['output']>;
  objectId: Maybe<Scalars['Int']['output']>;
  oneSection: Scalars['Boolean']['output'];
  payment: Scalars['Boolean']['output'];
  pointEnd: Maybe<Scalars['PointScalar']['output']>;
  pointStart: Maybe<Scalars['PointScalar']['output']>;
  prjCapacity: Maybe<Scalars['Int']['output']>;
  prjId: Maybe<Scalars['Int']['output']>;
  prjLanes: Maybe<Scalars['Int']['output']>;
  prjName: Maybe<Scalars['String']['output']>;
  prjSectionId: Maybe<Scalars['String']['output']>;
  prjSectionName: Maybe<Scalars['String']['output']>;
  prjSpeed: Maybe<Scalars['Int']['output']>;
  prjYear: Maybe<Scalars['Int']['output']>;
  regionFrom: Maybe<ITransportRegionType>;
  regionTo: Maybe<ITransportRegionType>;
  speed: Maybe<Scalars['Int']['output']>;
  type: IHighwayHighwayTypeChoices;
};

export type IIncomeGroupByCategoryType = {
  __typename?: 'IncomeGroupByCategoryType';
  category: Maybe<Scalars['String']['output']>;
  value: Maybe<Scalars['Float']['output']>;
};

export type IInfraType = {
  __typename?: 'InfraType';
  id: Maybe<Scalars['UUID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  point: Maybe<IPointType>;
  type: Maybe<Scalars['String']['output']>;
};

export type IItineraryType = {
  __typename?: 'ItineraryType';
  direction: Array<IDirectionType>;
  id: Scalars['UUID']['output'];
  vehicle: Array<IVehicleTypeType>;
};

export type IMultiLineStringInput = {
  coordinates: Array<InputMaybe<IPointInput>>;
};

export type IMutation = {
  __typename?: 'Mutation';
  addVertexToItem: Maybe<IAddVertexToItem>;
  createComputingRegistry: Maybe<ICreateComputingRegistry>;
  createGraphItem: Maybe<ICreateScenarioGraphItemMutation>;
  createStation: Maybe<ICreateScenarioStationInstance>;
  createVertex: Maybe<ICreateVertex>;
  deleteComputingRegistry: Maybe<IDeleteComputingRegistry>;
  deleteStation: Maybe<IDeleteScenarioStationMutation>;
  downloadComputingResultsExcel: Maybe<IDownloadFile>;
  runCalcComputingRegistry: Maybe<IRunCalcComputingRegistry>;
  updateComputedResultsParams: Maybe<IUpdateComputedResultParams>;
  updateComputingRegistry: Maybe<IUpdateComputingRegistry>;
  updateGraphItem: Maybe<IUpdateScenarioGraphItemMutation>;
  updateStation: Maybe<IUpdateScenarioStationMutation>;
};


export type IMutationAddVertexToItemArgs = {
  graphItemId: Scalars['UUID']['input'];
  vertexId: Scalars['UUID']['input'];
};


export type IMutationCreateComputingRegistryArgs = {
  intermediateCorrespondence?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  socEcoScenarioId?: InputMaybe<Scalars['UUID']['input']>;
  socEcoScenarioParams: InputMaybe<ISocEcoParamsUpdateInput>;
  socEcoScenarioType?: InputMaybe<Scalars['Int']['input']>;
  transportScenarioId?: InputMaybe<Scalars['UUID']['input']>;
  transportScenarioParams: InputMaybe<ITransportParamsInput>;
  transportScenarioType?: InputMaybe<Scalars['Int']['input']>;
};


export type IMutationCreateGraphItemArgs = {
  endVertexId: Scalars['UUID']['input'];
  geometry: InputMaybe<IMultiLineStringInput>;
  name: InputMaybe<Scalars['String']['input']>;
  scenarioId: Scalars['UUID']['input'];
  startVertexId: Scalars['UUID']['input'];
  year: InputMaybe<Scalars['Int']['input']>;
};


export type IMutationCreateStationArgs = {
  name: InputMaybe<Scalars['String']['input']>;
  railwayId?: InputMaybe<Scalars['UUID']['input']>;
  railwayStationId?: InputMaybe<Scalars['UUID']['input']>;
  scenarioId: Scalars['UUID']['input'];
  vertexId: Scalars['UUID']['input'];
};


export type IMutationCreateVertexArgs = {
  geometry: InputMaybe<IPointInput>;
  scenarioId: InputMaybe<Scalars['UUID']['input']>;
};


export type IMutationDeleteComputingRegistryArgs = {
  id: InputMaybe<Scalars['UUID']['input']>;
};


export type IMutationDeleteStationArgs = {
  tpuId: Scalars['UUID']['input'];
};


export type IMutationDownloadComputingResultsExcelArgs = {
  id: Scalars['UUID']['input'];
};


export type IMutationRunCalcComputingRegistryArgs = {
  id: InputMaybe<Scalars['UUID']['input']>;
};


export type IMutationUpdateComputedResultsParamsArgs = {
  durationAuto: InputMaybe<Scalars['Float']['input']>;
  durationAvia: InputMaybe<Scalars['Float']['input']>;
  durationBus: InputMaybe<Scalars['Float']['input']>;
  durationDayTrain: InputMaybe<Scalars['Float']['input']>;
  durationExpressSuburbanTrain: InputMaybe<Scalars['Float']['input']>;
  durationNightTrain: InputMaybe<Scalars['Float']['input']>;
  durationPlain: InputMaybe<Scalars['Float']['input']>;
  durationSuburbanTrain: InputMaybe<Scalars['Float']['input']>;
  frequencyAvia: InputMaybe<Scalars['Float']['input']>;
  frequencyBus: InputMaybe<Scalars['Float']['input']>;
  frequencyDayTrain: InputMaybe<Scalars['Float']['input']>;
  frequencyExpressSuburbanTrain: InputMaybe<Scalars['Float']['input']>;
  frequencyNightTrain: InputMaybe<Scalars['Float']['input']>;
  frequencyPlain: InputMaybe<Scalars['Float']['input']>;
  frequencySuburbanTrain: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['UUID']['input'];
  priceAuto: InputMaybe<Scalars['Float']['input']>;
  priceAvia: InputMaybe<Scalars['Float']['input']>;
  priceBus: InputMaybe<Scalars['Float']['input']>;
  priceDayTrain: InputMaybe<Scalars['Float']['input']>;
  priceExpressSuburbanTrain: InputMaybe<Scalars['Float']['input']>;
  priceNightTrain: InputMaybe<Scalars['Float']['input']>;
  pricePlain: InputMaybe<Scalars['Float']['input']>;
  priceSuburbanTrain: InputMaybe<Scalars['Float']['input']>;
};


export type IMutationUpdateComputingRegistryArgs = {
  id: Scalars['UUID']['input'];
  intermediateCorrespondence?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  socEcoScenarioId?: InputMaybe<Scalars['UUID']['input']>;
  socEcoScenarioParams: InputMaybe<ISocEcoParamsUpdateInput>;
  socEcoScenarioType?: InputMaybe<Scalars['Int']['input']>;
  transportScenarioId?: InputMaybe<Scalars['UUID']['input']>;
  transportScenarioParams: InputMaybe<ITransportParamsInput>;
  transportScenarioType?: InputMaybe<Scalars['Int']['input']>;
};


export type IMutationUpdateGraphItemArgs = {
  geometry: InputMaybe<IMultiLineStringInput>;
  graphItemId: Scalars['UUID']['input'];
  name: InputMaybe<Scalars['String']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};


export type IMutationUpdateStationArgs = {
  name: InputMaybe<Scalars['String']['input']>;
  railwayId?: InputMaybe<Scalars['UUID']['input']>;
  railwayStationId?: InputMaybe<Scalars['UUID']['input']>;
  stationId: Scalars['UUID']['input'];
};

export type IPassTrafficValuesType = {
  __typename?: 'PassTrafficValuesType';
  comfortCoef: Maybe<Scalars['Float']['output']>;
  duration: Maybe<Scalars['Float']['output']>;
  frequency: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  passTraffic: IPassengerTrafficType;
  price: Maybe<Scalars['Float']['output']>;
  year: Scalars['Int']['output'];
};

/** An enumeration. */
export const enum IPassTrafficVehicleTypeModeChoices {
  /** Train */
  A_1 = 'A_1',
  /** Avia */
  A_2 = 'A_2',
  /** Auto */
  A_3 = 'A_3',
  /** Multi */
  A_4 = 'A_4'
};

export type IPassengerTrafficByIncomeGroupType = {
  __typename?: 'PassengerTrafficByIncomeGroupType';
  items: Maybe<Array<Maybe<IIncomeGroupByCategoryType>>>;
  year: Maybe<Scalars['Int']['output']>;
};

export type IPassengerTrafficType = {
  __typename?: 'PassengerTrafficType';
  direction: IDirectionType;
  id: Scalars['UUID']['output'];
  multimodal: Maybe<Scalars['Float']['output']>;
  passtrafficvaluesSet: Array<IPassTrafficValuesType>;
  total: Maybe<Scalars['Int']['output']>;
  trafficTotal: Maybe<Scalars['Float']['output']>;
  vehicle: IVehicleTypeType;
  year: Maybe<Scalars['Int']['output']>;
};

export type IPlaneTimeTableType = {
  __typename?: 'PlaneTimeTableType';
  arrivalTime: Maybe<Scalars['Time']['output']>;
  departureTime: Maybe<Scalars['Time']['output']>;
  id: Scalars['UUID']['output'];
  plane: Maybe<IPlaneType>;
  station: Maybe<IAirportType>;
  stationPrev: Maybe<IAirportType>;
};

export type IPlaneType = {
  __typename?: 'PlaneType';
  frequencyPerYear: Maybe<Scalars['Int']['output']>;
  id: Scalars['UUID']['output'];
  planeName: Maybe<Scalars['String']['output']>;
  planeTimetable: Array<IPlaneTimeTableType>;
  pointFrom: Maybe<Scalars['String']['output']>;
  pointTo: Maybe<Scalars['String']['output']>;
  stationEnd: Maybe<IAirportType>;
  stationStart: Maybe<IAirportType>;
  stations: Maybe<Array<Maybe<IPlaneTimeTableType>>>;
  transportRegions: Maybe<Array<Maybe<ITransportRegionType>>>;
};

export type IPointInput = {
  lat: Scalars['Float']['input'];
  lon: Scalars['Float']['input'];
};

export type IPointType = {
  __typename?: 'PointType';
  lat: Maybe<Scalars['Float']['output']>;
  lon: Maybe<Scalars['Float']['output']>;
};

export type IProjectSectionType = {
  __typename?: 'ProjectSectionType';
  id: Scalars['ID']['output'];
  projectId: Maybe<Scalars['Int']['output']>;
  projectType: IComputingRegistryProjectSectionProjectTypeChoices;
  sectionId: Maybe<Scalars['String']['output']>;
  sectionName: Maybe<Scalars['String']['output']>;
  segmentId: Maybe<Scalars['Int']['output']>;
  transportProjectlist: Maybe<ITransportProjectListType>;
  year: Maybe<Scalars['Int']['output']>;
};

export type IQuery = {
  __typename?: 'Query';
  airport: Maybe<Array<Maybe<IAirportType>>>;
  airportDetail: Maybe<Array<Maybe<IAirportDetailType>>>;
  busStation: Maybe<Array<Maybe<IBusStationType>>>;
  computingRegistry: Maybe<Array<Maybe<IComputingRegistryType>>>;
  direction: Maybe<Array<Maybe<IDirectionType>>>;
  findRegion: Maybe<ITransportRegionDetailType>;
  findRegionByPrefix: Maybe<Array<Maybe<ITransportRegionType>>>;
  getAirportById: Maybe<IAirportType>;
  getBusBetweenTrs: Maybe<Array<Maybe<IBusType>>>;
  getBusStationById: Maybe<IBusStationType>;
  getComputedDirection: Maybe<IDirectionType>;
  getComputedPassTraffic: Maybe<Array<Maybe<IComputingResultsType>>>;
  getComputingRegistryInstanceById: Maybe<IComputingRegistryType>;
  getDirection: Maybe<IDirectionType>;
  getHighwayById: Maybe<IHighwayType>;
  getInfraPointByName: Maybe<Array<Maybe<IInfraType>>>;
  getItineraryByTransportRegionsAndVeichleType: Maybe<IItineraryType>;
  getPassTraffic: Maybe<Array<Maybe<IPassengerTrafficType>>>;
  getPlaneBetweenTrs: Maybe<Array<Maybe<IPlaneType>>>;
  getRailwayBetweenTrs: Maybe<Array<Maybe<ITrainType>>>;
  getRailwayById: Maybe<IRailwayType>;
  getRailwayStationById: Maybe<IRailwayStationType>;
  getRailwayTimetableById: Maybe<IRailwayTimeTableInformationType>;
  getRegion: Maybe<ITransportRegionType>;
  getRegionById: Maybe<ITransportRegionType>;
  getScenarioGraph: Maybe<IGraphType>;
  getTimeline: Maybe<ITimeLineType>;
  getTrainById: Maybe<ITrainType>;
  getTransportProjectById: Maybe<ITransportProjectType>;
  getTransportRegionById: Maybe<ITransportRegionType>;
  highway: Maybe<Array<Maybe<IHighwayType>>>;
  passengerTraffic: Maybe<Array<Maybe<IPassengerTrafficType>>>;
  projectSections: Maybe<Array<Maybe<IProjectSectionType>>>;
  railway: Maybe<Array<Maybe<IRailwayType>>>;
  railwayStation: Maybe<Array<Maybe<IRailwayStationType>>>;
  scenarioSocEco: Maybe<Array<Maybe<IScenarioSocEcoType>>>;
  socEcoScenarioInstances: Maybe<Array<Maybe<ISocioEconomicScenarioInstanceType>>>;
  train: Maybe<Array<Maybe<ITrainType>>>;
  transportProjectList: Maybe<Array<Maybe<ITransportProjectListType>>>;
  transportProjects: Maybe<Array<Maybe<ITransportProjectType>>>;
  transportRegion: Maybe<Array<Maybe<ITransportRegionType>>>;
  transportRegionDetail: Maybe<Array<Maybe<ITransportRegionDetailType>>>;
  transportScenarioInstances: Maybe<Array<Maybe<ITransportScenarioInstanceType>>>;
  vehicleType: Maybe<Array<Maybe<IVehicleTypeType>>>;
};


export type IQueryFindRegionArgs = {
  lat: InputMaybe<Scalars['Float']['input']>;
  lon: InputMaybe<Scalars['Float']['input']>;
};


export type IQueryFindRegionByPrefixArgs = {
  maxCount: InputMaybe<Scalars['Int']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
};


export type IQueryGetAirportByIdArgs = {
  id: InputMaybe<Scalars['String']['input']>;
};


export type IQueryGetBusBetweenTrsArgs = {
  trFrom: Scalars['String']['input'];
  trTo: Scalars['String']['input'];
  type: InputMaybe<Scalars['Int']['input']>;
};


export type IQueryGetBusStationByIdArgs = {
  id: InputMaybe<Scalars['String']['input']>;
};


export type IQueryGetComputedDirectionArgs = {
  computingRegistryId: InputMaybe<Scalars['String']['input']>;
  includePayment: InputMaybe<Scalars['Boolean']['input']>;
  plannedYear: InputMaybe<Scalars['Int']['input']>;
  regionFrom: InputMaybe<Scalars['String']['input']>;
  regionTo: InputMaybe<Scalars['String']['input']>;
};


export type IQueryGetComputedPassTrafficArgs = {
  computingRegistryId: InputMaybe<Scalars['String']['input']>;
  regionFrom: InputMaybe<Scalars['String']['input']>;
  regionTo: InputMaybe<Scalars['String']['input']>;
};


export type IQueryGetComputingRegistryInstanceByIdArgs = {
  id: InputMaybe<Scalars['String']['input']>;
};


export type IQueryGetDirectionArgs = {
  includePayment: InputMaybe<Scalars['Boolean']['input']>;
  plannedYear: InputMaybe<Scalars['Int']['input']>;
  regionFrom: InputMaybe<Scalars['String']['input']>;
  regionTo: InputMaybe<Scalars['String']['input']>;
};


export type IQueryGetHighwayByIdArgs = {
  id: InputMaybe<Scalars['String']['input']>;
};


export type IQueryGetInfraPointByNameArgs = {
  contains: InputMaybe<Scalars['String']['input']>;
};


export type IQueryGetItineraryByTransportRegionsAndVeichleTypeArgs = {
  regionFrom: InputMaybe<Scalars['String']['input']>;
  regionTo: InputMaybe<Scalars['String']['input']>;
  vehicleClass: InputMaybe<Scalars['Int']['input']>;
};


export type IQueryGetPassTrafficArgs = {
  intermediateCorrespondence?: InputMaybe<Scalars['Boolean']['input']>;
  regionFrom: InputMaybe<Scalars['String']['input']>;
  regionTo: InputMaybe<Scalars['String']['input']>;
  vehicleType: InputMaybe<Scalars['UUID']['input']>;
};


export type IQueryGetPlaneBetweenTrsArgs = {
  trFrom: Scalars['String']['input'];
  trTo: Scalars['String']['input'];
  type: InputMaybe<Scalars['Int']['input']>;
};


export type IQueryGetRailwayBetweenTrsArgs = {
  trFrom: Scalars['String']['input'];
  trTo: Scalars['String']['input'];
  type: InputMaybe<Scalars['Int']['input']>;
};


export type IQueryGetRailwayByIdArgs = {
  id: InputMaybe<Scalars['String']['input']>;
};


export type IQueryGetRailwayStationByIdArgs = {
  id: InputMaybe<Scalars['String']['input']>;
};


export type IQueryGetRailwayTimetableByIdArgs = {
  id: InputMaybe<Scalars['String']['input']>;
};


export type IQueryGetRegionArgs = {
  id: InputMaybe<Scalars['String']['input']>;
};


export type IQueryGetRegionByIdArgs = {
  id: InputMaybe<Scalars['String']['input']>;
};


export type IQueryGetScenarioGraphArgs = {
  scenarioId: InputMaybe<Scalars['UUID']['input']>;
};


export type IQueryGetTrainByIdArgs = {
  id: InputMaybe<Scalars['String']['input']>;
};


export type IQueryGetTransportProjectByIdArgs = {
  id: InputMaybe<Scalars['String']['input']>;
};


export type IQueryGetTransportRegionByIdArgs = {
  id: InputMaybe<Scalars['UUID']['input']>;
};

export type IRailwayDetailType = {
  __typename?: 'RailwayDetailType';
  id: Scalars['UUID']['output'];
  railway: IRailwayType;
  status: Scalars['Boolean']['output'];
  year: Maybe<Scalars['Int']['output']>;
};

/** An enumeration. */
export const enum IRailwayRailwayStatusChoices {
  /** Active */
  A_1 = 'A_1',
  /** Inactive */
  A_2 = 'A_2'
};

/** An enumeration. */
export const enum IRailwayRailwayTimeTableGroupOfRailwayCarsChoices {
  /** Основной маршрут */
  A_1 = 'A_1',
  /** Прицепной вагон */
  A_2 = 'A_2'
};

/** An enumeration. */
export const enum IRailwayRailwayTimeTableTypeChoices {
  /** Ночной поезд дальнего следования */
  A_1 = 'A_1',
  /** Дневной поезд дальнего следования */
  A_2 = 'A_2',
  /** Пригородный поезд */
  A_3 = 'A_3'
};

/** An enumeration. */
export const enum IRailwayRailwayTractionTypeChoices {
  /** Electrified  */
  A_1 = 'A_1',
  /** Non_electrified */
  A_2 = 'A_2'
};

export type IRailwayStationDetailType = {
  __typename?: 'RailwayStationDetailType';
  id: Scalars['UUID']['output'];
  passTraffic: Maybe<Scalars['Int']['output']>;
  railwayStation: IRailwayStationType;
  year: Maybe<Scalars['Int']['output']>;
};

export type IRailwayStationInstanceType = {
  __typename?: 'RailwayStationInstanceType';
  created: Scalars['DateTime']['output'];
  graph: Maybe<IGraphType>;
  id: Scalars['UUID']['output'];
  name: Maybe<Scalars['String']['output']>;
  railway: Maybe<IRailwayType>;
  railwayStation: Maybe<IRailwayStationType>;
  transportRegion: Maybe<ITransportRegionType>;
  vertex: IVertexType;
};

export type IRailwayStationType = {
  __typename?: 'RailwayStationType';
  code: Maybe<Scalars['Int']['output']>;
  details: Maybe<Array<Maybe<IRailwayStationDetailType>>>;
  geometry: Scalars['PointScalar']['output'];
  id: Scalars['UUID']['output'];
  isTerminal: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  objectId: Maybe<Scalars['Int']['output']>;
  prjId: Maybe<Scalars['Int']['output']>;
  railwayFrom: Array<IRailwayType>;
  railwayTo: Array<IRailwayType>;
  scenarioStations: Array<IRailwayStationInstanceType>;
  timetable: Array<IRailwayTimeTableInformationType>;
  timetableEnd: Array<IRailwayTimeTableInformationType>;
  timetableStPrev: Array<IRailwayTimeTableInformationType>;
  timetableStart: Array<IRailwayTimeTableInformationType>;
  trainTimetable: Array<ITrainTimeTableType>;
  trainTimetableEnd: Array<ITrainType>;
  trainTimetableStPrev: Array<ITrainTimeTableType>;
  trainTimetableStart: Array<ITrainType>;
  transportRegion: Maybe<ITransportRegionType>;
};

export type IRailwayTimeTableInformationType = {
  __typename?: 'RailwayTimeTableInformationType';
  arrivalTime: Maybe<Scalars['Time']['output']>;
  departureTime: Maybe<Scalars['Time']['output']>;
  frequencyPerYear: Maybe<Scalars['Int']['output']>;
  groupOfRailwayCars: IRailwayRailwayTimeTableGroupOfRailwayCarsChoices;
  id: Scalars['UUID']['output'];
  pointFrom: Maybe<Scalars['String']['output']>;
  pointTo: Maybe<Scalars['String']['output']>;
  station: Maybe<IRailwayStationType>;
  stationEnd: Maybe<IRailwayStationType>;
  stationPrev: Maybe<IRailwayStationType>;
  stationStart: Maybe<IRailwayStationType>;
  stations: Maybe<Array<Maybe<IRailwayStationType>>>;
  trainName: Maybe<Scalars['String']['output']>;
  type: Maybe<IRailwayRailwayTimeTableTypeChoices>;
};

/** An enumeration. */
export const enum IRailwayTrainGroupOfRailwayCarsChoices {
  /** Основной маршрут */
  A_1 = 'A_1',
  /** Прицепной вагон */
  A_2 = 'A_2'
};

export type IRailwayType = {
  __typename?: 'RailwayType';
  capacity: Maybe<Scalars['Int']['output']>;
  country: Maybe<Scalars['String']['output']>;
  details: Maybe<Array<Maybe<IRailwayDetailType>>>;
  direction: Array<IDirectionType>;
  geometry: Maybe<Scalars['MultiLineString']['output']>;
  id: Scalars['UUID']['output'];
  level: Maybe<Scalars['Int']['output']>;
  objectId: Maybe<Scalars['Int']['output']>;
  pointFrom: Maybe<Scalars['PointScalar']['output']>;
  pointTo: Maybe<Scalars['PointScalar']['output']>;
  prjId: Maybe<Scalars['Int']['output']>;
  prjSectionId: Maybe<Scalars['String']['output']>;
  railwayName: Maybe<Scalars['String']['output']>;
  scenarioStations: Array<IRailwayStationInstanceType>;
  speed: Maybe<Scalars['Int']['output']>;
  station1: Maybe<IRailwayStationType>;
  station2: Maybe<IRailwayStationType>;
  status: IRailwayRailwayStatusChoices;
  tracks: Maybe<Scalars['String']['output']>;
  tractionType: IRailwayRailwayTractionTypeChoices;
};

export type IRunCalcComputingRegistry = {
  __typename?: 'RunCalcComputingRegistry';
  computingRegistryObj: Maybe<IComputingRegistryType>;
  errors: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  result: Maybe<Scalars['Boolean']['output']>;
};

export type IScenarioSocEcoType = {
  __typename?: 'ScenarioSocEcoType';
  id: Scalars['UUID']['output'];
  indicators: Array<ISocEcoPredictionIndicatorsType>;
  name: Scalars['String']['output'];
  scenarioType: IComputingRegistryScenarioSocEcoScenarioTypeChoices;
  socEcoScenarioInstance: Maybe<ISocioEconomicScenarioInstanceType>;
  transportRegion: Maybe<ITransportRegionType>;
};

export type ISexType = {
  __typename?: 'SexType';
  female: Maybe<Scalars['Float']['output']>;
  male: Maybe<Scalars['Float']['output']>;
};

export type ISocEcoParamsUpdateInput = {
  gdpGrowthRate: Scalars['Float']['input'];
  growthRateInTheNumberOfPlacements: Scalars['Float']['input'];
  growthRateOfMotorization: Scalars['Float']['input'];
  jobGrowthRate: Scalars['Float']['input'];
  populationGrowthRate: Scalars['Float']['input'];
  socEcoComputeName: Scalars['String']['input'];
  socEcoParentId: Scalars['UUID']['input'];
};

export type ISocEcoPredictionIndicatorsType = {
  __typename?: 'SocEcoPredictionIndicatorsType';
  grossAddedValue: Maybe<Scalars['Decimal']['output']>;
  id: Scalars['UUID']['output'];
  motorizationLevel: Maybe<Scalars['Float']['output']>;
  numberOfJobsPlacements: Maybe<Scalars['Int']['output']>;
  numberOfPlacements: Maybe<Scalars['Int']['output']>;
  population: Maybe<Scalars['Decimal']['output']>;
  realDisposableIncomes: Maybe<Scalars['Decimal']['output']>;
  revenueProjection: Maybe<Scalars['Decimal']['output']>;
  salary: Maybe<Scalars['Decimal']['output']>;
  scenario: IScenarioSocEcoType;
  year: Maybe<Scalars['Int']['output']>;
};

export type ISocioEconomicScenarioInstanceType = {
  __typename?: 'SocioEconomicScenarioInstanceType';
  childrenSet: Array<ISocioEconomicScenarioInstanceType>;
  computed: Maybe<Scalars['Boolean']['output']>;
  gdpGrowthRate: Maybe<Scalars['Float']['output']>;
  growthRateInTheNumberOfPlacements: Maybe<Scalars['Float']['output']>;
  growthRateOfMotorization: Maybe<Scalars['Float']['output']>;
  id: Scalars['UUID']['output'];
  jobGrowthRate: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  parent: Maybe<ISocioEconomicScenarioInstanceType>;
  populationGrowthRate: Maybe<Scalars['Float']['output']>;
  regestry: Array<IComputingRegistryType>;
  scenarioType: IComputingRegistrySocioEconomicScenarioInstanceScenarioTypeChoices;
  socEcoScenario: Array<IScenarioSocEcoType>;
  year: Maybe<Scalars['Int']['output']>;
};

export type ITimeLineType = {
  __typename?: 'TimeLineType';
  timelineEnd: Maybe<Scalars['Int']['output']>;
  timelineStart: Maybe<Scalars['Int']['output']>;
};

export type ITrainTimeTableType = {
  __typename?: 'TrainTimeTableType';
  arrivalTime: Maybe<Scalars['Time']['output']>;
  departureTime: Maybe<Scalars['Time']['output']>;
  id: Scalars['UUID']['output'];
  station: Maybe<IRailwayStationType>;
  stationPrev: Maybe<IRailwayStationType>;
  train: Maybe<ITrainType>;
};

export type ITrainType = {
  __typename?: 'TrainType';
  code: Maybe<Scalars['String']['output']>;
  frequencyPerYear: Maybe<Scalars['Int']['output']>;
  groupOfRailwayCars: IRailwayTrainGroupOfRailwayCarsChoices;
  id: Scalars['UUID']['output'];
  pointFrom: Maybe<Scalars['String']['output']>;
  pointTo: Maybe<Scalars['String']['output']>;
  stationEnd: Maybe<IRailwayStationType>;
  stationStart: Maybe<IRailwayStationType>;
  stations: Maybe<Array<Maybe<ITrainTimeTableType>>>;
  trainName: Maybe<Scalars['String']['output']>;
  trainTimetable: Array<ITrainTimeTableType>;
  transportRegions: Maybe<Array<Maybe<ITransportRegionType>>>;
  type: Maybe<Scalars['Int']['output']>;
};

export type ITransportParamsInput = {
  projectObjects: InputMaybe<Array<InputMaybe<ITransportProjectCreateInput>>>;
  transportComputeName: Scalars['String']['input'];
};

export type ITransportPointType = {
  __typename?: 'TransportPointType';
  geometry: Scalars['PointScalar']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  transportRegion: Maybe<ITransportRegionType>;
};

export type ITransportProjectCreateInput = {
  projectId: Scalars['UUID']['input'];
  year: InputMaybe<Scalars['Int']['input']>;
};

export type ITransportProjectListType = {
  __typename?: 'TransportProjectListType';
  direction: Array<IDirectionType>;
  id: Scalars['UUID']['output'];
  name: Maybe<Scalars['String']['output']>;
  projId: Maybe<Scalars['Int']['output']>;
  projectStatus: IComputingRegistryTransportProjectListProjectStatusChoices;
  projectType: IComputingRegistryTransportProjectListProjectTypeChoices;
  sections: Array<IProjectSectionType>;
  transportprojectSet: Array<ITransportProjectType>;
};

export type ITransportProjectType = {
  __typename?: 'TransportProjectType';
  id: Scalars['UUID']['output'];
  transportProject: Maybe<ITransportProjectListType>;
  transportScenarioInstance: Maybe<ITransportScenarioInstanceType>;
  year: Maybe<Scalars['Int']['output']>;
};

export type ITransportRegionDetailType = {
  __typename?: 'TransportRegionDetailType';
  geometry: Maybe<Scalars['MultiPolygonScalar']['output']>;
  id: Scalars['UUID']['output'];
  transportRegion: Maybe<ITransportRegionType>;
};

export type ITransportRegionType = {
  __typename?: 'TransportRegionType';
  airportSet: Array<IAirportType>;
  border: Maybe<ITransportRegionDetailType>;
  busstationSet: Array<IBusStationType>;
  center: Maybe<ITransportPointType>;
  details: Maybe<Array<Maybe<IGravModelType>>>;
  directionsFrom: Array<IDirectionType>;
  directionsTo: Array<IDirectionType>;
  gravmodelSet: Array<IGravModelType>;
  heighwaysFrom: Array<IHighwayType>;
  heighwaysTo: Array<IHighwayType>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  railwaystationSet: Array<IRailwayStationType>;
  railwaystationinstanceSet: Array<IRailwayStationInstanceType>;
  scenarios: Array<IScenarioSocEcoType>;
  trId: Scalars['Int']['output'];
};

export type ITransportScenarioInstanceType = {
  __typename?: 'TransportScenarioInstanceType';
  childrenSet: Array<ITransportScenarioInstanceType>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  parent: Maybe<ITransportScenarioInstanceType>;
  registry: Array<IComputingRegistryType>;
  scenarioType: IComputingRegistryTransportScenarioInstanceScenarioTypeChoices;
  transportProjects: Maybe<Array<Maybe<ITransportProjectType>>>;
};

export type IUpdateComputedResultParams = {
  __typename?: 'UpdateComputedResultParams';
  computingResultsObj: Maybe<IComputingResultsType>;
  errors: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  result: Maybe<Scalars['Boolean']['output']>;
};

export type IUpdateComputingRegistry = {
  __typename?: 'UpdateComputingRegistry';
  computingRegistryObj: Maybe<IComputingRegistryType>;
  errors: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  result: Maybe<Scalars['Boolean']['output']>;
};

export type IUpdateScenarioGraphItemMutation = {
  __typename?: 'UpdateScenarioGraphItemMutation';
  instance: Maybe<IGraphItemType>;
};

export type IUpdateScenarioStationMutation = {
  __typename?: 'UpdateScenarioStationMutation';
  station: Maybe<IRailwayStationInstanceType>;
};

export type IVehicleTypeType = {
  __typename?: 'VehicleTypeType';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  itinerarys: Array<IItineraryType>;
  mode: IPassTrafficVehicleTypeModeChoices;
  vehicles: Array<IPassengerTrafficType>;
};

export type IVertexType = {
  __typename?: 'VertexType';
  created: Scalars['DateTime']['output'];
  endGraphItems: Array<IGraphItemType>;
  graph: Maybe<IGraphType>;
  graphScenario: Array<IGraphItemType>;
  id: Scalars['UUID']['output'];
  scenarioStations: Array<IRailwayStationInstanceType>;
  startGraphItems: Array<IGraphItemType>;
  theGeom: Scalars['PointScalar']['output'];
};
