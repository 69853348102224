import { createApi, createStore } from 'effector';

import { EMapFeatureLayout } from '@constants/map';

export const initLayersVisible: LayersVisibleStore = {
  [EMapFeatureLayout.highway]: false,
  [EMapFeatureLayout.railway]: false,
  [EMapFeatureLayout.areaBorder]: true,
  [EMapFeatureLayout.directionHighway]: true,
  [EMapFeatureLayout.directionRailway]: true,
  [EMapFeatureLayout.directionAirline]: true,
  [EMapFeatureLayout.busStation]: true,
  [EMapFeatureLayout.railwayStation]: true,
  [EMapFeatureLayout.airport]: true,
  [EMapFeatureLayout.pointsAB]: true,
  [EMapFeatureLayout.customRailwayStation]: true,
  [EMapFeatureLayout.customRailwayGraph]: true,
};

export const $LayersVisible =
  createStore<LayersVisibleStore>(initLayersVisible);

export const LayersVisibleApi = createApi($LayersVisible, {
  setVisible: (state, payload: Partial<LayersVisibleStore>) => {
    return {
      ...state,
      ...payload,
    };
  },
  reset: () => initLayersVisible,
});

export type LayersVisibleStore = {
  [EMapFeatureLayout.highway]: boolean;
  [EMapFeatureLayout.railway]: boolean;
  [EMapFeatureLayout.areaBorder]: boolean;
  [EMapFeatureLayout.directionHighway]: boolean;
  [EMapFeatureLayout.directionRailway]: boolean;
  [EMapFeatureLayout.directionAirline]: boolean;
  [EMapFeatureLayout.busStation]: boolean;
  [EMapFeatureLayout.railwayStation]: boolean;
  [EMapFeatureLayout.airport]: boolean;
  [EMapFeatureLayout.pointsAB]: boolean;
  [EMapFeatureLayout.customRailwayStation]: boolean;
  [EMapFeatureLayout.customRailwayGraph]: boolean;
};
