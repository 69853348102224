import { Icon, Style } from 'ol/style';

import AddRailwayStation from '@components/icons/AddRailwayStation.svg';
import AddTerminalRailwayStation from '@components/icons/AddTerminalRailwayStation.svg';
import AddTerminalRailwayStationSelected from '@components/icons/AddTerminalRailwayStationSelected.svg';

import { EMapFeatureMetaKeys } from '@constants/map';

import { VectorStyleProps } from '@utils/map/styles/types';

import { EFeatureStyle } from '../../../hooks/useInitVectorLayer';

export const ptCustomRailwayStationVectorStyle = (props: VectorStyleProps) => {
  const { feature, featureStyles } = props;
  let image = AddTerminalRailwayStation;

  if (
    featureStyles &&
    Object.keys(featureStyles).includes(
      String(feature?.get(EMapFeatureMetaKeys.olId)),
    )
  ) {
    const style = featureStyles[feature?.get(EMapFeatureMetaKeys.olId)];
    switch (style) {
      case EFeatureStyle.selected:
        image = AddTerminalRailwayStationSelected;
        break;
      case EFeatureStyle.started:
        image = AddRailwayStation;
        break;
      default:
        break;
    }
  }

  return new Style({
    image: new Icon({
      src: image,
    }),
  });
};
