import { apiClient } from '@api/client';
import { IMutation, IMutationCreateVertexArgs } from '@api/gql/types';
import { gql } from '@apollo/client';

export const mutationCreateVertex = async (
  newVertex: IMutationCreateVertexArgs,
) => {
  const result = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation CreateVertexMutation(
        $geometry: PointInput!
        $scenarioId: UUID!
      ) {
        createVertex(geometry: $geometry, scenarioId: $scenarioId) {
          vertex {
            id
            theGeom
          }
        }
      }
    `,
    variables: newVertex,
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result.data?.createVertex;
};
