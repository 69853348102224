import { useUnit } from 'effector-react';
import { useEffect, useMemo } from 'react';

import { Box, SxProps, Theme } from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';
import { Loading } from '@components/Loading/Loading';

import { TableParams } from '@widgets/widget-soceco-indicators/components/ThirdPage/TableParams';
import { excludedTables } from '@widgets/widget-soceco-indicators/components/ThirdPage/configs/excludedTables';
import { SOCECO_TABLE_BODYS_MOCK } from '@widgets/widget-soceco-indicators/constants/tables/body_mock';
import { SOC_ECO_TABLE_HEADS } from '@widgets/widget-soceco-indicators/constants/tables/heads/heads';
import { SOCECO_TABLE_IDS } from '@widgets/widget-soceco-indicators/constants/tables/tableIds';
import { MapCentredWrapper } from '@widgets/widget-soceco-indicators/containers/MapContainer/MapCentredWrapper';
import { MapContainer } from '@widgets/widget-soceco-indicators/containers/MapContainer/MapContainer';
import { MapControlsContainer } from '@widgets/widget-soceco-indicators/containers/MapControlsContainer/MapControlsContainer';
import { FilterContainer } from '@widgets/widget-soceco-indicators/containers/ThirdPageContainer/FilterContainer';
import { initTileSources } from '@widgets/widget-soceco-indicators/stores/map';
import { SocEcoInfrastructureFetchEvents } from '@widgets/widget-soceco-indicators/stores/map/infrastructure';
import {
  $Settings,
  SettingsApi,
} from '@widgets/widget-soceco-indicators/stores/settings/store';
import {
  $SocEcoTable,
  SocEcoTableApi,
} from '@widgets/widget-soceco-indicators/stores/tables/store';

export const ThirdPageDashboardContainer = ({
  scenarioUUID,
  centerCoords,
  projectUUID,
  minYear,
}: ThirdPageDashboardContainerType) => {
  const { baseYear, scenarioId } = useUnit($Settings);
  const { selectedTable, tables } = useUnit($SocEcoTable);
  const { setTable } = useUnit(SocEcoTableApi);

  const isDisplayTable = useMemo(
    () => !excludedTables.includes(selectedTable),
    [selectedTable],
  );

  useEffect(() => {
    if (scenarioUUID !== scenarioId) SettingsApi.setScenarioId(scenarioUUID);
    if (minYear !== baseYear) SettingsApi.setBaseYear(minYear);
  }, [baseYear, minYear, scenarioId, scenarioUUID]);

  useEffect(() => {
    // SocEcoInfrastructureFetchEvents[SOCECO_TABLE_IDS.TABLE_1_1]();
    // SocEcoInfrastructureFetchEvents[SOCECO_TABLE_IDS.TABLE_2_5]();
    // SocEcoInfrastructureFetchEvents[SOCECO_TABLE_IDS.TABLE_3_1]();
    // SocEcoInfrastructureFetchEvents[SOCECO_TABLE_IDS.TABLE_3_2]();
    // SocEcoInfrastructureFetchEvents[SOCECO_TABLE_IDS.TABLE_3_4]();
    SocEcoInfrastructureFetchEvents[SOCECO_TABLE_IDS.TABLE_4_2]();
    SocEcoInfrastructureFetchEvents[SOCECO_TABLE_IDS.TABLE_5_2]();
    SocEcoInfrastructureFetchEvents[SOCECO_TABLE_IDS.TABLE_5_3]();
    if (projectUUID) initTileSources(projectUUID);
  }, [projectUUID]);

  useEffect(() => {
    setTable(selectedTable);
  }, [selectedTable, setTable]);

  return (
    <Box sx={ContainerMuiStyle}>
      <FilterContainer />

      <AutoFullHeight
        style={{
          padding: '24px',
          width: '100%',
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '24px',
            height: '100%',
          }}
        >
          <Box
            sx={{
              height: 'calc(50%)',
              backgroundColor: theme => theme.palette.common.white,
              borderRadius: 2,
              boxShadow: '0px 0px 8px 0px #00000026',
            }}
          >
            <MapCentredWrapper centerCoords={centerCoords}>
              <MapContainer />
            </MapCentredWrapper>

            <MapControlsContainer />
          </Box>

          {isDisplayTable && (
            <Box
              sx={{
                height: 'calc(50%)',
                backgroundColor: theme => theme.palette.common.white,
                borderRadius: 2,
                boxShadow: '0px 0px 8px 0px #00000026',
              }}
            >
              <TableParams
                headCells={SOC_ECO_TABLE_HEADS[selectedTable]}
                bodyRows={
                  tables[selectedTable].data.length
                    ? tables[selectedTable].data
                    : SOCECO_TABLE_BODYS_MOCK[selectedTable]
                }
              />
            </Box>
          )}
        </Box>
      </AutoFullHeight>

      <Loading
        isLoading={tables[selectedTable].isLoading}
        sx={{ height: 'calc(100% - 155px)' }}
      />
    </Box>
  );
};

const ContainerMuiStyle: SxProps<Theme> = {
  borderTop: theme => `1px solid ${theme.palette.border.divider}`,
  display: 'flex',
};

export type ThirdPageDashboardContainerType = {
  scenarioUUID: string | null;
  projectUUID: string | null;
  centerCoords: number[] | null;
  minYear: number;
};
