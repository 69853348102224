export const bodyRows = [
  'name',
  'year',
  'regionFrom',
  'regionTo',
  'expressTrainTime',
  'nightTrainTime',
  'dayTrainTime',
  'suburbanTrainTime',
  'expressTrainCost',
  'nightTrainCost',
  'dayTrainCost',
  'suburbanTrainCost',
  'expressTrainFrequency',
  'nightTrainFrequency',
  'dayTrainFrequency',
  'suburbanTrainFrequency',
];
