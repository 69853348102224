import { createEvent } from 'effector';
import { MapBrowserEvent } from 'ol';

import { PipeMapControls } from '@utils/map/hooks/useInitMapControls';
import { PipeTileLayer } from '@utils/map/hooks/useInitTileLayer';
import { PipeVectorLayer } from '@utils/map/hooks/useInitVectorLayer';

export const pipeMapControls = createEvent<PipeMapControls>();
export const pipeRailwayTileLayer = createEvent<PipeTileLayer>();
export const pipeHighwayTileLayer = createEvent<PipeTileLayer>();
export const pipeAreaBorderVectorLayer = createEvent<PipeVectorLayer>();
export const pipeBusStationVectorLayer = createEvent<PipeVectorLayer>();
export const pipeAirportVectorLayer = createEvent<PipeVectorLayer>();
export const pipeRailwayStationVectorLayer = createEvent<PipeVectorLayer>();
export const pipePointsABVectorLayer = createEvent<PipeVectorLayer>();
export const pipeDirectionAirlineVectorLayer = createEvent<PipeVectorLayer>();
export const pipeDirectionHighwayVectorLayer = createEvent<PipeVectorLayer>();
export const pipeDirectionRailwayVectorLayer = createEvent<PipeVectorLayer>();
export const pipeCustomRailwayStationVectorLayer =
  createEvent<PipeVectorLayer>();
export const pipeCustomRailwayGraphVectorLayer = createEvent<PipeVectorLayer>();

export const clickMap = createEvent<MapBrowserEvent<UIEvent>>();
export const initSources = createEvent();
