import { apiClient } from '@api/client';
import { IMutation, IMutationDeleteStationArgs } from '@api/gql/types';
import { gql } from '@apollo/client';

export const mutationDeleteStation = async (
  tpu: IMutationDeleteStationArgs,
) => {
  const result = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation DeleteStationMutation($tpuId: UUID!) {
        deleteStation(tpuId: $tpuId) {
          ok
        }
      }
    `,
    variables: tpu,
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result.data?.deleteStation;
};
