export const SOC_ECO_TH_6_1 = [
  {
    id: 'year',
    label: 'Год',
  },
  {
    id: 'population',
    label: 'Численность населения, тыс. чел.',
  },
  {
    id: 'shoppingCenterArea',
    label: 'Площадь ТЦ, тыс. м2',
  },
  {
    id: 'numberCollectiveAccommodationFacilities',
    label: 'Число средств коллективного размещения, мест',
  },
  {
    id: 'constructionFinancing',
    label: 'Финансирование строительства, млн. руб.',
  },
  {
    id: 'frequencyServicePromisingLine',
    label: 'Частота курсирования по перспективной линии, рейсов в сутки',
  },
];
