import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import { TextField } from '@mui/material';

import { CellObjectType } from '@widgets/widget-routes-params/components/TableRoutesParams/TableRoutesParams';

export type CashedFieldProps<T = string | number> = {
  id: string;
  itemKey: string;
  value: T;
  getValue: (
    value: CellObjectType['label'],
    key: CellObjectType['key'],
    id: CellObjectType['id'],
  ) => void;
  onBlur: () => void;
};

export const CashedField = (props: CashedFieldProps) => {
  const { id, itemKey, value, getValue, onBlur } = props;
  const [cashedValue, setCashedValue] = useState<number | string>('');
  const prevProps = useRef(props);
  const textFieldRef = useRef<HTMLInputElement>(null);
  const isFrequency =
    itemKey === 'expressTrainFrequency' ||
    itemKey === 'nightTrainFrequency' ||
    itemKey === 'dayTrainFrequency' ||
    itemKey === 'suburbanTrainFrequency';

  const changeCashedValueHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const cost: number | string = (e.target as HTMLInputElement).value;
      const normalizedValue = cost === '' ? cost : cost.replace(',', '.');
      const testReg = isFrequency ? /^\d+$/ : /^\d{0,}(\.\d{0,2})?$/;

      if (normalizedValue === '.') return;

      if (testReg.test(normalizedValue) || normalizedValue === '') {
        setCashedValue(cost);
        getValue(normalizedValue, itemKey, id);
      }
    },
    [getValue, id, isFrequency, itemKey],
  );

  const onBlurHandler = useCallback(() => {
    getValue(value === '' ? value : +value, itemKey, id);
    onBlur();
  }, [getValue, id, itemKey, onBlur, value]);

  useEffect(() => {
    let cost: string | number = +value;
    const decimal = value.toString().split('.')[1] || '';

    if (!isFrequency) {
      if (decimal) {
        if (decimal.length > 1) {
          cost = (+value).toFixed(2).toString().replace('.', ',');
        } else {
          cost = (+value).toFixed(1).toString().replace('.', ',');
        }
      } else {
        cost = value.toString().replace('.', ',');
      }
    } else {
      cost = value;
    }

    if (value === undefined || cost === cashedValue) return;

    if (isFrequency) {
      setCashedValue(+value);
    } else {
      setCashedValue(
        (+value)
          .toFixed(decimal.length > 2 ? 2 : decimal.length)
          .toString()
          .replace('.', ','),
      );
    }

    if (+prevProps.current.value !== +value) {
      prevProps.current = props;
    }
  }, [cashedValue, isFrequency, itemKey, props, value]);

  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, []);

  return (
    <TextField
      value={cashedValue}
      onChange={changeCashedValueHandler}
      onBlur={onBlurHandler}
      inputRef={textFieldRef}
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        minWidth: '5ch',
        width: '100%',
        height: '100%',
        fieldset: {
          border: 'none',
        },
        input: {
          fontSize: theme => theme.typography.pxToRem(12),
          textAlign: 'center',
          height: '100%',
          p: 0,
        },
        [`.MuiInputBase-root.MuiOutlinedInput-root`]: {
          height: '100%',
        },
        [`.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused`]: {
          input: {
            boxShadow: 'inset 0px 0px 2px 1px #E21A1A',
            p: 0,
          },
        },
      }}
    />
  );
};
