import { Divider, Grid, Typography, alpha } from '@mui/material';

import { LineIcon } from '@components/Map/LineIcon';
import { RailsIcon, TrainIcon } from '@components/icons';
import { TpuIcon } from '@components/icons/TPU';

import { CircleItem } from '@features/ag-forecast/components/MapLegend/CircleItem';
import { LineItem } from '@features/ag-forecast/components/MapLegend/LineItem';
import { EFilterMapLayout } from '@features/ag-forecast/constants/EFilterMapLayout';

type MapLegendProps = {
  isVisibleInspector?: boolean;
  layout: EFilterMapLayout;
};

export const MapLegend = ({ isVisibleInspector, layout }: MapLegendProps) => {
  return (
    <Grid
      container
      direction={'column'}
      flexWrap={'nowrap'}
      sx={{
        backgroundColor: theme => theme.palette.common.white,
        boxShadow: '0px 0px 8px 0px #00000026',
        maxHeight: 'calc(100% - 260px);',
        overflow: 'hidden',
        width: '420px',
        borderRadius: theme => theme.shape.borderRadius,
        position: 'absolute',
        top: theme => theme.typography.pxToRem(16 + 40 + 12),
        right: theme =>
          theme.typography.pxToRem(isVisibleInspector ? 500 + 24 : 24),
        zIndex: 1,
      }}
      p={'44px 12px 32px 44px'}
    >
      <Grid
        item
        mb={3}
      >
        <Typography
          variant={'subtitle2'}
          gutterBottom
        >
          {layout === EFilterMapLayout.Infrastructure
            ? 'Инфраструктура'
            : 'Пассажиропотоки'}
        </Typography>
      </Grid>

      <Grid
        container
        item
        gap={1.5}
        sx={{
          overflowY: 'auto',
        }}
        pr={3}
      >
        <LineItem
          label="Автомобильный транспорт"
          color="autoV2"
        />

        {layout === EFilterMapLayout.Infrastructure && (
          <LineItem
            label="Планируемый автомобильный транспорт"
            color="autoV2"
            planned
          />
        )}

        <Divider
          sx={{
            borderColor: theme => theme.palette.border.divider,
            width: '100%',
          }}
          flexItem
        />

        <LineItem
          label="Троллейбус"
          color="trolleybus"
        />

        {layout === EFilterMapLayout.Infrastructure && (
          <LineItem
            label="Планируемый троллейбус"
            color="trolleybus"
            planned
          />
        )}

        <Divider
          sx={{
            borderColor: theme => theme.palette.border.divider,
            width: '100%',
          }}
          flexItem
        />

        <LineItem
          label="Трамвай"
          color="expressways"
        />

        {layout === EFilterMapLayout.Infrastructure && (
          <LineItem
            label="Планируемый трамвай"
            color="expressways"
            planned
          />
        )}

        <Divider
          sx={{
            borderColor: theme => theme.palette.border.divider,
            width: '100%',
          }}
          flexItem
        />

        <LineItem
          label="Метрополитен"
          color="metro"
        />

        {layout === EFilterMapLayout.Infrastructure && (
          <LineItem
            label="Планируемый метрополитен"
            color="metro"
            planned
          />
        )}

        <Divider
          sx={{
            borderColor: theme => theme.palette.border.divider,
            width: '100%',
          }}
          flexItem
        />

        <LineItem
          label="Водный транспорт"
          color="waterTransport"
        />

        {layout === EFilterMapLayout.Infrastructure && (
          <LineItem
            label="Планируемый водный транспорт"
            color="waterTransport"
            planned
          />
        )}

        <Divider
          sx={{
            borderColor: theme => theme.palette.border.divider,
            width: '100%',
          }}
          flexItem
        />

        <Grid
          display={'grid'}
          container
          gridTemplateColumns={'2fr 10fr'}
          gap={2}
          alignItems={'center'}
        >
          {layout === EFilterMapLayout.Infrastructure ? (
            <RailsIcon
              sx={{
                color: theme => theme.palette.customs.train,
                width: theme => theme.typography.pxToRem(48),
              }}
            />
          ) : (
            <LineIcon
              color={'train'}
              height={4}
            />
          )}
          <Typography
            variant="body2"
            color="text.secondary"
          >
            Железные дороги
          </Typography>
        </Grid>

        {layout === EFilterMapLayout.Infrastructure ? (
          <Grid
            display={'grid'}
            container
            gridTemplateColumns={'2fr 10fr'}
            gap={2}
            alignItems={'center'}
          >
            <RailsIcon
              sx={{
                color: theme => theme.palette.customs.trainPlanned,
                width: theme => theme.typography.pxToRem(48),
              }}
            />
            <Typography
              variant="body2"
              color="text.secondary"
            >
              Планируемые железные дороги
            </Typography>
          </Grid>
        ) : null}

        <Grid
          display={'grid'}
          container
          gridTemplateColumns={'2fr 10fr'}
          gap={2}
          alignItems={'center'}
        >
          <TrainIcon
            sx={{
              color: theme => theme.palette.customs.train,
              width: theme => theme.typography.pxToRem(20),
              justifySelf: 'center',
            }}
          />
          <Typography
            variant="body2"
            color="text.secondary"
          >
            Железнодорожные станции
          </Typography>
        </Grid>

        {layout === EFilterMapLayout.Infrastructure ? (
          <Grid
            display={'grid'}
            container
            gridTemplateColumns={'2fr 10fr'}
            gap={2}
            alignItems={'center'}
          >
            <TrainIcon
              sx={{
                color: theme => theme.palette.customs.trainPlanned,
                width: theme => theme.typography.pxToRem(20),
                justifySelf: 'center',
              }}
            />
            <Typography
              variant="body2"
              color="text.secondary"
            >
              Планируемые железнодорожные станции
            </Typography>
          </Grid>
        ) : null}

        <Grid
          display={'grid'}
          container
          gridTemplateColumns={'2fr 10fr'}
          gap={2}
          alignItems={'center'}
        >
          <TrainIcon
            sx={{
              color: '#E21A1A',
              width: theme => theme.typography.pxToRem(20),
              justifySelf: 'center',
            }}
          />
          <Typography
            variant="body2"
            color="text.secondary"
          >
            Проектные ТПУ
          </Typography>
        </Grid>

        {layout === EFilterMapLayout.Infrastructure ? (
          <Grid
            display={'grid'}
            container
            gridTemplateColumns={'2fr 10fr'}
            gap={2}
            alignItems={'center'}
          >
            <TrainIcon
              sx={{
                color: alpha('#E21A1A', 0.3),
                width: theme => theme.typography.pxToRem(20),
                justifySelf: 'center',
              }}
            />
            <Typography
              variant="body2"
              color="text.secondary"
            >
              Проектные планируемые ТПУ
            </Typography>
          </Grid>
        ) : null}

        <Grid
          display={'grid'}
          container
          gridTemplateColumns={'2fr 10fr'}
          gap={2}
          alignItems={'center'}
        >
          <TpuIcon
            sx={{
              color: '#E21A1A',
              width: theme => theme.typography.pxToRem(20),
              justifySelf: 'center',
            }}
          />
          <Typography
            variant="body2"
            color="text.secondary"
          >
            Пользовательские ТПУ
          </Typography>
        </Grid>

        {/*{layout === EFilterMapLayout.Infrastructure ? (*/}
        {/*  <Grid*/}
        {/*    display={'grid'}*/}
        {/*    container*/}
        {/*    gridTemplateColumns={'2fr 10fr'}*/}
        {/*    gap={2}*/}
        {/*    alignItems={'center'}*/}
        {/*  >*/}
        {/*    <TpuIcon*/}
        {/*      sx={{*/}
        {/*        color: alpha('#E21A1A', 0.3),*/}
        {/*        width: theme => theme.typography.pxToRem(20),*/}
        {/*        justifySelf: 'center',*/}
        {/*      }}*/}
        {/*    />*/}
        {/*    <Typography*/}
        {/*      variant="body2"*/}
        {/*      color="text.secondary"*/}
        {/*    >*/}
        {/*      Пользовательские планируемые ТПУ*/}
        {/*    </Typography>*/}
        {/*  </Grid>*/}
        {/*) : null}*/}

        <Divider
          sx={{
            borderColor: theme => theme.palette.border.divider,
            width: '100%',
          }}
          flexItem
        />

        <LineItem
          label="Прочие виды транспорта"
          color="otherTransport"
        />

        {layout === EFilterMapLayout.Infrastructure && (
          <LineItem
            label="Планируемые прочие виды транспорта"
            color="otherTransport"
            planned
          />
        )}

        <Divider
          sx={{
            borderColor: theme => theme.palette.border.divider,
            width: '100%',
          }}
          flexItem
        />

        <LineItem
          label="Границы транспортных районов"
          color="transportAreaInspectorBoundaries"
        />

        <CircleItem
          label="Центры транспортных районов"
          color="transportAreaInspectorBoundaries"
        />
      </Grid>
    </Grid>
  );
};
