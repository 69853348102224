import { Store } from 'effector';
import MVT from 'ol/format/MVT';
import VectorTileSource from 'ol/source/VectorTile';

import { TILE_SERVICE_LAYERS } from '@constants/tileServiceLayers';

import { FilterMapData } from '@features/passenger-traffic/stores';

import { keycloak } from '@system/keycloak/keycloak';

import { ApiMapPrivateTiles } from '@utils/map/tools/ApiMapPrivateTiles';

/** Источник для тайлового слоя автодорог */
export const highwaySource = () => {
  if (!keycloak.token) throw new Error('Keycloak token not found');
  return new VectorTileSource({
    format: new MVT(),
    url: `${
      import.meta.env.APP_API_MAP_SOURCE
    }highway/{z}/{x}/{y}.pbf?status=1`,
    tileSize: 512,
    tileLoadFunction: ApiMapPrivateTiles(keycloak.token),
  });
};

/** Источник для тайлового слоя планируемых автодорог */
export const plannedHighwaySource = (store: Store<FilterMapData>) => {
  if (!keycloak.token) throw new Error('Keycloak token not found');
  return new VectorTileSource({
    format: new MVT(),
    tileSize: 512,
    tileUrlFunction: ([z, x, y]) => {
      const year =
        (store as unknown as RootStoreInstance<FilterMapData>).stateRef!
          .current!.plannedYear || '2019';
      return `${
        import.meta.env.APP_API_MAP_SOURCE
      }highway/${z}/${x}/${y}.pbf?status=2&year=${year}`;
    },
    tileLoadFunction: ApiMapPrivateTiles(keycloak.token),
  });
};

/** Источник для тайлового слоя жд дорог */
export const railwaySource = () => {
  if (!keycloak.token) throw new Error('Keycloak token not found');
  return new VectorTileSource({
    format: new MVT(),
    url: `${
      import.meta.env.APP_API_MAP_SOURCE
    }railway/{z}/{x}/{y}.pbf?status=1`,
    tileSize: 512,
    tileLoadFunction: ApiMapPrivateTiles(keycloak.token),
  });
};

/** Источник для тайлового слоя планируемых жд дорог */
export const plannedRailwaySource = (store: Store<FilterMapData>) => {
  if (!keycloak.token) throw new Error('Keycloak token not found');
  return new VectorTileSource({
    format: new MVT(),
    tileSize: 512,
    tileUrlFunction: ([z, x, y]) => {
      const year =
        (store as unknown as RootStoreInstance<FilterMapData>).stateRef!
          .current!.plannedYear || '2019';
      return `${
        import.meta.env.APP_API_MAP_SOURCE
      }railway/${z}/${x}/${y}.pbf?status=2&year=${year}`;
    },
  });
};

export const createAgAllGraphSource = (projectUUID: string) => {
  if (!keycloak.token) throw new Error('Keycloak token not found');
  return new VectorTileSource({
    format: new MVT(),
    url: `${import.meta.env.APP_API_MAP_SOURCE_V2}tiles/${
      TILE_SERVICE_LAYERS.BASE_GRAPHS.PATH
    }/{z}/{x}/{y}.pbf?cql_filter=project_id IN ('${projectUUID}')`,
    tileSize: 512,
  });
};

/** Источник для тайлового слоя графов агломерации по сущьполу */
export const createAgBaseGraphSource = (
  vehicleUUID: string,
  projectUUID: string,
) => {
  if (!keycloak.token) throw new Error('Keycloak token not found');
  return new VectorTileSource({
    format: new MVT(),
    url: `${import.meta.env.APP_API_MAP_SOURCE_V2}tiles/${
      TILE_SERVICE_LAYERS.BASE_GRAPHS.PATH
    }/{z}/{x}/{y}.pbf?cql_filter=project_id IN ('${projectUUID}') AND transport_id IN ('${vehicleUUID}')`,
    tileSize: 512,
  });
};

/** Источник для тайлового слоя графов агломерации */
export const createAgComputedGraphSource = (
  vehicleUUID: string,
  projectUUID: string,
  scenarioUUID: string,
  timeUUID: string,
  year: number,
) => {
  if (!keycloak.token) throw new Error('Keycloak token not found');
  return new VectorTileSource({
    format: new MVT(),
    url: `${import.meta.env.APP_API_MAP_SOURCE_V2}tiles/${
      TILE_SERVICE_LAYERS.COMPUTED_GRAPHS.PATH
    }/{z}/{x}/{y}.pbf?cql_filter=project_id IN ('${projectUUID}') AND transport_id IN ('${vehicleUUID}') AND scenario_id IN ('${scenarioUUID}') AND year IN ('${year}') AND time_slot_id IN ('${timeUUID}') `,
    tileSize: 512,
  });
};

export const createAgGraphSourceTest = (
  vehicleUUID: string,
  projectUUID: string,
  scenarioUUID: string,
) => {
  if (!keycloak.token) throw new Error('Keycloak token not found');
  return new VectorTileSource({
    format: new MVT(),
    url: `${import.meta.env.APP_API_MAP_SOURCE_V2}tiles/${
      TILE_SERVICE_LAYERS.TEST.PATH
    }/{z}/{x}/{y}.pbf?cql_filter=project_id IN ('${projectUUID}') AND transport_id IN ('${vehicleUUID}') AND scenario_id IN ('${scenarioUUID}') AND year IN ('2030')`,
    tileSize: 512,
  });
};

/** Источник для тайлового слоя транспортных регионов агломерации */
export const createAgTransportDistrictsSource = (projectUUID: string) => {
  if (!keycloak.token) throw new Error('Keycloak token not found');
  return new VectorTileSource({
    format: new MVT(),
    url: `${import.meta.env.APP_API_MAP_SOURCE_V2}tiles/${
      TILE_SERVICE_LAYERS.TRANSPORT_DISTRICTS.PATH
    }/{z}/{x}/{y}.pbf?cql_filter=project_id IN ('${projectUUID}')`,
    tileSize: 512,
  });
};

/** Источник для тайлового слоя пассажиропотоков между транспортными регионами */
export const createAreaBordersPassengerFlowSource = (regionUUID: string) => {
  if (!keycloak.token) throw new Error('Keycloak token not found');
  return new VectorTileSource({
    format: new MVT(),
    url: `${
      import.meta.env.APP_API_MAP_SOURCE_V2
    }api/v1/tiles/rzhd_passtraffic/public/${
      TILE_SERVICE_LAYERS.AREA_BORDER_PASSENGER_FLOW.PATH
    }/{z}/{x}/{y}.pbf?cql_filter=region_from_id%20IN%20(%27${regionUUID}%27)`,
    tileSize: 512,
  });
};

/** Источник для тайлового слоя графов пассажиропотоков между транспортными регионами */
export const createPathPassengerFlowSource = (regionUUID: string) => {
  if (!keycloak.token) throw new Error('Keycloak token not found');
  return new VectorTileSource({
    format: new MVT(),
    url: `${
      import.meta.env.APP_API_MAP_SOURCE_V2
    }api/v1/tiles/rzhd_passtraffic/public/${
      TILE_SERVICE_LAYERS.PATH_PASSENGER_FLOW.PATH
    }/{z}/{x}/{y}.pbf?cql_filter=region_from_id%20IN%20(%27${regionUUID}%27)`,
    tileSize: 512,
  });
};

type RootStoreInstance<T> = {
  stateRef: {
    current: T;
  };
};
