import { createApi, createStore } from 'effector';

import { EMapViewMode } from '@features/ag-forecast/constants/EMapViewMode';

const initState: UIState = {
  filter: true,
  inspector: false,
  mapLegend: false,
  editTPUMode: false,
  placePoint: false,
  placeTPU: false,
  drawGraph: false,
  viewMode: EMapViewMode.infrastructure,
  isShowPassFlowTable: false,
  isSetPoint: false,
};

export const $UI = createStore<UIState>(initState);

export const UIApi = createApi($UI, {
  toggleFilter: store => ({
    ...store,
    filter: !store.filter,
  }),
  toggleInspector: store => ({
    ...store,
    inspector: !store.inspector,
  }),
  toggleMapLegend: store => ({
    ...store,
    mapLegend: !store.mapLegend,
  }),
  setFilter: (store, payload: boolean) => ({
    ...store,
    filter: payload,
  }),
  setInspector: (store, payload: boolean) => ({
    ...store,
    inspector: payload,
  }),
  setMapLegend: (store, payload: boolean) => ({
    ...store,
    mapLegend: payload,
  }),
  setEditTPUMode: (store, payload: boolean) => ({
    ...store,
    editTPUMode: payload,
  }),
  setPlacePoint: (store, payload: boolean) => ({
    ...store,
    placePoint: payload,
  }),
  setPlaceTPU: (store, payload: boolean) => ({
    ...store,
    placeTPU: payload,
  }),
  togglePlaceTPU: store => ({
    ...store,
    placeTPU: !store.placeTPU,
  }),
  setCreateGraph: (store, payload: boolean) => ({
    ...store,
    drawGraph: payload,
  }),
  toggleDrawGraph: store => ({
    ...store,
    drawGraph: !store.drawGraph,
  }),
  setViewMode: (store, payload: EMapViewMode) => ({
    ...store,
    viewMode: payload,
  }),
  setShowPassFlowTable: (store, payload: boolean) => ({
    ...store,
    isShowPassFlowTable: payload,
  }),
  setIsSetPoint: (store, payload: boolean) => ({
    ...store,
    isSetPoint: payload,
  }),
  reset: () => initState,
});

type UIState = {
  filter: boolean;
  inspector: boolean;
  mapLegend: boolean;
  editTPUMode: boolean;
  placePoint: boolean;
  placeTPU: boolean;
  drawGraph: boolean;
  viewMode: EMapViewMode;
  isShowPassFlowTable: boolean;
  isSetPoint: boolean;
};
