import { apiClient } from '@api/client';
import {
  IQuery as IQueryAg,
  IQueryGetScenarioByIdArgs,
} from '@api/gql/ag-types';
import { IQuery, IQueryFindRegionArgs } from '@api/gql/types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

import { EAgScenarioStatusType } from '@features/ag-forecast/constants/agScenarioStatuses';
import { AgGeometryApi, initSources } from '@features/ag-forecast/stores/map';
import { fetchTpuInfrastructure } from '@features/ag-forecast/stores/map/editor';
import {
  $FeatureSettings,
  FeatureSettingsApi,
  FindRegionFx,
  fetchScenarioStatus,
  fetchScenarioStatusFx,
} from '@features/ag-forecast/stores/settings/store';

// При получении координатов агломерации, запросить UUID региона
sample({
  clock: AgGeometryApi.setAgGeometry,
  fn: payload => {
    if (!payload.center?.length) throw Error('Center is not defined');
    return {
      lat: payload.center[0],
      lon: payload.center[1],
    } as IQueryFindRegionArgs;
  },
  target: FindRegionFx,
});

sample({
  clock: FindRegionFx.done,
  fn: request => {
    return request.result?.transportRegion?.id;
  },
  target: FeatureSettingsApi.setRegionUUID,
});

FindRegionFx.use(async params => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query QueryFindRegion($lon: Float!, $lat: Float!) {
        findRegion(lon: $lon, lat: $lat) {
          transportRegion {
            id
          }
        }
      }
    `,
    variables: {
      lon: params.lon,
      lat: params.lat,
    },
  });
  return response.data.findRegion;
});

// Запросить статус текущего сценария
sample({
  clock: fetchScenarioStatus,
  source: { FeatureSettings: $FeatureSettings },
  fn: ({ FeatureSettings }) => {
    return {
      scenarioId: FeatureSettings.scenarioUUID,
    } as IQueryGetScenarioByIdArgs;
  },
  target: fetchScenarioStatusFx,
});

fetchScenarioStatusFx.use(async params => {
  const response = await apiClient.query<
    Pick<IQueryAg, 'getScenarioById'>,
    IQueryGetScenarioByIdArgs
  >({
    query: gql`
      query QueryGetScenarioById($scenarioId: String!) {
        getScenarioById(scenarioId: $scenarioId) {
          resultStatus
        }
      }
    `,
    variables: {
      scenarioId: params.scenarioId,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });
  await new Promise(resolve => setTimeout(resolve, 2000));
  return response.data.getScenarioById || null;
});

// Записать статус текущего сценария
sample({
  clock: fetchScenarioStatusFx.done,
  fn: request => {
    return request.result?.resultStatus as EAgScenarioStatusType;
  },
  target: FeatureSettingsApi.setScenarioStatus,
});

// Если статус рассчета изменился на успешно, перезапросить инфраструктуру тпу и всех тайлов
sample({
  clock: FeatureSettingsApi.setScenarioStatus,
  source: { FeatureSettings: $FeatureSettings },
  filter: ({ FeatureSettings }, payload) =>
    payload === EAgScenarioStatusType.Success &&
    FeatureSettings.scenarioStatus !== EAgScenarioStatusType.Success,
  target: [fetchTpuInfrastructure, initSources],
});

// Если статус ожидания, запросить еще раз
sample({
  clock: fetchScenarioStatusFx.done,
  filter: request =>
    request.result?.resultStatus === EAgScenarioStatusType.Pending ||
    request.result?.resultStatus === EAgScenarioStatusType.Queue,
  target: fetchScenarioStatus,
});
