import {
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';

const rowStyleSX: SxProps<Theme> = {
  fontSize: theme => theme.typography.pxToRem(12),
  ['&:not(:first-of-type)']: {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
};

const THCellStyleSX: SxProps<Theme> = {
  fontSize: theme => theme.typography.pxToRem(12),
  bgcolor: theme => theme.palette.border.divider,
  ['&:not(:first-of-type)']: {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
  lineHeight: 1.25,
  boxShadow: theme => 'inset 0 0 1px 0px ' + theme.palette.border.stroke,
  position: 'sticky',
  top: 0,
  zIndex: 1,
};

type BodyRowsType = Record<string, string | number | null | undefined>;

type TableParamsType = {
  headCells: { label: string; id: string }[];
  bodyRows: BodyRowsType[];
};

export const TableParams = (props: TableParamsType) => {
  const { headCells, bodyRows } = props;

  const createCells = (row: BodyRowsType) => {
    if (!row) return;
    const cells: (string | number | null | undefined)[] = [];

    for (const headCell of headCells) {
      for (const [key, value] of Object.entries(row)) {
        if (headCell.id === key) {
          cells.push(value);
          break;
        }
      }
    }

    return cells.map((cell, i) => (
      <TableCell
        key={i}
        sx={rowStyleSX}
      >
        {cell}
      </TableCell>
    ));
  };

  return (
    <TableContainer
      sx={{
        height: '100%',
      }}
    >
      <Table
        size="medium"
        aria-labelledby="tableTitle"
        sx={{
          width: '100%',
        }}
      >
        <TableHead>
          <TableRow>
            {headCells.map(headCell => (
              <TableCell
                key={headCell.id}
                align={'left'}
                padding={'normal'}
                sx={THCellStyleSX}
              >
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {bodyRows.map((row, i) => (
            <TableRow key={i}>{createCells(row)}</TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
