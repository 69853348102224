import { apiClient } from '@api/client';
import { IMutation, IProjectType, IQuery } from '@api/gql/tpu-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import {
  $ProjectRegistry,
  copyProjectFx,
  getListOfProjectsFn,
  getListOfProjectsFx,
  resetProjectRegistryFn,
  setPageFn,
  setRowsPerPageFn,
  setSearchFn,
  setSortFn,
} from './project-regisrty.store';

import { TPU_CLIENT_NAME } from '@constants/api';

import { SORT_VALUE } from '@features/tpu-project-registry/constants';
import { removeProjectFx } from '@features/tpu-project-registry/stores/modal-delete-project/modal-delete-project.store';
import { debounceEffect } from '@features/tpu-project-registry/utils';

$ProjectRegistry
  .reset(resetProjectRegistryFn)
  .on(getListOfProjectsFx.done, (state, { result }) => {
    const projects = (result?.results?.filter(item => !!item) ??
      []) as IProjectType[];
    const count = result?.pagination?.count ?? 0;

    return {
      ...state,
      projects,
      count,
    };
  })
  .on(setSearchFn, (state, payload) => ({ ...state, search: payload }))
  .on(setPageFn, (state, payload) => ({ ...state, page: payload }))
  .on(setRowsPerPageFn, (state, payload) => ({
    ...state,
    page: 1,
    pageSize: payload,
  }))
  .on(setSortFn, (state, payload) => {
    const isSortModelExist = !!payload.length;
    if (!isSortModelExist) return { ...state, sort: null };

    const [{ field, sort }] = payload;

    return { ...state, sort: [SORT_VALUE[sort as 'asc' | 'desc'][field]] };
  })
  .on(getListOfProjectsFx.pending, (state, payload) => {
    return { ...state, loading: payload };
  });

sample({
  clock: [
    getListOfProjectsFn,
    setSearchFn,
    setPageFn,
    setRowsPerPageFn,
    setSortFn,
    removeProjectFx.done,
    copyProjectFx.done,
  ],
  source: $ProjectRegistry,
  fn: sourceData => ({
    page: sourceData.page,
    pageSize: sourceData.pageSize,
    sort: sourceData.sort,
    search: sourceData.search,
  }),
  target: debounceEffect(getListOfProjectsFx),
});

getListOfProjectsFx.use(async params => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query getListOfProjects(
        $page: Int
        $pageSize: Int
        $search: String
        $sort: [ProjectSortEnum!]
      ) {
        allProjects(
          page: $page
          pageSize: $pageSize
          search: $search
          sort: $sort
        ) {
          results {
            id
            name
            hub {
              name
              code
              agglomeration {
                id
                name
              }
            }
            state
            modified
          }
          pagination {
            count
          }
        }
      }
    `,
    variables: params,
    context: { clientName: TPU_CLIENT_NAME },
  });

  return response.data.allProjects;
});

copyProjectFx.use(async id => {
  try {
    const response = await apiClient.mutate<IMutation>({
      mutation: gql`
        mutation CopyProjectMutation($projectId: UUID!) {
          copyProject(projectId: $projectId) {
            ok
            errors {
              key
              message
            }
            newProject {
              id
              name
              state
              hub {
                name
                code
                id
                creationType
                agglomeration {
                  id
                  name
                }
              }
              dataFiles {
                id
                state
                dataType
              }
              floorFiles {
                id
                description
              }
            }
          }
        }
      `,
      variables: {
        projectId: id,
      },
      context: { clientName: TPU_CLIENT_NAME },
    });

    console.log(response);

    return response.data?.copyProject;
  } catch (e) {
    console.log(e);
  }
});
