import { useKeycloak } from '@react-keycloak/web';
import { useMemo, useRef } from 'react';

import ImageAgglomeration from '@assets/bg-agglomeration.png';
import ImageAnalytics from '@assets/bg-analytics.png';
import ImageBusinessEnvironment from '@assets/bg-business-environment.png';
import ImageFreightFlow from '@assets/bg-freight-flow.png';
import ImageInfrastructure from '@assets/bg-infrastructure.png';
import ImagePassengerTraffic from '@assets/bg-passenger-traffic.png';
import ImageSchedule from '@assets/bg-schedule.png';
import ImageSocioEconomicAndBudgetaryEffects from '@assets/bg-socio-economic-and-budgetary-effects.png';
import ImageStation from '@assets/bg-station.png';

import { Box, Stack } from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';

import { ACCESS_GROUPS } from '@constants/accessGroups';
import { ROUTES } from '@constants/routes';

import { CardModule } from '@features/modules/components/card/CardModule';
import { MainCardModule } from '@features/modules/components/card/MainCardModule';

export const ContainerModules = () => {
  const scrollContainerRef = useRef<null | HTMLDivElement>(null);
  const { keycloak } = useKeycloak();

  let scrollInterval: NodeJS.Timeout | null = null;

  const startScroll = (direction: 'right' | 'left') => {
    const scrollAmount = direction === 'left' ? -10 : 10;
    scrollInterval = setInterval(() => {
      if (scrollContainerRef.current) {
        let newScrollPosition =
          scrollContainerRef.current.scrollLeft + scrollAmount;
        // Корректировка позиции прокрутки, если она выходит за допустимые пределы
        newScrollPosition = Math.max(0, newScrollPosition);
        newScrollPosition = Math.min(
          scrollContainerRef.current.scrollWidth -
            scrollContainerRef.current.clientWidth,
          newScrollPosition,
        );
        scrollContainerRef.current.scrollLeft = newScrollPosition;

        // Остановка прокрутки, если достигнут край
        if (
          newScrollPosition <= 0 ||
          newScrollPosition >=
            scrollContainerRef.current.scrollWidth -
              scrollContainerRef.current.clientWidth
        ) {
          stopScroll();
        }
      }
    }, 50);
  };

  const isLock = useMemo(
    () => ({
      agglomeration: !(
        keycloak.tokenParsed?.assignedGroups as string[]
      ).includes(ACCESS_GROUPS.AGGLOMERATION),
      tpu: !(keycloak.tokenParsed?.assignedGroups as string[]).includes(
        ACCESS_GROUPS.TPU,
      ),
      passengerTraffic: !(
        keycloak.tokenParsed?.assignedGroups as string[]
      ).includes(ACCESS_GROUPS.PASS_FLOW),
    }),
    [keycloak.tokenParsed?.assignedGroups],
  );

  const stopScroll = () => {
    if (scrollInterval) {
      clearInterval(scrollInterval);
    }
  };

  return (
    <>
      <AutoFullHeight>
        <Stack height="100%">
          <Box
            ref={scrollContainerRef}
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(5, 1fr)',
              gridTemplateRows: 'repeat(2, 1fr)',
              alignItems: 'center',
              justifyItems: 'center',
              width: '100%',
              height: '100%',
              maxWidth: '2000px',
              maxHeight: '900px',
              scrollBehavior: 'smooth',
              overflowX: 'hidden',
              margin: 'auto',
              '& > *': {
                margin: '8px',
              },
            }}
          >
            <MainCardModule />
            <CardModule
              title="Пассажиропоток"
              description="Моделирование и прогнозирование междугородных пассажиропотоков в разрезе видов транспорта и корреспонденций"
              image={ImagePassengerTraffic}
              link={ROUTES.PASSENGER_TRAFFIC}
              locked={isLock.passengerTraffic}
            />
            <CardModule
              title="Агломерация"
              description="Взаимоувязка проектов развития железнодорожного транспорта с городскими транспортными системами"
              image={ImageAgglomeration}
              link={ROUTES.AGGLOMERATION}
              locked={isLock.agglomeration}
            />
            <CardModule
              title="ТПУ и вокзалы"
              description="Расчет оптимальных параметров и оценка коммерческой эффективности транспортно-пересадочных узлов"
              image={ImageStation}
              link={ROUTES.TPU}
              locked={isLock.tpu}
            />
            <CardModule
              isPlanned
              title="Грузопоток"
              description="Моделирование и прогнозирование грузопотоков в разрезе видов грузов, видов транспорта и корреспонденций"
              image={ImageFreightFlow}
              link={ROUTES.GENERAL}
            />
            <CardModule
              isPlanned
              title="Инфраструктура"
              description="Определение набора и параметров проектов строительства и реконструкции железнодорожной инфраструктуры"
              image={ImageInfrastructure}
              link={ROUTES.GENERAL}
            />
            <CardModule
              isPlanned
              title="Социально-экономические эффекты"
              description="Оценка социально-экономических эффектов от реализации инфраструктурных проектов"
              image={ImageSocioEconomicAndBudgetaryEffects}
              link={ROUTES.GENERAL}
            />
            <CardModule
              isPlanned
              title="Бизнес-окружение"
              description="Оценка влияния инфраструктурных проектов на прилегающие территории и конкретные предприятия"
              image={ImageBusinessEnvironment}
              link={ROUTES.GENERAL}
            />
            <CardModule
              isPlanned
              title="График движения"
              description="Автоматическое построение графиков движения поездов, оптимизация графика с учетом прогнозируемого пассажиропотока и грузопотока"
              image={ImageSchedule}
              link={ROUTES.GENERAL}
            />
            <CardModule
              isPlanned
              title="Аналитика"
              description="Аналитический инструмент для всесторонней оценки показателей работы транспортной системы"
              image={ImageAnalytics}
              link={ROUTES.GENERAL}
            />
          </Box>
        </Stack>
      </AutoFullHeight>
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '250px',
          height: '100%',
        }}
        onMouseOver={() => startScroll('left')}
        onMouseLeave={stopScroll}
      />
      <Box
        sx={{
          position: 'absolute',
          right: 0,
          top: 0,
          width: '250px',
          height: '100%',
        }}
        onMouseOver={() => startScroll('right')}
        onMouseLeave={stopScroll}
      />
    </>
  );
};
