import { useUnit } from 'effector-react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Stack } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { SxProps } from '@mui/system';

import { AutoFullHeight } from '@components/AutoFullHeight';

import { ROUTES } from '@constants/routes';

import { Loading } from '@features/pt-forecast-new/components/Loading/Loading';
import { CalculationNotification } from '@features/pt-forecast-new/components/Notifications/CalculationNotification';
import { FinallyCalculationNotification } from '@features/pt-forecast-new/components/Notifications/FinallyCalculationNotification';
import { ToolBar } from '@features/pt-forecast-new/components/ToolBar/ToolBar';
import { DashboardToolBarContainer } from '@features/pt-forecast-new/containers/DashboardToolBarContainer/DashboardToolBarContainer';
import { DashboardsContainer } from '@features/pt-forecast-new/containers/DashboardsContainer/DashboardsContainer';
import { ForecastDetailsContainer } from '@features/pt-forecast-new/containers/ForecastDetailsContainer/ForecastDetailsContainer';
import { InspectorContainer } from '@features/pt-forecast-new/containers/InspectorContainer/InspectorContainer';
import { MapContainer } from '@features/pt-forecast-new/containers/MapContainer/MapContainer';
import { MapControlsContainer } from '@features/pt-forecast-new/containers/MapControlsContainer/MapControlsContainer';
import { MapFiltersContainer } from '@features/pt-forecast-new/containers/MapFiltersContainer/MapFiltersContainer';
import { NewInfrastructureContainer } from '@features/pt-forecast-new/containers/NewInfrastructureContainer/NewInfrastructureContainer';
import { RecalculationContainer } from '@features/pt-forecast-new/containers/RecalculationContainer/RecalculationContainer';
import { YearLineContainer } from '@features/pt-forecast-new/containers/YearLineContainer/YearLineContainer';
import { searchRegions } from '@features/pt-forecast-new/stores/lists/regionOptions';
import { initSources } from '@features/pt-forecast-new/stores/map';
import { getGraphItemsFx } from '@features/pt-forecast-new/stores/map/editor';
import { VectorGeometryEvents } from '@features/pt-forecast-new/stores/map/vectorFeatures';
import { FeatureSettingsApi } from '@features/pt-forecast-new/stores/settings';
import { $UI, UIApi } from '@features/pt-forecast-new/stores/ui';
import { storesReset } from '@features/pt-forecast-new/stores/unmountReset';

import { WidgetRoutesParams } from '@widgets/widget-routes-params/container';

const ContainerMuiStyle: SxProps<Theme> = {
  position: 'relative',
};

export const PassengerTrafficComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const {
    showDashboard,
    dashboardPage,
    widgetRoutesParams,
    panels: { forecastDetails, inspector, newInfrastructure },
  } = useUnit($UI);
  const { setVisibleProgressGeometry, setVisibleWidgetRoutesParams } =
    useUnit(UIApi);

  const { setScenarioId } = useUnit(FeatureSettingsApi);

  const [calculationScenarioId] = useState(queryParams.get('id'));

  useEffect(() => {
    if (!calculationScenarioId)
      return navigate(ROUTES.PASSENGER_TRAFFIC_CONSTRUCTOR);

    setScenarioId(calculationScenarioId);

    return () => {
      setVisibleProgressGeometry(false);
    };
  }, [
    calculationScenarioId,
    navigate,
    setScenarioId,
    setVisibleProgressGeometry,
  ]);

  useEffect(() => {
    searchRegions('');

    VectorGeometryEvents.fetchAirports();
    VectorGeometryEvents.fetchRailwayStations();
    VectorGeometryEvents.fetchBusStations();
    VectorGeometryEvents.fetchTransportRegions();
    initSources();

    getGraphItemsFx({ scenarioId: calculationScenarioId });

    return () => {
      storesReset();
    };
  }, []);

  const mapPage = (
    <>
      <Stack flex={1}>
        <AutoFullHeight
          style={{
            backgroundColor: 'white',
            overflow: 'hidden',
          }}
        >
          <MapContainer />
        </AutoFullHeight>
      </Stack>

      <RecalculationContainer />

      {forecastDetails && <ForecastDetailsContainer />}
      {inspector && <InspectorContainer />}
      {newInfrastructure && <NewInfrastructureContainer />}

      <MapControlsContainer />

      {/*TODO: отрефакторить*/}
      <CalculationNotification />
      <FinallyCalculationNotification />
      <Loading />
    </>
  );

  return (
    <>
      {!showDashboard && !widgetRoutesParams && <ToolBar />}
      {showDashboard && <DashboardToolBarContainer />}

      <Box sx={ContainerMuiStyle}>
        {!showDashboard && !widgetRoutesParams && mapPage}
        {showDashboard && <DashboardsContainer />}
        {widgetRoutesParams && (
          <WidgetRoutesParams
            closeRoutesParams={() => setVisibleWidgetRoutesParams(false)}
          />
        )}

        {!widgetRoutesParams && <MapFiltersContainer />}
        {!widgetRoutesParams && dashboardPage !== 2 && <YearLineContainer />}
      </Box>
    </>
  );
};
