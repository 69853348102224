import styled from '@emotion/styled';
import { useCallback, useEffect, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button } from '@mui/material';

import {
  ArrowCircleLeftIcon,
  ArrowCircleRightIcon,
  DownloadIcon,
} from '@components/icons';

import { DisplayedPageType } from '@features/ag-forecast/stores/dashboard/types';

const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, '44px', 0, '44px'),
  gap: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '76px',
}));

export const DashboardToolBar = ({
  onBackButtonClick,
  onChangeView,
}: Props) => {
  const [view, setView] = useState<DisplayedPageType>(1);

  const onViewButtonClick = useCallback(
    (view: DisplayedPageType) => () => setView(view),
    [],
  );

  const renderViewButton = useCallback(
    (activeView: DisplayedPageType) => {
      return (
        <Box
          sx={{
            width: '18px',
            height: '18px',
            cursor: 'pointer',
            borderRadius: '50%',
            border: theme => `1px solid ${theme.palette.secondary.main}`,
            backgroundColor:
              view === activeView
                ? theme => theme.palette.secondary.main
                : 'transparent',
          }}
          onClick={onViewButtonClick(activeView)}
        />
      );
    },
    [onViewButtonClick, view],
  );

  const handlerPrevPage = () => setView(state => --state as DisplayedPageType);

  const handlerNextPage = () => setView(state => ++state as DisplayedPageType);

  useEffect(() => {
    onChangeView(view);
  }, [onChangeView, view]);

  return (
    <Wrapper>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Button
          variant={'text'}
          onClick={onBackButtonClick}
          sx={{
            color: theme => theme.palette.customs.link,
            [`& .MuiButton-startIcon > *:nth-of-type(1)`]: {
              fontSize: '12px',
            },
            mr: '33px',
          }}
          startIcon={<ArrowBackIcon />}
        >
          Назад
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <a href={location.origin + '/src/assets/Общие показатели.xlsx'}>
          <Button
            variant="text"
            sx={{
              color: theme => theme.palette.customs.link,
              columnGap: 1,
              mr: 4,
            }}
          >
            Скачать
            <DownloadIcon />
          </Button>
        </a>

        {view !== 1 && (
          <Button
            variant="outlined"
            sx={{
              mr: 2,
              columnGap: 1,
            }}
            onClick={handlerPrevPage}
          >
            <ArrowCircleLeftIcon
              sx={{
                color: theme => theme.palette.secondary.main,
                fill: theme => theme.palette.common.white,
              }}
            />
            Предыдущий
          </Button>
        )}

        {renderViewButton(1)}
        {renderViewButton(2)}
        {renderViewButton(3)}

        {view !== 3 && (
          <Button
            variant="outlined"
            sx={{
              ml: 2,
              columnGap: 1,
            }}
            onClick={handlerNextPage}
          >
            <ArrowCircleRightIcon
              sx={{
                color: theme => theme.palette.secondary.main,
                fill: theme => theme.palette.common.white,
              }}
            />
            Следующий
          </Button>
        )}
      </Box>
    </Wrapper>
  );
};

export interface Props {
  pointARegion: string | undefined;
  pointBRegion: string | undefined;
  onChangeView: (view: DisplayedPageType) => void;
  onBackButtonClick: () => void;
}
