import { useUnit } from 'effector-react';

import { FirstPageDashboardContainer } from './containers/FirstPageContainer';
import { SecondPageDashboardContainer } from './containers/SecondPageContainer';

import { DisplayedPageType } from '@features/ag-forecast/stores/dashboard/types';
import { $AgGeometry } from '@features/ag-forecast/stores/map';
import { $FeatureSettings } from '@features/ag-forecast/stores/settings/store';
import { $YearLineStore } from '@features/ag-forecast/stores/yearLine/store';

import { WidgetSocecoIndicators } from '@widgets/widget-soceco-indicators/container';

type DashboardsContainersProps = {
  page: DisplayedPageType;
  // onHideDashboardView: () => void;
};

export const DashboardsContainers = ({
  page, // onHideDashboardView,
}: DashboardsContainersProps) => {
  const { scenarioUUID, projectUUID } = useUnit($FeatureSettings);
  const { center } = useUnit($AgGeometry);
  const { minYear } = useUnit($YearLineStore);
  return (
    <>
      {page === 1 && <FirstPageDashboardContainer />}
      {page === 2 && (
        <SecondPageDashboardContainer
        // onHideDashboardView={onHideDashboardView}
        />
      )}
      {page === 3 && (
        <WidgetSocecoIndicators
          scenarioUUID={scenarioUUID}
          projectUUID={projectUUID}
          centerCoords={center}
          minYear={minYear}
        />
      )}
    </>
  );
};
