import { apiClient } from '@api/client';
import { IQuery, IQueryGetComputedGraphByIdArgs } from '@api/gql/ag-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

import { EMapViewMode } from '@features/ag-forecast/constants/EMapViewMode';
import {
  $FilterMap,
  FilterMapApi,
} from '@features/ag-forecast/stores/filters/store';
import {
  PassFlowInspectorApi,
  PassFlowInspectorStore,
  getPassFlowGraphsForInspectorFx,
} from '@features/ag-forecast/stores/inspector/passFlow/store';
import { $Vehicles } from '@features/ag-forecast/stores/lists/vehicles';
import { $SelectedFeatures } from '@features/ag-forecast/stores/map';
import { $FeatureSettings } from '@features/ag-forecast/stores/settings';
import { $UI } from '@features/ag-forecast/stores/ui';
import { $YearLineStore } from '@features/ag-forecast/stores/yearLine/store';
import { getVehicleByFilter } from '@features/ag-forecast/utils/getVehicleByFilter';

// Запросить данные по выделенной фиче на слое пассажиропотока
sample({
  clock: [$SelectedFeatures, FilterMapApi.setTimeSlot],
  source: {
    UI: $UI,
    SelectedFeatures: $SelectedFeatures,
    FeatureSettings: $FeatureSettings,
    YearLineStore: $YearLineStore,
    FilterMap: $FilterMap,
    Vehicles: $Vehicles,
  },
  filter: ({ UI, SelectedFeatures }) =>
    !!SelectedFeatures.length && UI.viewMode === EMapViewMode.passFlow,
  fn: ({
    SelectedFeatures,
    FeatureSettings,
    YearLineStore,
    FilterMap,
    Vehicles,
  }) => {
    const vehicle = getVehicleByFilter(FilterMap, Vehicles);
    console.log(1111);
    return {
      graphId: SelectedFeatures[0]!.id,
      scenarioId: FeatureSettings.scenarioUUID,
      year: YearLineStore.selectedYear,
      timeSlotId: FilterMap.selectedTimeSlot,
      isPublic: vehicle.isPublic,
    } as IQueryGetComputedGraphByIdArgs;
  },
  target: [getPassFlowGraphsForInspectorFx, PassFlowInspectorApi.reset],
});

sample({
  clock: getPassFlowGraphsForInspectorFx.done,
  fn: request => {
    return {
      transport: request.result?.edge?.transport || [],
      laneNum: request.result?.edge?.laneNum || 1,
      freeSpeed: request.result?.edge?.freeSpeed || 0,
      capacity: request.result?.edge?.capacity || 0,
      oneway: request.result?.edge?.oneway || false,
      flow: request.result?.flow || 0,
      category: request.result?.edge?.category || '',
    } as PassFlowInspectorStore['graphs'];
  },
  target: PassFlowInspectorApi.setGraphsForInspector,
});

getPassFlowGraphsForInspectorFx.use(async params => {
  const response = await apiClient.query<
    IQuery,
    IQueryGetComputedGraphByIdArgs
  >({
    query: gql`
      query QueryGetComputedGraphById(
        $scenarioId: UUID!
        $year: Int!
        $graphId: UUID!
        $timeSlotId: UUID!
        $isPublic: Boolean!
      ) {
        getComputedGraphById(
          scenarioId: $scenarioId
          year: $year
          graphId: $graphId
          timeSlotId: $timeSlotId
          isPublic: $isPublic
        ) {
          flow
          edge {
            category
            laneNum
            oneway
            freeSpeed
            capacity
            transport
          }
        }
      }
    `,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    variables: {
      graphId: params.graphId,
      scenarioId: params.scenarioId,
      year: params.year,
      timeSlotId: params.timeSlotId,
      isPublic: params.isPublic,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });
  return response.data.getComputedGraphById;
});
