export type DisplayedPageType = 1 | 2 | 3;

export type PassangerTrafficVehilceType = {
  railway: number;
  public: number;
  private: number;
};

export enum EVehilceName {
  railway = 'Железнодорожный транспорт',
  public = 'Общественный траспорт (искл. ЖД)',
  private = 'Личный траспорт',
}

export enum EVehicleColor {
  railway = '#5A5A5A',
  public = '#DB3F3F',
  private = '#83CA6B',
}
