import { apiClient } from '@api/client';
import { IMutation, IMutationUpdateGraphItemArgs } from '@api/gql/types';
import { gql } from '@apollo/client';

export const mutationUpdateGraph = async (
  graph: IMutationUpdateGraphItemArgs,
) => {
  const result = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation UpdateGraphItemMutation(
        $name: String
        $year: Int
        $graphItemId: UUID!
      ) {
        updateGraphItem(name: $name, year: $year, graphItemId: $graphItemId) {
          instance {
            id
          }
        }
      }
    `,
    variables: graph,
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result.data?.updateGraphItem;
};
