import { useUnit } from 'effector-react';
import { useCallback, useEffect } from 'react';

import { CloseOutlined, InfoOutlined } from '@mui/icons-material';
import { Button, IconButton, Paper, Stack, Typography } from '@mui/material';

import { ClearIcon } from '@components/icons';
import { AddRailwayGraph } from '@components/icons/AddRailwayGraph';
import { AddRailwayGraphAccept } from '@components/icons/AddRailwayGraphAccept';
import { AddRailwayStation } from '@components/icons/AddRailwayStation';
// import { SaveIcon } from '@components/icons/Save';
import { Settings2 } from '@components/icons/Settings2';

import {
  $EditorMap,
  EditorMapApi, // EditorMapEvents,
} from '@features/pt-forecast-new/stores/map/editor';
import { $UI, UIApi } from '@features/pt-forecast-new/stores/ui';

export const MapEditToolBar = () => {
  const {
    panels: { inspector, filter, newInfrastructure },
    placeRailwayStation,
    drawGraph,
  } = useUnit($UI);
  const {
    setPlaceRailwayStations,
    setDrawGraph,
    setVisibleWidgetRoutesParams,
  } = useUnit(UIApi);
  const EditorMap = useUnit($EditorMap);
  const { addDrawGraphEndStation } = useUnit(EditorMapApi);

  const onClickTogglePlaceRailwayStation = useCallback(() => {
    setPlaceRailwayStations(!placeRailwayStation);
  }, [placeRailwayStation, setPlaceRailwayStations]);

  const onClickDrawGraph = useCallback(() => {
    if (drawGraph) {
      !!EditorMap.drawGraph &&
        EditorMap.drawGraph.nodes.length > 1 &&
        EditorMap.lastStep!.isStation &&
        addDrawGraphEndStation(EditorMap.lastStep!.stationId);
      setDrawGraph(false);
    } else {
      setDrawGraph(true);
    }
  }, [
    EditorMap.drawGraph,
    EditorMap.lastStep,
    addDrawGraphEndStation,
    drawGraph,
    setDrawGraph,
  ]);

  const handleOpenRoutesParams = useCallback(
    () => setVisibleWidgetRoutesParams(true),
    [setVisibleWidgetRoutesParams],
  );

  useEffect(() => {
    // EditorMapEvents.fetchInfrastructure();
  }, []);

  return (
    <Paper
      sx={{
        backgroundColor: theme => theme.palette.common.white,
        boxShadow: '0px 0px 8px 0px #00000026',
        borderRadius: '6px',
        width: `calc(100vw - ${inspector ? 500 : 1}px - ${
          filter || newInfrastructure ? 500 : 1
        }px - 50px)`,
        p: 2.5,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        style={{}}
      >
        <Stack
          direction="row"
          gap={3.5}
        >
          <Button
            sx={{
              width: '240px',
            }}
            color={!placeRailwayStation ? 'primary' : 'secondary'}
            onClick={onClickTogglePlaceRailwayStation}
          >
            {!placeRailwayStation ? (
              <>
                <AddRailwayStation
                  style={{
                    marginRight: '8px',
                  }}
                />
                Добавить станцию
              </>
            ) : (
              <>
                <ClearIcon
                  style={{
                    marginRight: '8px',
                  }}
                />
                Отменить добавление
              </>
            )}
          </Button>
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
          >
            <Button
              sx={() => ({
                width: '240px',
              })}
              color={!drawGraph ? 'primary' : 'secondary'}
              onClick={onClickDrawGraph}
            >
              {!drawGraph ? (
                <>
                  <AddRailwayGraph
                    style={{
                      marginRight: '8px',
                    }}
                  />
                  Добавить линию
                </>
              ) : (
                <>
                  <AddRailwayGraphAccept
                    style={{
                      marginRight: '8px',
                    }}
                  />
                  Завершить построение
                </>
              )}
            </Button>
            <IconButton style={{ width: '24px', height: '24px' }}>
              <InfoOutlined style={{ opacity: 0.5 }} />
            </IconButton>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          gap={3.5}
        >
          <Button
            color="secondary"
            sx={{}}
            onClick={handleOpenRoutesParams}
          >
            <Settings2
              style={{ width: '16px', height: '16px', marginRight: '8px' }}
            />
            <Typography>Параметры сообщения</Typography>
          </Button>
          {/* <Button
            style={{ minWidth: '44px', padding: '4px' }}
            onClick={() => {}}
          >
            <SaveIcon
              style={{ opacity: 0.85, width: '16px', height: '16px' }}
            />
          </Button> */}
          <Button
            variant="outlined"
            style={{ minWidth: '44px', padding: '4px' }}
            onClick={() => {}}
          >
            <CloseOutlined />
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};
