import { useUnit } from 'effector-react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Divider, Stack, Typography } from '@mui/material';

import { FilterIcon } from '@components/icons';

import { ROUTES } from '@constants/routes';

import { $FeatureSettings } from '@features/pt-forecast-new/stores/settings';
import { $UI, UIApi } from '@features/pt-forecast-new/stores/ui';

export const ToolBar = () => {
  const navigate = useNavigate();

  const { editMode } = useUnit($UI);
  const { toggleFilterPanel } = useUnit(UIApi);
  const { scenarioDetails } = useUnit($FeatureSettings);

  const handleClickBuilder = useCallback(() => {
    navigate(ROUTES.PASSENGER_TRAFFIC_CONSTRUCTOR);
  }, [navigate]);

  return (
    <Stack
      py={2}
      bgcolor={'common.white'}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      px={5.5}
      width={'100%'}
    >
      <Stack
        width={'calc(500px - 44px)'}
        pr={5.5}
      >
        <Button
          fullWidth
          color={'primary'}
          variant={'contained'}
          onClick={toggleFilterPanel}
        >
          <FilterIcon
            sx={{
              mr: theme => theme.typography.pxToRem(8),
            }}
          />
          Фильтры
        </Button>
      </Stack>

      <Stack
        flexDirection={'row'}
        width={'calc(100% - 1000px + 88px)'}
        alignItems={'center'}
      >
        <Divider
          sx={{ borderColor: '#EBECEF', mr: 5.5 }}
          orientation={'vertical'}
          flexItem
        />

        <Typography
          variant="subtitle1"
          sx={{
            my: 'auto',
            lineHeight: 1.25,
            minWidth: 'calc(50% - 215px - 88px)',
          }}
        >
          {scenarioDetails?.name || 'Новый расчет'}
        </Typography>

        {/*<SearchInput />*/}

        <Divider
          sx={{ borderColor: '#EBECEF', ml: 'auto' }}
          orientation={'vertical'}
          flexItem
        />
      </Stack>

      <Stack
        width={'calc(500px - 44px)'}
        pl={5.5}
        alignItems={'center'}
      >
        <Button
          variant={'contained'}
          onClick={handleClickBuilder}
          fullWidth
          disabled={editMode}
        >
          В реестр расчетов
        </Button>
      </Stack>
    </Stack>
  );
};
