import { createApi, createStore } from 'effector';

import { LayersVisibleStore } from '@features/pt-forecast-new/stores/map/layersVisible';

export const $SelectedFeatures = createStore<SelectedFeaturesStore[]>([]);

export const SelectedFeaturesApi = createApi($SelectedFeatures, {
  setSelectedFeatures: (_, payload: SelectedFeaturesStore[]) => payload,
  reset: () => [],
});

export type SelectedFeaturesStore = {
  layout: keyof LayersVisibleStore;
  id: string;
};
