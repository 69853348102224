import { IProjectType, IQueryGetProjectByIdArgs } from '@api/gql/ag-types';
import { createApi, createEffect, createEvent, createStore } from 'effector';
import { Maybe } from 'graphql/jsutils/Maybe';
import { MapBrowserEvent } from 'ol';

import { EMapFeatureLayout } from '@constants/map';

import { initLayersVisible } from '@features/ag-forecast/constants/inits';
import { PipeMapFollowPath } from '@features/ag-forecast/utils/useMapFollowPath';

import { PipeMapControls } from '@utils/map/hooks/useInitMapControls';
import { PipeTileLayer } from '@utils/map/hooks/useInitTileLayer';
import { PipeVectorLayer } from '@utils/map/hooks/useInitVectorLayer';

export const pipeMapControls = createEvent<PipeMapControls>();
export const pipeMapFollowPath = createEvent<PipeMapFollowPath>();
export const pipeTpuLayer = createEvent<PipeVectorLayer>();
export const pipeTransportFlowVectorLayer = createEvent<PipeVectorLayer>();
export const pipeTpuGraphsLayer = createEvent<PipeVectorLayer>();
export const pipeRailwayStationsLayer = createEvent<PipeVectorLayer>();
export const pipeAutoTileLayer = createEvent<PipeTileLayer | null>();
export const pipeBusTileLayer = createEvent<PipeTileLayer | null>();
export const pipeTrolleybusTileLayer = createEvent<PipeTileLayer | null>();
export const pipeTramTileLayer = createEvent<PipeTileLayer | null>();
export const pipeMetroTileLayer = createEvent<PipeTileLayer | null>();
export const pipeSuburbanRailwayTileLayer = createEvent<PipeTileLayer | null>();
export const pipeRopeWayTileLayer = createEvent<PipeTileLayer | null>();
export const pipeWaterWayTileLayer = createEvent<PipeTileLayer | null>();
export const pipeFunicularTileLayer = createEvent<PipeTileLayer | null>();
export const pipeMonoRailWayTileLayer = createEvent<PipeTileLayer | null>();
export const pipePedestrainTileLayer = createEvent<PipeTileLayer | null>();
export const pipeAviaTileLayer = createEvent<PipeTileLayer | null>();
export const pipeTransportDistrictsTileLayer =
  createEvent<PipeTileLayer | null>();
export const pipeAgSearchInfraVectorLayer = createEvent<PipeVectorLayer>();

export const initSources = createEvent();

//////////////////////////////////////////////////////////////////////////////////////////////

export const $LayersVisible = createStore<AgLayersStore>(initLayersVisible);

export const LayersVisibleApi = createApi($LayersVisible, {
  setVisible: (state, payload: SetVisiblePayload) => {
    if (!payload) return state;
    return {
      ...state,
      ...payload,
    };
  },
});

//////////////////////////////////////////////////////////////////////////////////////////////

export const $SelectedFeatures = createStore<SelectedFeaturesState[]>([]);

export const SelectedFeaturesApi = createApi($SelectedFeatures, {
  setSelectedFeatures: (_, payload: SetSelectedFeaturesPayload | null) =>
    payload ? ([payload] as unknown as SelectedFeaturesState[]) : [],
});

export const clickMap = createEvent<MapBrowserEvent<UIEvent>>();

//////////////////////////////////////////////////////////////////////////////////////////////

export const $CreatedFeatures = createStore<CreatedFeaturesState>({
  graphNodes: [],
  tpuPoints: [],
});

export const CreatedFeaturesApi = createApi($CreatedFeatures, {
  addTpu: (state, payload: TpuPointsType) => ({
    ...state,
    tpuPoints: [...state.tpuPoints, payload],
  }),
  addGraphNode: (state, payload: GraphNodesType | null) =>
    payload
      ? {
          ...state,
          graphNodes: [...state.graphNodes, payload],
        }
      : state,
  setTpuState: (state, payload: TpuPointsType[]) => ({
    ...state,
    tpuPoints: payload,
  }),
  setGraphNodesState: (state, payload: GraphNodesType[]) => ({
    ...state,
    graphNodes: payload,
  }),
});

//////////////////////////////////////////////////////////////////////////////////////////////

const defaultAgGeometryStore: AgGeometryStore = {
  railwayStations: null,
  center: null,
};

export const $AgGeometry = createStore<AgGeometryStore>(defaultAgGeometryStore);

export const AgGeometryApi = createApi($AgGeometry, {
  setAgGeometry: (_, payload: AgGeometryStore) => payload,
});

export const FetchAgGeometryFx = createEffect<
  IQueryGetProjectByIdArgs,
  Maybe<IProjectType>
>();

//////////////////////////////////////////////////////////////////////////////////////////////

const initState: MapStore = {
  isBusy: true,
};

export const $MapStore = createStore<MapStore>(initState);

export const MapStoreApi = createApi($MapStore, {
  setIsBusy: (store, payload: boolean) => ({
    ...store,
    isBusy: payload,
  }),
  reset: () => initState,
});

//////////////////////////////////////////////////////////////////////////////////////////////

export type AgLayersStore = {
  [EMapFeatureLayout.tpu]: boolean;
  [EMapFeatureLayout.tpuGraphs]: boolean;
  [EMapFeatureLayout.graphs]: boolean;
  [EMapFeatureLayout.transportDistricts]: boolean;
  [EMapFeatureLayout.agRailwayStations]: boolean;
  [EMapFeatureLayout.graphAuto]: boolean;
  [EMapFeatureLayout.graphBus]: boolean;
  [EMapFeatureLayout.graphTrolleybus]: boolean;
  [EMapFeatureLayout.graphTram]: boolean;
  [EMapFeatureLayout.graphMetro]: boolean;
  [EMapFeatureLayout.graphSuburbanRailway]: boolean;
  [EMapFeatureLayout.graphRopeWay]: boolean;
  [EMapFeatureLayout.graphWaterWay]: boolean;
  [EMapFeatureLayout.graphFunicular]: boolean;
  [EMapFeatureLayout.graphMonoRailWay]: boolean;
  [EMapFeatureLayout.graphPedestrain]: boolean;
  [EMapFeatureLayout.graphAvia]: boolean;
  [EMapFeatureLayout.transportFlowDistricts]: boolean;
  [EMapFeatureLayout.agSearchInfra]: boolean;
};

export type AgGeometryRailwayStation = {
  coordinates: number[];
  year: number;
  isTpu: boolean;
  id: string;
};

export type AgGeometryStore = {
  railwayStations: AgGeometryRailwayStation[] | null;
  center: number[] | null;
};

type CreatedFeaturesState = {
  tpuPoints: TpuPointsType[];
  graphNodes: GraphNodesType[];
};

export type TpuPointsType = {
  id: string;
  coordinates: number[];
  group: number | null;
  isMain: boolean;
};

export type GraphNodesType = {
  id: string;
  coordinates: number[];
  group: number;
};

type SelectedFeaturesState = {
  layout: keyof AgLayersStore;
  id: string;
};

export type SetSelectedFeaturesPayload = {
  id: string;
  layout: EMapFeatureLayout;
};

export type SetVisiblePayload = Partial<Record<keyof AgLayersStore, boolean>>;

export type MapStore = {
  isBusy: boolean;
};
