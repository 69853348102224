import { Event, createApi, createEvent, createStore } from 'effector';

import { SocEcoInfrastructureType } from '@widgets/widget-soceco-indicators/constants/map/SocEcoInfrastructureType';
import { SOCECO_TABLE_IDS } from '@widgets/widget-soceco-indicators/constants/tables/tableIds';

const initState: SocEcoInfrastructureStore = {
  types: {
    [SOCECO_TABLE_IDS.TABLE_1_1]: [],
    [SOCECO_TABLE_IDS.TABLE_2_5]: [],
    [SOCECO_TABLE_IDS.TABLE_3_1]: [],
    [SOCECO_TABLE_IDS.TABLE_3_2]: [],
    [SOCECO_TABLE_IDS.TABLE_3_4]: [],
    [SOCECO_TABLE_IDS.TABLE_4_2]: [],
    [SOCECO_TABLE_IDS.TABLE_5_2]: [],
    [SOCECO_TABLE_IDS.TABLE_5_3]: [],
  },
};

export const $SocEcoInfrastructure =
  createStore<SocEcoInfrastructureStore>(initState);

export const SocEcoInfrastructureApi = createApi($SocEcoInfrastructure, {
  setFeatures: (store, payload: TargetedPayload<FeatureItem[]>) => ({
    ...store,
    types: {
      ...store.types,
      [payload.type]: payload.data,
    },
  }),
  reset: () => initState,
});

export const SocEcoInfrastructureFetchEvents: Record<
  keyof typeof SocEcoInfrastructureType,
  Event<void>
> = {
  [SOCECO_TABLE_IDS.TABLE_1_1]: createEvent(),
  [SOCECO_TABLE_IDS.TABLE_2_5]: createEvent(),
  [SOCECO_TABLE_IDS.TABLE_3_1]: createEvent(),
  [SOCECO_TABLE_IDS.TABLE_3_2]: createEvent(),
  [SOCECO_TABLE_IDS.TABLE_3_4]: createEvent(),
  [SOCECO_TABLE_IDS.TABLE_4_2]: createEvent(),
  [SOCECO_TABLE_IDS.TABLE_5_2]: createEvent(),
  [SOCECO_TABLE_IDS.TABLE_5_3]: createEvent(),
};

export type TargetedPayload<T> = {
  type: keyof typeof SocEcoInfrastructureType;
  data: T;
};

export type FeatureItem = {
  id: number;
  name: string;
  geometry: {
    point: number[] | null;
    line: number[][] | null;
    multiline: number[][][] | null;
    polygon: number[][][] | null;
    multipoint: number[][] | null;
  };
};

export type SocEcoInfrastructureStore = {
  types: Record<keyof typeof SocEcoInfrastructureType, FeatureItem[]>;
};
