import { apiClient } from '@api/client';
import {
  IComputingRegistryType,
  IQuery,
  IQueryGetComputedPassTrafficArgs,
  IQueryGetComputingRegistryInstanceByIdArgs,
} from '@api/gql/types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import {
  $CalculationScenario,
  CalculationScenarioState,
  fetchCalculationScenarioFx,
  getComputedPassTrafficFx,
  pushPassTrafficForSelectYear,
  resetComputed,
  setComputingIsLoading,
  setComputingRegistry,
  setComputingresultsSet,
  updateLinkComputingRegistry,
} from '@features/pt-forecast/stores/computed/store';
import { FilterRegionApi } from '@features/pt-forecast/stores/filterRegion/store';
import { resetAllFilters } from '@features/pt-forecast/stores/filters/store';
import { updateComputedStatusFx } from '@features/pt-forecast/stores/recalculate/store';
import {
  $Timeline,
  TimelineApi,
} from '@features/pt-forecast/stores/timeline/store';

sample({
  clock: getComputedPassTrafficFx,
  fn: () => true,
  target: setComputingIsLoading,
});

sample({
  clock: getComputedPassTrafficFx.finally,
  fn: () => false,
  target: setComputingIsLoading,
});

// При изменении выбранного года, возвращает результаты по пас трафику указанного года
sample({
  clock: [TimelineApi.selectYear, setComputingresultsSet],
  source: { calculationScenario: $CalculationScenario, timeline: $Timeline },
  fn: ({ calculationScenario, timeline }) => {
    const selectedYear =
      (timeline.baseYear || 0) + (timeline.selectedYear || 0);

    const result = calculationScenario.computingresultsSet.find(
      resultSet => resultSet.year === selectedYear,
    );

    return result || null;
  },
  target: pushPassTrafficForSelectYear,
});

// Маппинг ответа с апи для корреспонденции
sample({
  clock: getComputedPassTrafficFx.done,
  fn: request => {
    const result: CalculationScenarioState['computingresultsSet'] =
      (request.result?.map(data => ({
        id: data?.id || '',
        year: Number(data?.year) || 0,
        passengerTraffic: {
          multimodal:
            data?.multimodal !== 'NaN' ? Number(data?.multimodal || 0) : 0,
          auto: data?.auto !== 'NaN' ? Number(data?.auto || 0) : 0,
          bus: data?.bus !== 'NaN' ? Number(data?.bus || 0) : 0,
          dayTrain: data?.dayTrain !== 'NaN' ? Number(data?.dayTrain || 0) : 0,
          nightTrain:
            data?.nightTrain !== 'NaN' ? Number(data?.nightTrain || 0) : 0,
          suburbanTrain:
            data?.suburbanTrain !== 'NaN'
              ? Number(data?.suburbanTrain || 0)
              : 0,
          avia: data?.avia !== 'NaN' ? Number(data?.avia || 0) : 0,
          expressTrain:
            data?.expressSuburbanTrain !== 'NaN'
              ? Number(data?.expressSuburbanTrain || 0)
              : 0,
          totalFlow:
            data?.totalFlow !== 'NaN' ? Number(data?.totalFlow || 0) : 0,
        },
        price: {
          auto: Number(data?.priceAuto || 0),
          bus: Number(data?.priceBus || 0),
          dayTrain: Number(data?.priceDayTrain || 0),
          nightTrain: Number(data?.priceNightTrain || 0),
          suburbanTrain: Number(data?.priceSuburbanTrain || 0),
          expressTrain: Number(data?.priceExpressSuburbanTrain || 0),
          avia: Number(data?.priceAvia || 0),
        },
        duration: {
          auto: Number(data?.durationAuto || 0),
          bus: Number(data?.durationBus || 0),
          dayTrain: Number(data?.durationDayTrain || 0),
          nightTrain: Number(data?.durationNightTrain || 0),
          suburbanTrain: Number(data?.durationSuburbanTrain || 0),
          expressTrain: Number(data?.durationExpressSuburbanTrain || 0),
          avia: Number(data?.durationAvia || 0),
        },
        frequency: {
          bus: Number(data?.frequencyBus || 0),
          dayTrain: Number(data?.frequencyDayTrain || 0),
          nightTrain: Number(data?.frequencyNightTrain || 0),
          suburbanTrain: Number(data?.frequencySuburbanTrain || 0),
          expressTrain: Number(data?.frequencyExpressSuburbanTrain || 0),
          avia: Number(data?.frequencyAvia || 0),
        },
        direction: {
          endDate: data?.direction?.endDate || '',
          startDate: data?.direction?.startDate || '',
          id: data?.direction?.id || '',
          highways:
            data?.direction?.highways?.map(highway => ({
              geometry: highway?.geometry || null,
              id: highway?.id || '',
              details:
                highway?.details?.map(detail => ({
                  status: detail?.status || '',
                  year: detail?.year || 0,
                })) || [],
            })) || [],
          railways: data?.direction?.railways?.map(railway => ({
            id: railway?.id || '',
            geometry: railway?.geometry || null,
            details:
              railway?.details?.map(detail => ({
                status: detail?.status || '',
                year: detail?.year || 0,
              })) || [],
          })),
        },
        unevenness: data?.unevenness,
        dailyUnevenness: data?.dailyUnevenness
          ? JSON.parse(data?.dailyUnevenness || '')
          : null,
      })) as CalculationScenarioState['computingresultsSet']) || [];

    return result;
  },
  target: setComputingresultsSet,
});

// Маппинг ответа с апи для параметров текущего сценария расчета
sample({
  clock: fetchCalculationScenarioFx.done,
  fn: request =>
    ({
      link: request.result.getComputingRegistryInstanceById?.link,
      name: request.result.getComputingRegistryInstanceById?.name || '',
      socEcoScenario:
        request.result.getComputingRegistryInstanceById?.socEcoScenario || null,
      transportScenario:
        request.result.getComputingRegistryInstanceById?.transportScenario ||
        null,
    }) as CalculationScenarioState['computingRegistry'],
  target: setComputingRegistry,
});

sample({
  clock: updateComputedStatusFx.done,
  fn: payload => payload.result?.link as IComputingRegistryType['link'],
  target: updateLinkComputingRegistry,
});

// Сброс данных при сбросе фильтров
sample({
  clock: [resetAllFilters, FilterRegionApi.reset],
  target: resetComputed,
});

// mockComputingResults
// Получить все данные по сценарию расчета
fetchCalculationScenarioFx.use(async ({ id }) => {
  const response = await apiClient.query<
    Pick<IQuery, 'getComputingRegistryInstanceById'>,
    IQueryGetComputingRegistryInstanceByIdArgs
  >({
    query: gql`
      query QueryGetComputingRegistryInstanceById($id: String) {
        getComputingRegistryInstanceById(id: $id) {
          id
          name
          link
          resultStatus
          socEcoScenario {
            gdpGrowthRate
            growthRateInTheNumberOfPlacements
            growthRateOfMotorization
            id
            name
            populationGrowthRate
            scenarioType
            year
            jobGrowthRate
          }
          transportScenario {
            id
            name
            scenarioType
            transportProjects {
              id
              year
              transportProject {
                name
              }
            }
          }
        }
      }
    `,
    variables: { id },
  });

  return response.data;
});

getComputedPassTrafficFx.use(async params => {
  const response = await apiClient.query<
    IQuery,
    IQueryGetComputedPassTrafficArgs
  >({
    errorPolicy: 'ignore',
    query: gql`
      query getComputingPassTraffic(
        $computingRegistryId: String
        $regionFrom: String
        $regionTo: String
      ) {
        getComputedPassTraffic(
          computingRegistryId: $computingRegistryId
          regionFrom: $regionFrom
          regionTo: $regionTo
        ) {
          totalFlow
          year
          auto
          avia
          bus
          dayTrain
          suburbanTrain
          multimodal
          id
          nightTrain
          priceAuto
          priceSuburbanTrain
          pricePlain
          priceNightTrain
          priceExpressSuburbanTrain
          priceDayTrain
          priceBus
          priceAvia
          frequencySuburbanTrain
          frequencyPlain
          frequencyNightTrain
          frequencyExpressSuburbanTrain
          frequencyDayTrain
          frequencyBus
          frequencyAvia
          durationBus
          durationDayTrain
          durationExpressSuburbanTrain
          durationNightTrain
          durationAuto
          durationPlain
          durationSuburbanTrain
          durationAvia
          expressSuburbanTrain
          unevenness
          dailyUnevenness
          passengerTrafficByIncomeGroups {
            items {
              value
              category
            }
          }
          passengerTrafficBySex {
            female
            male
          }
          passengerTrafficByAge {
            workingAge
            underWorkingAge
            overWorkingAge
          }
        }
      }
    `,
    variables: {
      regionTo: params.regionTo,
      regionFrom: params.regionFrom,
      computingRegistryId: params.computingRegistryId,
    },
  });
  return response.data.getComputedPassTraffic;
});
