import { createEvent } from 'effector';
import { MapBrowserEvent } from 'ol';

import { PipeMapControls } from '@utils/map/hooks/useInitMapControls';
import { PipeTileLayer } from '@utils/map/hooks/useInitTileLayer';
import { PipeVectorLayer } from '@utils/map/hooks/useInitVectorLayer';

export const pipeMapControls = createEvent<PipeMapControls>();
export const pipeAnimalPassageVectorLayer = createEvent<PipeVectorLayer>();
export const pipeAnimalProtectionVibrationVectorLayer =
  createEvent<PipeVectorLayer>();
export const pipeChangingRoutesVectorLayer = createEvent<PipeVectorLayer>();
export const pipeCulvertsVectorLayer = createEvent<PipeVectorLayer>();
export const pipeProtectionVibrationVectorLayer =
  createEvent<PipeVectorLayer>();
export const pipeSafeCrossingVectorLayer = createEvent<PipeVectorLayer>();
export const pipeStationAccessibilityVectorLayer =
  createEvent<PipeVectorLayer>();
export const pipeWalkingAreaVectorLayer = createEvent<PipeVectorLayer>();
export const pipeAllGraphsAgglomerationTileLayer = createEvent<PipeTileLayer>();
export const clickMap = createEvent<MapBrowserEvent<UIEvent>>();

export const initTileSources = createEvent<string>();
