import { SOCECO_TABLE_IDS } from '@widgets/widget-soceco-indicators/constants/tables/tableIds';

export const SOCECO_TABLE_BODYS_MOCK = {
  [SOCECO_TABLE_IDS.TABLE_1_1]: [
    // {
    //   tpu: 'Красноярск-Пассажирский',
    //   year: 2023,
    //   passTraffic: '3629,3',
    //   zoneRadius: '640',
    // },
    // {
    //   tpu: 'Красноярск-Пассажирский',
    //   year: 2024,
    //   passTraffic: '3755,0',
    //   zoneRadius: '640',
    // },
    // {
    //   tpu: 'Красноярск-Пассажирский',
    //   year: 2025,
    //   passTraffic: '3785,3',
    //   zoneRadius: '640',
    // },
    // {
    //   tpu: 'Красноярск-Пассажирский',
    //   year: 2026,
    //   passTraffic: '3830,7',
    //   zoneRadius: '640',
    // },
    // {
    //   tpu: 'Красноярск-Пассажирский',
    //   year: 2027,
    //   passTraffic: '4290,4',
    //   zoneRadius: '640',
    // },
    // {
    //   tpu: 'Аэропорт Красноярск',
    //   year: 2023,
    //   passTraffic: '0',
    //   zoneRadius: '640',
    // },
    // {
    //   tpu: 'Аэропорт Красноярск',
    //   year: 2024,
    //   passTraffic: '0',
    //   zoneRadius: '640',
    // },
    // {
    //   tpu: 'Аэропорт Красноярск',
    //   year: 2025,
    //   passTraffic: '0',
    //   zoneRadius: '640',
    // },
    // {
    //   tpu: 'Аэропорт Красноярск',
    //   year: 2026,
    //   passTraffic: '0',
    //   zoneRadius: '640',
    // },
    // {
    //   tpu: 'Аэропорт Красноярск',
    //   year: 2027,
    //   passTraffic: '516,3',
    //   zoneRadius: '640',
    // },
    // {
    //   tpu: 'Злобино',
    //   year: 2023,
    //   passTraffic: '729,5',
    //   zoneRadius: 640,
    // },
    // {
    //   tpu: 'Злобино',
    //   year: 2024,
    //   passTraffic: '754,8',
    //   zoneRadius: 640,
    // },
    // {
    //   tpu: 'Злобино',
    //   year: 2025,
    //   passTraffic: '772,1',
    //   zoneRadius: 640,
    // },
    // {
    //   tpu: 'Енисей',
    //   year: 2023,
    //   passTraffic: '382,5',
    //   zoneRadius: 640,
    // },
    // {
    //   tpu: 'Енисей',
    //   year: 2024,
    //   passTraffic: '395,7',
    //   zoneRadius: 640,
    // },
    // {
    //   tpu: 'Енисей',
    //   year: 2025,
    //   passTraffic: '401,1',
    //   zoneRadius: 640,
    // },
    // {
    //   tpu: 'Базаиха',
    //   year: 2023,
    //   passTraffic: '178,3',
    //   zoneRadius: 640,
    // },
    // {
    //   tpu: 'Базаиха',
    //   year: 2024,
    //   passTraffic: '184,5',
    //   zoneRadius: 640,
    // },
    // {
    //   tpu: 'Базаиха',
    //   year: 2025,
    //   passTraffic: '186,0',
    //   zoneRadius: 640,
    // },
  ],
  [SOCECO_TABLE_IDS.TABLE_1_2]: [
    {
      tpu: 'Красноярск-Пассажирский',
      year: 2023,
      projectedPassTraffic: '3629,3',
      availabilityAccommodationFacilities: 15000,
      shortageAccommodationFacilities: 0,
    },
    {
      tpu: 'Красноярск-Пассажирский',
      year: 2024,
      projectedPassTraffic: '3755,0',
      availabilityAccommodationFacilities: 15000,
      shortageAccommodationFacilities: 520,
    },
    {
      tpu: 'Красноярск-Пассажирский',
      year: 2025,
      projectedPassTraffic: '3785,3',
      availabilityAccommodationFacilities: 15000,
      shortageAccommodationFacilities: 645,
    },
    {
      tpu: 'Красноярск-Пассажирский',
      year: 2026,
      projectedPassTraffic: '3830,7',
      availabilityAccommodationFacilities: 15000,
      shortageAccommodationFacilities: 833,
    },
    {
      tpu: 'Красноярск-Пассажирский',
      year: 2027,
      projectedPassTraffic: '4290,4',
      availabilityAccommodationFacilities: 15000,
      shortageAccommodationFacilities: 2733,
    },
  ],
  [SOCECO_TABLE_IDS.TABLE_1_3]: [
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2023,
    //   projectedPassTraffic: '3629,3',
    //   economicallyActivePopulation: '14,9',
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2024,
    //   projectedPassTraffic: '3755,0',
    //   economicallyActivePopulation: '15,0',
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2025,
    //   projectedPassTraffic: '3785,3',
    //   economicallyActivePopulation: '15,2',
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2026,
    //   projectedPassTraffic: '3830,7',
    //   economicallyActivePopulation: '15,1',
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2027,
    //   projectedPassTraffic: '4290,4',
    //   economicallyActivePopulation: '15,1',
    // },
  ],
  [SOCECO_TABLE_IDS.TABLE_1_4]: [
    // {
    //   nameTransportObject: 'Красноярск-Пассажирский',
    //   year: 2023,
    //   averageDailyPassengerTraffic: '9943,3',
    //   tradeArea: '994,3',
    //   annualRevenue: '248582,2',
    // },
    // {
    //   nameTransportObject: 'Красноярск-Пассажирский',
    //   year: 2024,
    //   averageDailyPassengerTraffic: '10287,7',
    //   tradeArea: '1028,8',
    //   annualRevenue: '257191,8',
    // },
    // {
    //   nameTransportObject: 'Красноярск-Пассажирский',
    //   year: 2025,
    //   averageDailyPassengerTraffic: '10370,7',
    //   tradeArea: '1037,1',
    //   annualRevenue: '259267,1',
    // },
    // {
    //   nameTransportObject: 'Красноярск-Пассажирский',
    //   year: 2026,
    //   averageDailyPassengerTraffic: '10495,1',
    //   tradeArea: '1049,5',
    //   annualRevenue: '262378,3',
    // },
    // {
    //   nameTransportObject: 'Красноярск-Пассажирский',
    //   year: 2027,
    //   averageDailyPassengerTraffic: '11754,5',
    //   tradeArea: '1175,5',
    //   annualRevenue: '293863,7',
    // },
    // {
    //   nameTransportObject: 'Аэропорт Красноярск',
    //   year: 2023,
    //   averageDailyPassengerTraffic: '0,0',
    //   tradeArea: '0,0',
    //   annualRevenue: '0,0',
    // },
    // {
    //   nameTransportObject: 'Аэропорт Красноярск',
    //   year: 2024,
    //   averageDailyPassengerTraffic: '0,0',
    //   tradeArea: '0,0',
    //   annualRevenue: '0,0',
    // },
    // {
    //   nameTransportObject: 'Аэропорт Красноярск',
    //   year: 2025,
    //   averageDailyPassengerTraffic: '0,0',
    //   tradeArea: '0,0',
    //   annualRevenue: '0,0',
    // },
    // {
    //   nameTransportObject: 'Аэропорт Красноярск',
    //   year: 2026,
    //   averageDailyPassengerTraffic: '0,0',
    //   tradeArea: '0,0',
    //   annualRevenue: '0,0',
    // },
    // {
    //   nameTransportObject: 'Аэропорт Красноярск',
    //   year: 2027,
    //   averageDailyPassengerTraffic: '1414,5',
    //   tradeArea: '141,5',
    //   annualRevenue: '35363,0',
    // },
  ],
  [SOCECO_TABLE_IDS.TABLE_1_5]: [
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2023,
    //   numberJobs: 24937,
    //   totalTransportCosts: 115,
    //   changeNumberAvailableJobs: 0,
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2024,
    //   numberJobs: 25379,
    //   totalTransportCosts: 112,
    //   changeNumberAvailableJobs: 654,
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2025,
    //   numberJobs: 25821,
    //   totalTransportCosts: 108,
    //   changeNumberAvailableJobs: 930,
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2026,
    //   numberJobs: 26263,
    //   totalTransportCosts: 106,
    //   changeNumberAvailableJobs: 509,
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2027,
    //   numberJobs: 26707,
    //   totalTransportCosts: 102,
    //   changeNumberAvailableJobs: 1085,
    // },
    // {
    //   transportArea: 'Мебельная фабрика',
    //   year: 2023,
    //   numberJobs: 6257,
    //   totalTransportCosts: 108,
    //   changeNumberAvailableJobs: 0,
    // },
    // {
    //   transportArea: 'Мебельная фабрика',
    //   year: 2024,
    //   numberJobs: 6293,
    //   totalTransportCosts: 105,
    //   changeNumberAvailableJobs: 162,
    // },
    // {
    //   transportArea: 'Мебельная фабрика',
    //   year: 2025,
    //   numberJobs: 6329,
    //   totalTransportCosts: 101,
    //   changeNumberAvailableJobs: 228,
    // },
    // {
    //   transportArea: 'Мебельная фабрика',
    //   year: 2026,
    //   numberJobs: 6365,
    //   totalTransportCosts: 99,
    //   changeNumberAvailableJobs: 123,
    // },
    // {
    //   transportArea: 'Мебельная фабрика',
    //   year: 2027,
    //   numberJobs: 6403,
    //   totalTransportCosts: 95,
    //   changeNumberAvailableJobs: 260,
    // },
  ],

  [SOCECO_TABLE_IDS.TABLE_2_1]: [
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2023,
    //   populationTransportArea: '24937',
    //   shoppingCenterArea: 7630,
    //   ShortageSCSupermarketsTransportArea: 22793,
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2024,
    //   populationTransportArea: '25379',
    //   shoppingCenterArea: 7765,
    //   ShortageSCSupermarketsTransportArea: 23197,
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2025,
    //   populationTransportArea: '25821',
    //   shoppingCenterArea: 7790,
    //   ShortageSCSupermarketsTransportArea: 23602,
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2026,
    //   populationTransportArea: '26263',
    //   shoppingCenterArea: 8035,
    //   ShortageSCSupermarketsTransportArea: 24006,
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2027,
    //   populationTransportArea: '26707',
    //   shoppingCenterArea: 8172,
    //   ShortageSCSupermarketsTransportArea: 24411,
    // },
    // {
    //   transportArea: 'Мебельная фабрика',
    //   year: 2023,
    //   populationTransportArea: '6257',
    //   shoppingCenterArea: 2320,
    //   ShortageSCSupermarketsTransportArea: 5314,
    // },
    // {
    //   transportArea: 'Мебельная фабрика',
    //   year: 2024,
    //   populationTransportArea: '6293',
    //   shoppingCenterArea: 2343,
    //   ShortageSCSupermarketsTransportArea: 5334,
    // },
    // {
    //   transportArea: 'Мебельная фабрика',
    //   year: 2025,
    //   populationTransportArea: '6329',
    //   shoppingCenterArea: 2366,
    //   ShortageSCSupermarketsTransportArea: 5355,
    // },
    // {
    //   transportArea: 'Мебельная фабрика',
    //   year: 2026,
    //   populationTransportArea: '6365',
    //   shoppingCenterArea: 2389,
    //   ShortageSCSupermarketsTransportArea: 5376,
    // },
    // {
    //   transportArea: 'Мебельная фабрика',
    //   year: 2027,
    //   populationTransportArea: '6403',
    //   shoppingCenterArea: 2415,
    //   ShortageSCSupermarketsTransportArea: 5397,
    // },
  ],
  [SOCECO_TABLE_IDS.TABLE_2_2]: [
    {
      nameArea: 'Красноярск-ТЭЦ – Агентство Аэрофлота',
      numberPassengersCarried: '10,0',
      carryingCapacity: '12,0',
      networkAreaCongestion: '83,3%',
    },
    {
      nameArea: 'Красноярск-ТЭЦ – Агентство Аэрофлота',
      numberPassengersCarried: '8,0',
      carryingCapacity: '10,0',
      networkAreaCongestion: '80,0%',
    },
  ],
  [SOCECO_TABLE_IDS.TABLE_2_3]: [
    // {
    //   nameTPU: 'Красноярск-Пассажирский',
    //   TPUStatus: 'Существующий',
    //   year: 2023,
    //   passengerTraffic: '3629,3',
    // },
    // {
    //   nameTPU: 'Красноярск-Пассажирский',
    //   TPUStatus: 'Существующий',
    //   year: 2024,
    //   passengerTraffic: '3755,0',
    // },
    // {
    //   nameTPU: 'Красноярск-Пассажирский',
    //   TPUStatus: 'Существующий',
    //   year: 2025,
    //   passengerTraffic: '3785,3',
    // },
    // {
    //   nameTPU: 'Красноярск-Пассажирский',
    //   TPUStatus: 'Существующий',
    //   year: 2026,
    //   passengerTraffic: '3830,7',
    // },
    // {
    //   nameTPU: 'Красноярск-Пассажирский',
    //   TPUStatus: 'Существующий',
    //   year: 2027,
    //   passengerTraffic: '4290,4',
    // },
    // {
    //   nameTPU: 'Аэропорт Красноярск',
    //   TPUStatus: 'Перспективный',
    //   year: 2023,
    //   passengerTraffic: '0,0',
    // },
    // {
    //   nameTPU: 'Красноярск-Пассажирский',
    //   TPUStatus: 'Перспективный',
    //   year: 2024,
    //   passengerTraffic: '0,0',
    // },
    // {
    //   nameTPU: 'Красноярск-Пассажирский',
    //   TPUStatus: 'Перспективный',
    //   year: 2025,
    //   passengerTraffic: '0,0',
    // },
    // {
    //   nameTPU: 'Красноярск-Пассажирский',
    //   TPUStatus: 'Перспективный',
    //   year: 2026,
    //   passengerTraffic: '0,0',
    // },
    // {
    //   nameTPU: 'Красноярск-Пассажирский',
    //   TPUStatus: 'Перспективный',
    //   year: 2027,
    //   passengerTraffic: '516,3',
    // },
  ],
  [SOCECO_TABLE_IDS.TABLE_2_4]: [
    {
      transportObject: 'Красноярск-Пассажирский',
      year: 2023,
      simultaneousMaximumPassengerTraffic: '1657,2',
      numberFloorsTPU: 3,
      numberElevatorsTPU: 4,
      numberEscalatorsTPU: 6,
    },
    {
      transportObject: 'Красноярск-Пассажирский',
      year: 2024,
      simultaneousMaximumPassengerTraffic: '1714,6',
      numberFloorsTPU: 3,
      numberElevatorsTPU: 4,
      numberEscalatorsTPU: 6,
    },
    {
      transportObject: 'Красноярск-Пассажирский',
      year: 2025,
      simultaneousMaximumPassengerTraffic: '1728,4',
      numberFloorsTPU: 3,
      numberElevatorsTPU: 4,
      numberEscalatorsTPU: 6,
    },
    {
      transportObject: 'Красноярск-Пассажирский',
      year: 2026,
      simultaneousMaximumPassengerTraffic: '1749,2',
      numberFloorsTPU: 3,
      numberElevatorsTPU: 4,
      numberEscalatorsTPU: 6,
    },
    {
      transportObject: 'Красноярск-Пассажирский',
      year: 2027,
      simultaneousMaximumPassengerTraffic: '1959,1',
      numberFloorsTPU: 3,
      numberElevatorsTPU: 4,
      numberEscalatorsTPU: 8,
    },
    {
      transportObject: 'Аэропорт Красноярск',
      year: 2023,
      simultaneousMaximumPassengerTraffic: '0,0',
      numberFloorsTPU: 0,
      numberElevatorsTPU: 0,
      numberEscalatorsTPU: 0,
    },
    {
      transportObject: 'Аэропорт Красноярск',
      year: 2024,
      simultaneousMaximumPassengerTraffic: '0,0',
      numberFloorsTPU: 0,
      numberElevatorsTPU: 0,
      numberEscalatorsTPU: 0,
    },
    {
      transportObject: 'Аэропорт Красноярск',
      year: 2025,
      simultaneousMaximumPassengerTraffic: '0,0',
      numberFloorsTPU: 0,
      numberElevatorsTPU: 0,
      numberEscalatorsTPU: 0,
    },
    {
      transportObject: 'Аэропорт Красноярск',
      year: 2026,
      simultaneousMaximumPassengerTraffic: '0,0',
      numberFloorsTPU: 0,
      numberElevatorsTPU: 0,
      numberEscalatorsTPU: 0,
    },
    {
      transportObject: 'Аэропорт Красноярск',
      year: 2027,
      simultaneousMaximumPassengerTraffic: '235,8',
      numberFloorsTPU: 2,
      numberElevatorsTPU: 1,
      numberEscalatorsTPU: 2,
    },
  ],
  [SOCECO_TABLE_IDS.TABLE_2_5]: [
    // {
    //   nameArea: 'Аэропорт Красноярск  Семидарское',
    //   noiseScreenSize: 4858,
    // },
    // {
    //   nameArea: 'Семидарское - Емельяново',
    //   noiseScreenSize: 7972,
    // },
    // {
    //   nameArea: 'Мясокомбинат-Каракуша',
    //   noiseScreenSize: 2315,
    // },
  ],

  [SOCECO_TABLE_IDS.TABLE_3_1]: [
    // {
    //   nameArea: 'Каракуша– Дрокино',
    //   sectionLength: 2197,
    //   numberPasses: 1,
    // },
    // {
    //   nameArea: 'Дрокино-Емельяново',
    //   sectionLength: 1857,
    //   numberPasses: 1,
    // },
  ],
  [SOCECO_TABLE_IDS.TABLE_3_2]: [
    // {
    //   nameArea: 'Каркуши-Дрокино',
    //   sectionLength: 2752,
    //   noiseScreenSize: 5504,
    // },
  ],
  [SOCECO_TABLE_IDS.TABLE_3_3]: [
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2023,
    //   transport: 'Железнодорожный',
    //   passTraffic: '3629,1',
    //   emissionsVolume: '1034,3',
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2023,
    //   transport: 'Автобусный',
    //   passTraffic: '4637,8',
    //   emissionsVolume: '2921,8',
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2023,
    //   transport: 'Личный автомобильный',
    //   passTraffic: '1351,2',
    //   emissionsVolume: '1999,8',
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2023,
    //   transport: 'Городской электрический',
    //   passTraffic: '423,5',
    //   emissionsVolume: '80,5',
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2024,
    //   transport: 'Железнодорожный',
    //   passTraffic: '3755,1',
    //   emissionsVolume: '1070,2',
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2024,
    //   transport: 'Автобусный',
    //   passTraffic: '4591,5',
    //   emissionsVolume: '2892,6',
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2024,
    //   transport: 'Личный автомобильный',
    //   passTraffic: '1325,3',
    //   emissionsVolume: '1961,4',
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2024,
    //   transport: 'Городской электрический',
    //   passTraffic: '428,4',
    //   emissionsVolume: '81,4',
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2025,
    //   transport: 'Железнодорожный',
    //   passTraffic: '3785,3',
    //   emissionsVolume: '719,2',
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2025,
    //   transport: 'Автобусный',
    //   passTraffic: '4523,8',
    //   emissionsVolume: '859,5',
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2025,
    //   transport: 'Личный автомобильный',
    //   passTraffic: '1325,8',
    //   emissionsVolume: '251,9',
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2025,
    //   transport: 'Городской электрический',
    //   passTraffic: '432,6',
    //   emissionsVolume: '82,2',
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2026,
    //   transport: 'Железнодорожный',
    //   passTraffic: '3830,7',
    //   emissionsVolume: '727,8',
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2026,
    //   transport: 'Автобусный',
    //   passTraffic: '4478,5',
    //   emissionsVolume: '850,9',
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2026,
    //   transport: 'Личный автомобильный',
    //   passTraffic: '1300,4',
    //   emissionsVolume: '247,1',
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2026,
    //   transport: 'Городской электрический',
    //   passTraffic: '436,8',
    //   emissionsVolume: '83,0',
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2027,
    //   transport: 'Железнодорожный',
    //   passTraffic: '4290,4',
    //   emissionsVolume: '815,2',
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2027,
    //   transport: 'Автобусный',
    //   passTraffic: '4377,9',
    //   emissionsVolume: '831,8',
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2027,
    //   transport: 'Личный автомобильный',
    //   passTraffic: '1283,6',
    //   emissionsVolume: '243,9',
    // },
    // {
    //   transportArea: 'Железнодорожный вокзал',
    //   year: 2027,
    //   transport: 'Городской электрический',
    //   passTraffic: '432,5',
    //   emissionsVolume: '82,2',
    // },
  ],
  [SOCECO_TABLE_IDS.TABLE_3_4]: [
    // {
    //   nameArea: 'Каркуши-Дрокино',
    //   lineLength: 5264,
    //   numberCulverts: 3,
    // },
    // {
    //   nameArea: 'Дрокино-Емельяново',
    //   lineLength: 5700,
    //   numberCulverts: 3,
    // },
  ],
  [SOCECO_TABLE_IDS.TABLE_3_5]: [
    // {
    //   nameArea: 'Бугач – Каракуша',
    //   lineLength: '7,8',
    //   volumesTechnogenicImpact: '7,722',
    // },
    // {
    //   nameArea: 'Каракуша – Дрокино',
    //   lineLength: '4,0',
    //   volumesTechnogenicImpact: '3,96',
    // },
    // {
    //   nameArea: 'Дрокино – Емельяново',
    //   lineLength: '6,6',
    //   volumesTechnogenicImpact: '6,534',
    // },
    // {
    //   nameArea: 'Емельяново – Семирадское',
    //   lineLength: '4,2',
    //   volumesTechnogenicImpact: '4,158',
    // },
    // {
    //   nameArea: 'Семирадское – Аэропорт Красноярск',
    //   lineLength: '7,7',
    //   volumesTechnogenicImpact: '7,623',
    // },
  ],
  [SOCECO_TABLE_IDS.TABLE_3_6]: [
    // {
    //   transportArea: 'Бугач – Каракуша',
    //   areaTerritoriesSubjectBuild: 585000,
    //   areaTerritoriesSubjectTransportLine: 351000,
    //   areaTPU: 1000,
    //   areaTerritoriesSubjectReclamation: 233000,
    // },
    // {
    //   transportArea: 'Каракуша – Дрокино',
    //   areaTerritoriesSubjectBuild: 300000,
    //   areaTerritoriesSubjectTransportLine: 180000,
    //   areaTPU: 0,
    //   areaTerritoriesSubjectReclamation: 120000,
    // },
    // {
    //   transportArea: 'Дрокино – Емельяново',
    //   areaTerritoriesSubjectBuild: 495000,
    //   areaTerritoriesSubjectTransportLine: 297000,
    //   areaTPU: 0,
    //   areaTerritoriesSubjectReclamation: 198000,
    // },
    // {
    //   transportArea: 'Емельяново – Семирадское',
    //   areaTerritoriesSubjectBuild: 315000,
    //   areaTerritoriesSubjectTransportLine: 189000,
    //   areaTPU: 0,
    //   areaTerritoriesSubjectReclamation: 126000,
    // },
    // {
    //   transportArea: 'Семирадское – Аэропорт Красноярск',
    //   areaTerritoriesSubjectBuild: 577500,
    //   areaTerritoriesSubjectTransportLine: 346500,
    //   areaTPU: 2000,
    //   areaTerritoriesSubjectReclamation: 229000,
    // },
  ],
  [SOCECO_TABLE_IDS.TABLE_3_7]: [
    // {
    //   transportArea: 'Бугач – Каракуша',
    //   area: 780000,
    //   areaTPU: 500,
    //   areaTerritoriesSubjectTransportLine: 351000,
    //   areaTerritoriesSubjectReclamation: 233000,
    //   areaTerritoriesSubjectRestoration: 195500,
    // },
    // {
    //   transportArea: 'Каракуша – Дрокино',
    //   area: 400000,
    //   areaTPU: 700,
    //   areaTerritoriesSubjectTransportLine: 180000,
    //   areaTerritoriesSubjectReclamation: 120000,
    //   areaTerritoriesSubjectRestoration: 99300,
    // },
    // {
    //   transportArea: 'Дрокино – Емельяново',
    //   area: 660000,
    //   areaTPU: 400,
    //   areaTerritoriesSubjectTransportLine: 297000,
    //   areaTerritoriesSubjectReclamation: 198000,
    //   areaTerritoriesSubjectRestoration: 164600,
    // },
    // {
    //   transportArea: 'Емельяново – Семирадское',
    //   area: 420000,
    //   areaTPU: 700,
    //   areaTerritoriesSubjectTransportLine: 189000,
    //   areaTerritoriesSubjectReclamation: 126000,
    //   areaTerritoriesSubjectRestoration: 104300,
    // },
    // {
    //   transportArea: 'Семирадское – Аэропорт Красноярск',
    //   area: 77000,
    //   areaTPU: 700,
    //   areaTerritoriesSubjectTransportLine: 346500,
    //   areaTerritoriesSubjectReclamation: 229000,
    //   areaTerritoriesSubjectRestoration: 193800,
    // },
  ],

  [SOCECO_TABLE_IDS.TABLE_4_1]: [
    // {
    //   transportObject: 'Аэропорт Красноярск',
    //   year: 2023,
    //   numberPassengersPerDay: 0,
    //   requiredNumberPersonalMobilityDevices: 0,
    // },
    // {
    //   transportObject: 'Аэропорт Красноярск',
    //   year: 2024,
    //   numberPassengersPerDay: 0,
    //   requiredNumberPersonalMobilityDevices: 0,
    // },
    // {
    //   transportObject: 'Аэропорт Красноярск',
    //   year: 2025,
    //   numberPassengersPerDay: 0,
    //   requiredNumberPersonalMobilityDevices: 0,
    // },
    // {
    //   transportObject: 'Аэропорт Красноярск',
    //   year: 2026,
    //   numberPassengersPerDay: 0,
    //   requiredNumberPersonalMobilityDevices: 0,
    // },
    // {
    //   transportObject: 'Аэропорт Красноярск',
    //   year: 2027,
    //   numberPassengersPerDay: 1415,
    //   requiredNumberPersonalMobilityDevices: 14,
    // },
  ],
  [SOCECO_TABLE_IDS.TABLE_4_2]: [
    // {
    //   transportArea: 'Ботанический бульвар',
    //   project:
    //     'Железнодорожная линия Бугач – Аэропорт Емельяново - автомобильная дорога ОП РЗ 04К-297 Красноярск - Элита',
    //   totalNumberIntersectionsRequired: 1,
    // },
  ],
  [SOCECO_TABLE_IDS.TABLE_4_3]: [
    {
      transportObject: 'Бугач',
      project: 'Строительство линии до аэропорта Емельяново',
      simultaneousMaximumPassTraffic: 124,
      numberDigitalTransportSystemsRequired: 2,
    },
    {
      transportObject: 'Каракуша',
      project: 'Строительство линии до аэропорта Емельяново',
      simultaneousMaximumPassTraffic: 51,
      numberDigitalTransportSystemsRequired: 1,
    },
    {
      transportObject: 'Дрокино',
      project: 'Строительство линии до аэропорта Емельяново',
      simultaneousMaximumPassTraffic: 60,
      numberDigitalTransportSystemsRequired: 1,
    },
    {
      transportObject: 'Емельяново',
      project: 'Строительство линии до аэропорта Емельяново',
      simultaneousMaximumPassTraffic: 97,
      numberDigitalTransportSystemsRequired: 2,
    },
    {
      transportObject: 'Семирадское',
      project: 'Строительство линии до аэропорта Емельяново',
      simultaneousMaximumPassTraffic: 30,
      numberDigitalTransportSystemsRequired: 1,
    },
    {
      transportObject: 'Аэропорт Красноярск',
      project: 'Строительство линии до аэропорта Емельяново',
      simultaneousMaximumPassTraffic: 472,
      numberDigitalTransportSystemsRequired: 2,
    },
  ],
  [SOCECO_TABLE_IDS.TABLE_4_4]: [
    // {
    //   projectArea: 'Бугач – Каракуша',
    //   project: 'Железнодорожная линия Бугач – Аэропорт Емельяново',
    //   buildingAreaLength: '7,8',
    //   numberJobsCreated: 66,
    // },
    // {
    //   projectArea: 'Каракуша – Дрокино',
    //   project: 'Железнодорожная линия Бугач – Аэропорт Емельяново',
    //   buildingAreaLength: '4,0',
    //   numberJobsCreated: 8,
    // },
    // {
    //   projectArea: 'Дрокино – Емельяново',
    //   project: 'Железнодорожная линия Бугач – Аэропорт Емельяново',
    //   buildingAreaLength: '6,6',
    //   numberJobsCreated: 14,
    // },
    // {
    //   projectArea: 'Емельяново – Семирадское',
    //   project: 'Железнодорожная линия Бугач – Аэропорт Емельяново',
    //   buildingAreaLength: '4,2',
    //   numberJobsCreated: 9,
    // },
    // {
    //   projectArea: 'Семирадское – Аэропорт Красноярск',
    //   project: 'Железнодорожная линия Бугач – Аэропорт Емельяново',
    //   buildingAreaLength: '7,7',
    //   numberJobsCreated: 116,
    // },
  ],

  [SOCECO_TABLE_IDS.TABLE_5_1]: [
    // {
    //   nameArea: 'Красноярск-ТЭЦ – Агентство Аэрофлота',
    //   numberPassengersCarried: 10,
    //   carryingCapacity: 12,
    //   networkCongestion: '83,3%',
    // },
    // {
    //   nameArea: 'Красноярск-ТЭЦ– Посёлок Энергетиков',
    //   numberPassengersCarried: 8,
    //   carryingCapacity: 10,
    //   networkCongestion: '80,0%',
    // },
  ],
  [SOCECO_TABLE_IDS.TABLE_5_2]: [
    {
      transportObject: 'Бугач',
      transportArea: 'Ботанический бульвар',
      simultaneousMaximumPassTraffic: 200,
      numberPublicTransportRoutes: 8,
    },
  ],
  [SOCECO_TABLE_IDS.TABLE_5_3]: [
    {
      transportObject: 'Бугач',
      stoppingPoints: 'Магазин Локомотив',
      year: 2027,
      averageTimeTransferFromRoadTransport: 4,
      averageTimeTransferFromPublicTransport: 8,
    },
    {
      transportObject: 'Бугач',
      stoppingPoints: 'Магазин Локомотив',
      year: 2027,
      averageTimeTransferFromRoadTransport: 3,
      averageTimeTransferFromPublicTransport: 7.5,
    },
    {
      transportObject: 'Бугач',
      stoppingPoints: 'Северо-Западный район',
      year: 2027,
      averageTimeTransferFromRoadTransport: 4,
      averageTimeTransferFromPublicTransport: 6,
    },
    {
      transportObject: 'Бугач',
      stoppingPoints: 'Северо-Западный район',
      year: 2027,
      averageTimeTransferFromRoadTransport: 4,
      averageTimeTransferFromPublicTransport: 6,
    },
    {
      transportObject: 'Бугач',
      stoppingPoints: 'База УМТС',
      year: 2027,
      averageTimeTransferFromRoadTransport: 3,
      averageTimeTransferFromPublicTransport: 6,
    },
    {
      transportObject: 'Бугач',
      stoppingPoints: 'База УМТС',
      year: 2027,
      averageTimeTransferFromRoadTransport: 4,
      averageTimeTransferFromPublicTransport: 5,
    },
    {
      transportObject: 'Бугач',
      stoppingPoints: 'База УМТС',
      year: 2027,
      averageTimeTransferFromRoadTransport: 4,
      averageTimeTransferFromPublicTransport: 7.5,
    },
    {
      transportObject: 'Бугач',
      stoppingPoints: 'База УМТС',
      year: 2027,
      averageTimeTransferFromRoadTransport: 4,
      averageTimeTransferFromPublicTransport: 7.5,
    },
    {
      transportObject: 'Бугач',
      stoppingPoints: 'Пожарная часть',
      year: 2027,
      averageTimeTransferFromRoadTransport: 4,
      averageTimeTransferFromPublicTransport: 6,
    },
    {
      transportObject: 'Бугач',
      stoppingPoints: 'Пожарная часть',
      year: 2027,
      averageTimeTransferFromRoadTransport: 3,
      averageTimeTransferFromPublicTransport: 6,
    },
    {
      transportObject: 'Бугач',
      stoppingPoints: 'Ботанический бульвар',
      year: 2027,
      averageTimeTransferFromRoadTransport: 3,
      averageTimeTransferFromPublicTransport: 6,
    },
    {
      transportObject: 'Бугач',
      stoppingPoints: 'Ботанический бульвар',
      year: 2027,
      averageTimeTransferFromRoadTransport: 3,
      averageTimeTransferFromPublicTransport: 7.5,
    },
    {
      transportObject: 'Бугач',
      stoppingPoints: 'Посёлок ГЭС',
      year: 2027,
      averageTimeTransferFromRoadTransport: 3,
      averageTimeTransferFromPublicTransport: 7.5,
    },
    {
      transportObject: 'Бугач',
      stoppingPoints: 'Поселовк ГЭС',
      year: 2027,
      averageTimeTransferFromRoadTransport: 4,
      averageTimeTransferFromPublicTransport: 6,
    },
    {
      transportObject: 'Бугач',
      stoppingPoints: 'КПАТП-5',
      year: 2027,
      averageTimeTransferFromRoadTransport: 4,
      averageTimeTransferFromPublicTransport: 7.5,
    },
    {
      transportObject: 'Бугач',
      stoppingPoints: 'КПАТП-5',
      year: 2027,
      averageTimeTransferFromRoadTransport: 4,
      averageTimeTransferFromPublicTransport: 7.5,
    },
  ],
  [SOCECO_TABLE_IDS.TABLE_5_4]: [
    {
      transportObject: 'Аэропорт Красноярск',
      year: 2023,
      simultaneousMaximumPassTraffic: 0,
      requiredNumberParkingSpaces: 0,
    },
    {
      transportObject: 'Аэропорт Красноярск',
      year: 2024,
      simultaneousMaximumPassTraffic: 0,
      requiredNumberParkingSpaces: 0,
    },
    {
      transportObject: 'Аэропорт Красноярск',
      year: 2025,
      simultaneousMaximumPassTraffic: 0,
      requiredNumberParkingSpaces: 0,
    },
    {
      transportObject: 'Аэропорт Красноярск',
      year: 2026,
      simultaneousMaximumPassTraffic: 0,
      requiredNumberParkingSpaces: 0,
    },
    {
      transportObject: 'Аэропорт Красноярск',
      year: 2027,
      simultaneousMaximumPassTraffic: 472,
      requiredNumberParkingSpaces: 47,
    },
  ],
  [SOCECO_TABLE_IDS.TABLE_5_5]: [
    // {
    //   transportObject: 'Бугач',
    //   year: '2007',
    //   simultaneousMaximumPassTraffic: '124',
    //   numberNavigationElements: 6,
    // },
    // {
    //   transportObject: 'Каракуша',
    //   year: '2007',
    //   simultaneousMaximumPassTraffic: '51',
    //   numberNavigationElements: 3,
    // },
    // {
    //   transportObject: 'Дрокино',
    //   year: '2007',
    //   simultaneousMaximumPassTraffic: '60',
    //   numberNavigationElements: 3,
    // },
    // {
    //   transportObject: 'Емельяново',
    //   year: '2007',
    //   simultaneousMaximumPassTraffic: '97',
    //   numberNavigationElements: 5,
    // },
    // {
    //   transportObject: 'Семирадское',
    //   year: '2007',
    //   simultaneousMaximumPassTraffic: '30',
    //   numberNavigationElements: 2,
    // },
    // {
    //   transportObject: 'Аэропорт Красноярск',
    //   year: '2007',
    //   simultaneousMaximumPassTraffic: '472',
    //   numberNavigationElements: 24,
    // },
  ],
  [SOCECO_TABLE_IDS.TABLE_5_6]: [
    // {
    //   transportObject: 'Бугач',
    //   adjacentArea: 1000,
    //   costImprovementActivities: '6,3',
    // },
    // {
    //   transportObject: 'Каракуша',
    //   adjacentArea: 500,
    //   costImprovementActivities: '3,2',
    // },
    // {
    //   transportObject: 'Дрокино',
    //   adjacentArea: 500,
    //   costImprovementActivities: '3,2',
    // },
    // {
    //   transportObject: 'Емельяново',
    //   adjacentArea: 500,
    //   costImprovementActivities: '3,2',
    // },
    // {
    //   transportObject: 'Семирадское',
    //   adjacentArea: 500,
    //   costImprovementActivities: '3,2',
    // },
    // {
    //   transportObject: 'Аэропорт Красноярск',
    //   adjacentArea: 2000,
    //   costImprovementActivities: '12,7',
    // },
  ],
  [SOCECO_TABLE_IDS.TABLE_5_7]: [
    {
      transportObject: '-',
      transportArea: '-',
      year: '-',
      volumesCargoFlows: '-',
      needStorageFacilities: '-',
    },
  ],
  [SOCECO_TABLE_IDS.TABLE_5_8]: [
    // {
    //   nameTransportInitiative:
    //     'Строительство железнодорожной линии Бугач – Аэропорт Емельяново',
    //   planningDocument:
    //     'Стратегия социально-экономического развития Красноярского края до 2030 года',
    //   startYearImplementation: 2024,
    //   yearCompletion: 2027,
    // },
  ],

  [SOCECO_TABLE_IDS.TABLE_6_1]: [
    {
      year: '2023',
      population: '1171,1',
      shoppingCenterArea: '922,3',
      numberCollectiveAccommodationFacilities: '17070',
      constructionFinancing: '0',
      frequencyServicePromisingLine: '0',
    },
    {
      year: '2024',
      population: '1190,5',
      shoppingCenterArea: '943',
      numberCollectiveAccommodationFacilities: '17258',
      constructionFinancing: '4000',
      frequencyServicePromisingLine: '0',
    },
    {
      year: '2025',
      population: '1210',
      shoppingCenterArea: '963,8',
      numberCollectiveAccommodationFacilities: '17448',
      constructionFinancing: '4000',
      frequencyServicePromisingLine: '0',
    },
    {
      year: '2026',
      population: '1229,6',
      shoppingCenterArea: '984,5',
      numberCollectiveAccommodationFacilities: '17640',
      constructionFinancing: '4000',
      frequencyServicePromisingLine: '0',
    },
    {
      year: '2027',
      population: '1249,3',
      shoppingCenterArea: '1005,4',
      numberCollectiveAccommodationFacilities: '17834',
      constructionFinancing: '4000',
      frequencyServicePromisingLine: '15',
    },
  ],
  [SOCECO_TABLE_IDS.TABLE_6_2]: [
    {
      tpu: 'Бугач',
      numberPassengersTransportedViaTPU: '135,8',
      costImprovementActivities: '6,3',
      numberParkingSpacesWithinTPU: '13',
      numberPersonalMobilityDevices: '2',
    },
    {
      tpu: 'Каракуша',
      numberPassengersTransportedViaTPU: '55,8',
      costImprovementActivities: '3,2',
      numberParkingSpacesWithinTPU: '6',
      numberPersonalMobilityDevices: '1',
    },
    {
      tpu: 'Дрокино',
      numberPassengersTransportedViaTPU: '65,7',
      costImprovementActivities: '3,2',
      numberParkingSpacesWithinTPU: '6',
      numberPersonalMobilityDevices: '1',
    },
    {
      tpu: 'Емельяново',
      numberPassengersTransportedViaTPU: '106,2',
      costImprovementActivities: '3,2',
      numberParkingSpacesWithinTPU: '10',
      numberPersonalMobilityDevices: '1',
    },
    {
      tpu: 'Семирадское',
      numberPassengersTransportedViaTPU: '32,9',
      costImprovementActivities: '3,2',
      numberParkingSpacesWithinTPU: '3',
      numberPersonalMobilityDevices: '1',
    },
    {
      tpu: 'Аэропорт Красноярск',
      numberPassengersTransportedViaTPU: '516,3',
      costImprovementActivities: '12,7',
      numberParkingSpacesWithinTPU: '48',
      numberPersonalMobilityDevices: '5',
    },
  ],
  [SOCECO_TABLE_IDS.TABLE_6_3]: [
    {
      year: '2023',
      population: '1171,1',
      reducingCarbonDioxideEmissions: '0',
      areaDisturbedLands: '0',
    },
    {
      year: '2024',
      population: '1190,5',
      reducingCarbonDioxideEmissions: '-30,7',
      areaDisturbedLands: '0',
    },
    {
      year: '2025',
      population: '1210',
      reducingCarbonDioxideEmissions: '-32,5',
      areaDisturbedLands: '0',
    },
    {
      year: '2026',
      population: '1229,6',
      reducingCarbonDioxideEmissions: '-52,4',
      areaDisturbedLands: '0',
    },
    {
      year: '2027',
      population: '1249,3',
      reducingCarbonDioxideEmissions: '41,9',
      areaDisturbedLands: '166,35',
    },
  ],

  [SOCECO_TABLE_IDS.TABLE_7_1]: [
    {
      year: '2023',
      population: '1171,1',
      shoppingCenterArea: '922,3',
      numberCollectiveAccommodationFacilities: '17070',
      constructionFinancing: '0',
      frequencyServicePromisingLine: '0',
    },
    {
      year: '2024',
      population: '1190,5',
      shoppingCenterArea: '943',
      numberCollectiveAccommodationFacilities: '17258',
      constructionFinancing: '4000',
      frequencyServicePromisingLine: '0',
    },
    {
      year: '2025',
      population: '1210',
      shoppingCenterArea: '963,8',
      numberCollectiveAccommodationFacilities: '17448',
      constructionFinancing: '4000',
      frequencyServicePromisingLine: '0',
    },
    {
      year: '2026',
      population: '1229,6',
      shoppingCenterArea: '984,5',
      numberCollectiveAccommodationFacilities: '17640',
      constructionFinancing: '4000',
      frequencyServicePromisingLine: '0',
    },
    {
      year: '2027',
      population: '1249,3',
      shoppingCenterArea: '1005,4',
      numberCollectiveAccommodationFacilities: '17834',
      constructionFinancing: '4000',
      frequencyServicePromisingLine: '15',
    },
  ],
  [SOCECO_TABLE_IDS.TABLE_7_2]: [
    {
      nameTransportLineSection: 'Бугач - Аэропорт Красноярск',
      year: 2023,
      volumeTransportWork: '-',
      amountElectricityConsumed: '-',
    },
    {
      nameTransportLineSection: 'Бугач - Аэропорт Красноярск',
      year: 2024,
      volumeTransportWork: '-',
      amountElectricityConsumed: '-',
    },
    {
      nameTransportLineSection: 'Бугач - Аэропорт Красноярск',
      year: 2025,
      volumeTransportWork: '-',
      amountElectricityConsumed: '-',
    },
    {
      nameTransportLineSection: 'Бугач - Аэропорт Красноярск',
      year: 2026,
      volumeTransportWork: '-',
      amountElectricityConsumed: '-',
    },
    {
      nameTransportLineSection: 'Бугач - Аэропорт Красноярск',
      year: 2027,
      volumeTransportWork: '165,9',
      amountElectricityConsumed: '331,8',
    },
  ],
  [SOCECO_TABLE_IDS.TABLE_7_3]: [
    {
      transportObject: 'Бугач',
      areaTPU: 2000,
      numberInteractiveMapsRequired: 1,
    },
    {
      transportObject: 'Каракуша',
      areaTPU: 1000,
      numberInteractiveMapsRequired: 1,
    },
    {
      transportObject: 'Дрокино',
      areaTPU: 1000,
      numberInteractiveMapsRequired: 1,
    },
    {
      transportObject: 'Емельяново',
      areaTPU: 1000,
      numberInteractiveMapsRequired: 1,
    },
    {
      transportObject: 'Семирадское',
      areaTPU: 1000,
      numberInteractiveMapsRequired: 1,
    },
    {
      transportObject: 'Аэропорт Красноярск',
      areaTPU: 3000,
      numberInteractiveMapsRequired: 2,
    },
  ],
  [SOCECO_TABLE_IDS.TABLE_7_4]: [
    {
      year: '2023',
      population: '1171,1',
      shoppingCenterArea: '922,3',
      numberCollectiveAccommodationFacilities: '17070',
      constructionFinancing: '0',
      frequencyServicePromisingLine: '0',
    },
    {
      year: '2024',
      population: '1190,5',
      shoppingCenterArea: '943',
      numberCollectiveAccommodationFacilities: '17258',
      constructionFinancing: '4000',
      frequencyServicePromisingLine: '0',
    },
    {
      year: '2025',
      population: '1210',
      shoppingCenterArea: '963,8',
      numberCollectiveAccommodationFacilities: '17448',
      constructionFinancing: '4000',
      frequencyServicePromisingLine: '0',
    },
    {
      year: '2026',
      population: '1229,6',
      shoppingCenterArea: '984,5',
      numberCollectiveAccommodationFacilities: '17640',
      constructionFinancing: '4000',
      frequencyServicePromisingLine: '0',
    },
    {
      year: '2027',
      population: '1249,3',
      shoppingCenterArea: '1005,4',
      numberCollectiveAccommodationFacilities: '17834',
      constructionFinancing: '4000',
      frequencyServicePromisingLine: '15',
    },
  ],
  [SOCECO_TABLE_IDS.TABLE_7_5]: [
    {
      nameTransportLineSection: 'Бугач - Аэропорт Красноярск',
      year: 2023,
      volumeTransportWork: '-',
      amountElectricityConsumed: '-',
    },
    {
      nameTransportLineSection: 'Бугач - Аэропорт Красноярск',
      year: 2024,
      volumeTransportWork: '-',
      amountElectricityConsumed: '-',
    },
    {
      nameTransportLineSection: 'Бугач - Аэропорт Красноярск',
      year: 2025,
      volumeTransportWork: '-',
      amountElectricityConsumed: '-',
    },
    {
      nameTransportLineSection: 'Бугач - Аэропорт Красноярск',
      year: 2026,
      volumeTransportWork: '-',
      amountElectricityConsumed: '-',
    },
    {
      nameTransportLineSection: 'Бугач - Аэропорт Красноярск',
      year: 2027,
      volumeTransportWork: '165,9',
      amountElectricityConsumed: '331,8',
    },
  ],
};
