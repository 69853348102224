import { useUnit } from 'effector-react';
import { ChangeEvent, useCallback } from 'react';

import { TextField } from '@mui/material';

import {
  $CurrentProject,
  CurrentProjectApi,
} from '@features/ag-projects-registry/store/currentProject';
import { $Settings } from '@features/ag-projects-registry/store/settings/store';

export const ProjectName = () => {
  const currentAgglomeration = useUnit($CurrentProject);
  const { readonly } = useUnit($Settings);
  const { setProjectName } = useUnit(CurrentProjectApi);

  const handleChangeName = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setProjectName(event.target.value);
    },
    [setProjectName],
  );

  if (!currentAgglomeration) return null;

  return (
    <TextField
      placeholder="Название проекта"
      disabled={readonly}
      value={currentAgglomeration.name}
      onChange={handleChangeName}
      sx={{ mt: 1.25 }}
    />
  );
};
