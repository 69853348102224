import { apiClient } from '@api/client';
import { IMutation, IMutationCreateGraphItemArgs } from '@api/gql/types';
import { gql } from '@apollo/client';

export const mutationCreateGraphItem = async (
  newGraphItem: IMutationCreateGraphItemArgs,
) => {
  const result = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation CreateGraphItemMutation(
        $endVertexId: UUID!
        $geometry: MultiLineStringInput!
        $scenarioId: UUID!
        $startVertexId: UUID!
        $year: Int!
        $name: String!
      ) {
        createGraphItem(
          endVertexId: $endVertexId
          geometry: $geometry
          scenarioId: $scenarioId
          startVertexId: $startVertexId
          year: $year
          name: $name
        ) {
          instance {
            name
            endVertex {
              id
              theGeom
            }
            geometry
            id
            relatedVertices {
              id
              theGeom
            }
            startVertex {
              id
              theGeom
            }
            year
          }
        }
      }
    `,
    variables: newGraphItem,
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result.data?.createGraphItem;
};
