import { IComputingRegistryType } from '@api/gql/types';
import { mockComputingResults } from '@features/pt-constructor/constants/mock/mockComputingResults';
import { getScenarioType } from '@features/pt-constructor/constants/socialScenarioType';
import { CalculationScenario } from '@features/pt-constructor/store';
import { Maybe } from 'graphql/jsutils/Maybe';

export const userScenariosReformatter = (
  data: Maybe<Maybe<IComputingRegistryType>[]>,
): CalculationScenario[] =>
  data
    ? data.map(scenario => ({
        id: scenario?.id || '',
        name: scenario?.name || '',
        gdpGrowthRate: scenario?.socEcoScenario?.gdpGrowthRate || 0,
        growthRateInTheNumberOfPlacements:
          scenario?.socEcoScenario?.growthRateInTheNumberOfPlacements || 0,
        growthRateOfMotorization:
          scenario?.socEcoScenario?.growthRateOfMotorization || 0,
        jobGrowthRate: scenario?.socEcoScenario?.jobGrowthRate || 0,
        populationGrowthRate:
          scenario?.socEcoScenario?.populationGrowthRate || 0,
        projectObjects:
          scenario?.transportScenario?.transportProjects?.map(projects => ({
            id: projects?.id || '',
            name: projects?.transportProject?.name || '',
            year: projects?.year || 2024,
          })) || [], // где?
        socEcoScenarioType: getScenarioType(
          scenario?.socEcoScenario?.scenarioType || 'A_4',
        ),
        socEcoComputeName: scenario?.socEcoScenario?.name || '',
        socEcoScenarioId: scenario?.socEcoScenario?.id || '',
        transportScenarioType: getScenarioType(
          scenario?.transportScenario?.scenarioType || 'A_4',
        ),
        transportComputeName: scenario?.transportScenario?.name || '',
        transportScenarioId: scenario?.transportScenario?.id || '',
        computingResults: mockComputingResults,
        computingresultsSet: null,
      }))
    : [];
