export const SOC_ECO_TH_2_2 = [
  {
    id: 'nameArea',
    label: 'Наименование участка',
  },
  {
    id: 'numberPassengersCarried',
    label: 'Число перевезённых пассажиров, тыс. чел. / сутки',
  },
  {
    id: 'carryingCapacity',
    label: 'Провозная способность, тыс. чел. / сутки ',
  },
  {
    id: 'networkAreaCongestion',
    label: 'Загруженность участков сети, %',
  },
];
