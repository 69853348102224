import { Icon, Style } from 'ol/style';

import RailwayStationSVG from '@components/icons/RailwayStation.svg';
import RailwayStationSelectedSVG from '@components/icons/RailwayStationSelected.svg';

import { EMapFeatureMetaKeys } from '@constants/map';

import { EFeatureStyle } from '@utils/map/hooks/useInitVectorLayer';
import { VectorStyleProps } from '@utils/map/styles/types';

export const ptRailwayStationVectorStyle = (props: VectorStyleProps) => {
  const { featureStyles, feature, map, maxRenderZoom } = props;
  let image = RailwayStationSVG;
  let currentMaxRenderZoom = maxRenderZoom || 8;

  const zoom = map.getView().getZoom()!;

  if (
    featureStyles &&
    Object.keys(featureStyles).includes(
      String(feature?.get(EMapFeatureMetaKeys.olId)),
    )
  ) {
    const style = featureStyles[feature?.get(EMapFeatureMetaKeys.olId)];
    switch (style) {
      case EFeatureStyle.selected:
        image = RailwayStationSelectedSVG;
        currentMaxRenderZoom = 0;
    }
  }

  if (zoom < currentMaxRenderZoom) return undefined;

  return new Style({
    image: new Icon({
      src: image,
    }),
  });
};
