import { sample } from 'effector';
import { Feature } from 'ol';
import { MultiLineString } from 'ol/geom';

import { EFilterMapLayout } from '@components/MapFilters';

import {
  getWidthLine,
  layerIdKey,
  layerLayoutKey,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import {
  $FilterMap,
  $FilterRegions,
  $Map,
  $TimeLine,
  SetMapFeaturesTypes,
  changeMap,
  setMapFeatures,
} from '@features/passenger-traffic/stores';

import { recursivelyFromLonLat } from '@utils/geometry';

sample({
  clock: changeMap,
  source: {
    FilterMap: $FilterMap,
    FilterRegions: $FilterRegions,
    Map: $Map,
    Timeline: $TimeLine,
  },
  fn: ({ FilterMap, FilterRegions, Map, Timeline }) => {
    const layer: SetMapFeaturesTypes = {
      params: {
        layout: EMapFeatureLayout.directionHighway,
        width: 3,
      },
      features: [],
    };

    // В фильтре отключено отображение автодорог
    if (!FilterMap.autoRoot) return layer;

    // Не выбрано отображение существующих структур
    if (!FilterMap.actual) return layer;

    // Не установлены оба поина АБ
    if (!FilterRegions.selectedRegionA || !FilterRegions.selectedRegionB)
      return layer;

    // НЕ блокирующее условие определения толщины линии
    if (FilterMap.layout === EFilterMapLayout.PassengerFlows) {
      layer.params.width = getWidthLine(
        (FilterMap.auto ? Map.passengerTrafficPercent.auto || 0 : 0) +
          (FilterMap.bus ? Map.passengerTrafficPercent.bus || 0 : 0),
      );
    }

    //Если поинты АБ установлены, можно построить авто сообщение между районами
    if (FilterRegions.selectedRegionA && FilterRegions.selectedRegionB) {
      // const uniqueIds = new Set();

      layer.features = FilterRegions.directions.highway
        .filter(
          item =>
            item.details![0]?.status === 'A_1' ||
            item.details![0]?.status === 'A_2' ||
            (item.details![0]?.status === 'A_3' &&
              item.details![0].year! <= Timeline.baseYear),
        )
        .map(
          item =>
            new Feature({
              geometry: new MultiLineString(
                recursivelyFromLonLat(item.geometry.coordinates),
              ),
              [layerIdKey]: item.id,
              [layerLayoutKey]: EMapFeatureLayout.directionHighway,
            }),
        );
      return layer;
    }

    // Пока глобально отображать нечего
    return layer;
  },
  target: setMapFeatures,
});
