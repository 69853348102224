import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';
import { AccordionFilter } from '@components/MapFilters/AccordionFilter';

export const FilterContainer = () => {
  return (
    <AutoFullHeight
      style={{
        paddingTop: '24px',
        minWidth: '500px',
        maxWidth: '500px',
        overflowY: 'auto',
        backgroundColor: 'white',
        height: '100%',
        boxShadow: 'rgba(0, 0, 0, 0.1) 5px 4px 2px 0px',
        zIndex: 2,
      }}
    >
      <Typography
        variant={'subtitle2'}
        pl={5.5}
      >
        Фильтры
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          mt: 2,
          py: 1,
          pl: 5.5,
        }}
      >
        <AccordionFilter
          TitleComponent={
            <Typography variant="subtitle2">Северное шоссе (ТПУ 1)</Typography>
          }
        >
          <RadioGroup
            defaultValue="default"
            sx={{
              ' .MuiFormControlLabel-root': {
                height: '32px',
              },
            }}
          >
            <FormControlLabel
              value="default"
              label="Основное размещение ТПУ"
              control={<Radio />}
            />
            <FormControlLabel
              value="tpu1"
              label="Альтернативное размещение ТПУ №1"
              control={<Radio />}
            />
            <FormControlLabel
              value="tpu2"
              label="Альтернативное размещение ТПУ №2"
              control={<Radio />}
            />
          </RadioGroup>
        </AccordionFilter>

        <AccordionFilter
          TitleComponent={
            <Typography variant="subtitle2">Северное шоссе (ТПУ 2)</Typography>
          }
        >
          <RadioGroup
            defaultValue="default"
            sx={{
              ' .MuiFormControlLabel-root': {
                height: '32px',
              },
            }}
          >
            <FormControlLabel
              value="default"
              label="Основное размещение ТПУ"
              control={<Radio />}
            />
            <FormControlLabel
              value="tpu1"
              label="Альтернативное размещение ТПУ №1"
              control={<Radio />}
            />
            <FormControlLabel
              value="tpu2"
              label="Альтернативное размещение ТПУ №2"
              control={<Radio />}
            />
          </RadioGroup>
        </AccordionFilter>
      </Box>
    </AutoFullHeight>
  );
};
