import { useUnit } from 'effector-react';
import { ChangeEvent, useEffect, useRef } from 'react';

import { Error } from './states/error';
import { Loading } from './states/loading';
import { Success } from './states/success';
import { Upload } from './states/upload';

import { Box, Typography } from '@mui/material';

import { DownloadFileFx } from '@features/ag-projects-registry/store/downloadFile';
import {
  $Files,
  EFileStatuses,
  EFileTypes,
  FileState,
  FilesApi,
  FilesStore,
  UploadFile,
} from '@features/ag-projects-registry/store/files';
import { $Settings } from '@features/ag-projects-registry/store/settings/store';

import { palette } from '@system/theme/palette';

interface IBlockFileUpload {
  title: string;
  stateFile: FileState;
  keyFile: EFileTypes;
  format: '.geojson' | '.xlsx';
}

const getTypeFile = (key: keyof FilesStore) => {
  switch (key) {
    case 'graph':
      return 1;
    case 'transportAreas':
      return 2;
    case 'centersOfTransportAreas':
      return 3;
    case 'transportZones':
      return 4;
    case 'centersOfTransportZones':
      return 5;
    case 'indicatorsOfTransportAreasAndTravelParameters':
      return 6;
    case 'dataOnSuburbanRailwayRoutes':
      return 7;
    case 'graphStops':
      return 8;
  }
};

export const BlockFileUpload = (props: IBlockFileUpload) => {
  const { title, stateFile, keyFile, format } = props;
  const uploadFile = useUnit(UploadFile);
  const downloadFileFx = useUnit(DownloadFileFx);
  const $files = useUnit($Files);
  const { setStateFile, setStatus } = useUnit(FilesApi);
  const { readonly } = useUnit($Settings);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonUploadClick = () => {
    if (readonly) return;

    fileInputRef.current!.click();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (readonly) return;

    const file = event.target.files![0];
    uploadFile({
      file,
      type: getTypeFile(keyFile),
    });
    // Обработка загруженного файла
  };

  const handleCancelDownload = () => {
    if (readonly) return;

    console.log('add abort uploading');
  };

  const handleDownload = () => {
    if (readonly) return;

    downloadFileFx({
      fileId: $files[keyFile].id || '',
    });
  };

  const handleDelete = () => {
    if (readonly) return;

    setStateFile({
      key: keyFile,
      state: {
        status: EFileStatuses.empty,
        id: null,
        error: null,
      },
    });
  };

  useEffect(() => {
    setStatus({
      status:
        $files[keyFile].status === EFileStatuses.success
          ? EFileStatuses.success
          : EFileStatuses.empty,
      key: keyFile,
    });
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        columnGap: 1,
        p: theme => theme.spacing(2),
        borderRadius: theme => theme.spacing(0.25),
        border: theme => '1px solid ' + theme.palette.border.divider,
        backgroundColor: theme =>
          stateFile.status === EFileStatuses.uploading
            ? theme.palette.customs.bgHover
            : readonly
            ? '#EBECEF'
            : theme.palette.common.white,
      }}
    >
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept={format}
      />

      <Box
        display={'flex'}
        columnGap={0.5}
      >
        <Typography
          sx={{
            color: theme =>
              readonly ? 'rgba(0, 0, 0, 0.38)' : theme.palette.common.black,
            fontSize: theme => theme.typography.pxToRem(12),
          }}
        >
          {title}

          {format && (
            <span
              style={{
                color: readonly
                  ? 'rgba(0, 0, 0, 0.38)'
                  : palette.text.secondary,
                fontSize: '12px',
                marginLeft: '6px',
              }}
            >
              ({format})
            </span>
          )}
        </Typography>
      </Box>

      {stateFile.status === EFileStatuses.empty && (
        <Upload
          disabled={readonly}
          onClick={handleButtonUploadClick}
        />
      )}

      {stateFile.status === EFileStatuses.uploading && (
        <Loading onClick={handleCancelDownload} />
      )}

      {stateFile.status === EFileStatuses.error && (
        <Error
          disabled={readonly}
          error={stateFile.error}
          onDownload={handleDownload}
          onDelete={handleDelete}
          onUpload={handleButtonUploadClick}
        />
      )}

      {stateFile.status === EFileStatuses.success && (
        <Success
          disabled={readonly}
          onDownload={handleDownload}
          onDelete={handleDelete}
        />
      )}
    </Box>
  );
};
