import { apiClient } from '@api/client';
import { IQuery, IQueryGetComputedPassTrafficArgs } from '@api/gql/types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import {
  $FilterMap,
  FilterMapApi,
} from '@features/pt-forecast-new/stores/filters';
import {
  ComputedApi,
  ComputedResulItem,
  getComputedResultsFx,
} from '@features/pt-forecast-new/stores/lists/computed';
import { $FeatureSettings } from '@features/pt-forecast-new/stores/settings';

// Запросить информацию по корреспонденции в паспотоке
sample({
  clock: [FilterMapApi.setRegionTo, FilterMapApi.setRegionFrom],
  source: { FilterMap: $FilterMap, FeatureSettings: $FeatureSettings },
  filter: ({ FilterMap }) =>
    !!FilterMap.fromSelectedRegion && !!FilterMap.toSelectedRegion,
  fn: ({ FilterMap, FeatureSettings }) => {
    return {
      computingRegistryId: FeatureSettings.scenarioId,
      regionFrom: FilterMap.fromSelectedRegion!.id,
      regionTo: FilterMap.toSelectedRegion!.id,
    } as IQueryGetComputedPassTrafficArgs;
  },
  target: getComputedResultsFx,
});

getComputedResultsFx.use(async params => {
  const result = await apiClient.query<
    Pick<IQuery, 'getComputedPassTraffic'>,
    IQueryGetComputedPassTrafficArgs
  >({
    query: gql`
      query QueryGetComputedPassTraffic(
        $computingRegistryId: String
        $regionFrom: String
        $regionTo: String
      ) {
        getComputedPassTraffic(
          computingRegistryId: $computingRegistryId
          regionFrom: $regionFrom
          regionTo: $regionTo
        ) {
          totalFlow
          year
          auto
          avia
          bus
          dayTrain
          suburbanTrain
          multimodal
          id
          nightTrain
          priceAuto
          priceSuburbanTrain
          pricePlain
          priceNightTrain
          priceExpressSuburbanTrain
          priceDayTrain
          priceBus
          priceAvia
          frequencySuburbanTrain
          frequencyPlain
          frequencyNightTrain
          frequencyExpressSuburbanTrain
          frequencyDayTrain
          frequencyBus
          frequencyAvia
          durationBus
          durationDayTrain
          durationExpressSuburbanTrain
          durationNightTrain
          durationAuto
          durationPlain
          durationSuburbanTrain
          durationAvia
          expressSuburbanTrain
          unevenness
          dailyUnevenness
          passengerTrafficByIncomeGroups {
            items {
              value
              category
            }
          }
          passengerTrafficBySex {
            female
            male
          }
          passengerTrafficByAge {
            workingAge
            underWorkingAge
            overWorkingAge
          }
        }
      }
    `,
    variables: {
      computingRegistryId: params.computingRegistryId,
      regionFrom: params.regionFrom,
      regionTo: params.regionTo,
    },
  });
  return result.data.getComputedPassTraffic || null;
});

// Рефакторинг результата и запись в стор
sample({
  clock: getComputedResultsFx.done,
  fn: request => {
    return request.result!.map(item => ({
      id: item?.id || '',
      year: item?.year || 0,
      unevenness: item?.unevenness,
      dailyUnevenness: item?.dailyUnevenness
        ? JSON.parse(item?.dailyUnevenness || '')
        : null,
      passengerTraffic: {
        multimodal:
          item?.multimodal !== 'NaN' ? Number(item?.multimodal || 0) : 0,
        auto: item?.auto !== 'NaN' ? Number(item?.auto || 0) : 0,
        bus: item?.bus !== 'NaN' ? Number(item?.bus || 0) : 0,
        dayTrain: item?.dayTrain !== 'NaN' ? Number(item?.dayTrain || 0) : 0,
        nightTrain:
          item?.nightTrain !== 'NaN' ? Number(item?.nightTrain || 0) : 0,
        suburbanTrain:
          item?.suburbanTrain !== 'NaN' ? Number(item?.suburbanTrain || 0) : 0,
        avia: item?.avia !== 'NaN' ? Number(item?.avia || 0) : 0,
        expressTrain:
          item?.expressSuburbanTrain !== 'NaN'
            ? Number(item?.expressSuburbanTrain || 0)
            : 0,
        totalFlow: item?.totalFlow !== 'NaN' ? Number(item?.totalFlow || 0) : 0,
      },
      price: {
        auto: Number(item?.priceAuto || 0),
        bus: Number(item?.priceBus || 0),
        dayTrain: Number(item?.priceDayTrain || 0),
        nightTrain: Number(item?.priceNightTrain || 0),
        suburbanTrain: Number(item?.priceSuburbanTrain || 0),
        expressTrain: Number(item?.priceExpressSuburbanTrain || 0),
        avia: Number(item?.priceAvia || 0),
      },
      duration: {
        auto: Number(item?.durationAuto || 0),
        bus: Number(item?.durationBus || 0),
        dayTrain: Number(item?.durationDayTrain || 0),
        nightTrain: Number(item?.durationNightTrain || 0),
        suburbanTrain: Number(item?.durationSuburbanTrain || 0),
        expressTrain: Number(item?.durationExpressSuburbanTrain || 0),
        avia: Number(item?.durationAvia || 0),
      },
      frequency: {
        bus: Number(item?.frequencyBus || 0),
        dayTrain: Number(item?.frequencyDayTrain || 0),
        nightTrain: Number(item?.frequencyNightTrain || 0),
        suburbanTrain: Number(item?.frequencySuburbanTrain || 0),
        expressTrain: Number(item?.frequencyExpressSuburbanTrain || 0),
        avia: Number(item?.frequencyAvia || 0),
      },
      passengerTrafficByIncomeGroups:
        item?.passengerTrafficByIncomeGroups?.items?.map(item => ({
          value: Number(item?.value || 0),
          category: item?.category || '',
        })),
      passengerTrafficByAge: {
        workingAge: Number(item?.passengerTrafficByAge?.workingAge || 0),
        underWorkingAge: Number(
          item?.passengerTrafficByAge?.underWorkingAge || 0,
        ),
        overWorkingAge: Number(
          item?.passengerTrafficByAge?.overWorkingAge || 0,
        ),
      },
      passengerTrafficBySex: {
        male: Number(item?.passengerTrafficBySex?.male || 0),
        female: Number(item?.passengerTrafficBySex?.female || 0),
      },
    })) as ComputedResulItem[];
  },
  target: ComputedApi.setComputedResults,
});

// Если регионы сброшены, сбросить стор
sample({
  clock: [
    FilterMapApi.setRegionFrom,
    FilterMapApi.setRegionTo,
    FilterMapApi.reset,
  ],
  source: { FilterMap: $FilterMap },
  fn: ({ FilterMap }) =>
    !FilterMap.toSelectedRegion || !FilterMap.fromSelectedRegion,
  target: ComputedApi.reset,
});
