import { IMutationCreateGraphItemArgs } from '@api/gql/types';
import { sample } from 'effector';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import { toLonLat } from 'ol/proj';

import { EMapFeatureLayout, EMapFeatureMetaKeys } from '@constants/map';

import { layerLayoutKey } from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import { mutationCreateGraphItem } from '@features/pt-forecast-new/stores/api/mutationCreateGraphItem';
import {
  InspectorApi,
  eInspectorType,
} from '@features/pt-forecast-new/stores/inspector';
import { clickMap } from '@features/pt-forecast-new/stores/map';
import {
  $EditorMap,
  CustomRailwayGraphGraphItem,
  DrawGraphState,
  EditorMapApi,
  createGraphItemFx,
  createStationFx,
  createVertexFx,
} from '@features/pt-forecast-new/stores/map/editor';
import { $SelectedFeatures } from '@features/pt-forecast-new/stores/map/selectedFeatures';
import { $FeatureSettings } from '@features/pt-forecast-new/stores/settings';
import { $UI, UIApi } from '@features/pt-forecast-new/stores/ui';

import { getFeatureAtPixel } from '@utils/map/tools/getFeatureAtPixel';

// Начать построение графа с клика по начальной станции
sample({
  clock: clickMap,
  source: { UI: $UI, FeatureSettings: $FeatureSettings, EditorMap: $EditorMap },
  // Если режим построения графов, и начальная точка еще не определена
  filter: ({ UI, EditorMap }) => UI.drawGraph && EditorMap.drawGraph === null,
  fn: ({ EditorMap }, event) => {
    if (!EditorMap.stations?.length) throw new Error('No TPU');

    const featureAtPixel = getFeatureAtPixel({
      map: event.map,
      pixel: event.pixel,
      includeLayouts: [EMapFeatureLayout.customRailwayStation],
      hitTolerance: 1,
    });

    if (!featureAtPixel) return null;
    const id = featureAtPixel.get(EMapFeatureMetaKeys.olId) as string;
    const tpu = EditorMap.stations.find(item => item.id === String(id));
    if (!tpu) return null;

    return {
      startStationId: id,
      nodes: [],
      endStationId: null,
      intermediateStations: [],
    } as DrawGraphState;
  },
  target: EditorMapApi.startDraw,
});

// Указание узлов нового графа
const addNode = sample({
  clock: clickMap,
  source: { UI: $UI, FeatureSettings: $FeatureSettings, EditorMap: $EditorMap },
  filter: ({ UI, EditorMap }) => UI.drawGraph && EditorMap.drawGraph !== null,
  fn: (_, event) => {
    const featureAtPixel = getFeatureAtPixel({
      map: event.map,
      pixel: event.pixel,
      includeLayouts: [EMapFeatureLayout.customRailwayStation],
      hitTolerance: 1,
    });

    if (
      featureAtPixel?.get(layerLayoutKey) ===
      EMapFeatureLayout.customRailwayStation
    ) {
      const coords = (featureAtPixel as Feature<Point>)
        .getGeometry()
        ?.getCoordinates();
      return {
        isStation: true,
        stationId: featureAtPixel.get(EMapFeatureMetaKeys.olId) as string,
        coords: toLonLat(coords!),
      };
    }

    return {
      isStation: false,
      stationId: null,
      coords: toLonLat(event.target.getCoordinateFromPixel(event.pixel)),
    };
  },
});

// Клик в пустом месте или по станции - добавить узел
sample({
  clock: addNode,
  fn: ({ coords }) => coords,
  target: EditorMapApi.addGraphNode,
});

// Кеширование последнего действия при по стороении точки (точка или станция)
sample({
  clock: addNode,
  target: EditorMapApi.setLastStep,
});

// Клик по станции - указать станцию в качестве конечной
sample({
  clock: addNode,
  source: { EditorMap: $EditorMap },
  filter: ({ EditorMap }, payload) =>
    // Если клик по станции
    payload.isStation &&
    // и у графа еще не указана конечная станция
    EditorMap.drawGraph?.endStationId === null &&
    // и указанная станция не совпадает с начальной
    payload.stationId !== EditorMap.drawGraph.startStationId,
  fn: (_, { stationId }) => stationId!,
  target: EditorMapApi.addDrawGraphEndStation,
});

// Если указана конечная станция - закончить построение графа
sample({
  clock: EditorMapApi.addDrawGraphEndStation,
  source: { EditorMap: $EditorMap },
  fn: () => false,
  target: UIApi.setDrawGraph,
});

// Если пользователь отменил построение графа
sample({
  clock: [UIApi.setDrawGraph],
  source: { UI: $UI, EditorMap: $EditorMap, FeatureSettings: $FeatureSettings },
  filter: ({ UI, EditorMap }) =>
    !UI.drawGraph &&
    !!EditorMap.drawGraph &&
    EditorMap.drawGraph.nodes.length < 2,
  target: EditorMapApi.clearDrawGraph,
});

//  По завершении строительства графа, если конечная точка не была указана станцией, создать там вершину
sample({
  clock: [UIApi.setDrawGraph],
  source: { UI: $UI, EditorMap: $EditorMap, FeatureSettings: $FeatureSettings },
  filter: ({ UI, EditorMap }) =>
    // отключен режим построения графа
    !UI.drawGraph &&
    // В сторе редактора есть наброски
    !!EditorMap.drawGraph &&
    // В набросках больше одной точки
    EditorMap.drawGraph.nodes.length > 1 &&
    // Нет конечной точки
    !EditorMap.drawGraph.endStationId &&
    // последняя точка не станция
    !EditorMap.lastStep!.isStation,
  fn: ({ EditorMap, FeatureSettings }) => {
    const lastNode =
      EditorMap.drawGraph!.nodes[EditorMap.drawGraph!.nodes.length - 1];
    return {
      scenarioId: FeatureSettings.scenarioId,
      isForGraph: true,
      geometry: {
        lon: lastNode[0],
        lat: lastNode[1],
      },
    };
  },
  target: createVertexFx,
});

// Создание станции по вершине
sample({
  clock: createVertexFx.done,
  source: {
    FeatureSettings: $FeatureSettings,
  },
  filter: (_, request) => !!request.params.isForGraph,
  fn: ({ FeatureSettings }, payload) => ({
    name: 'Безымянная станция',
    scenarioId: FeatureSettings.scenarioId,
    vertexId: payload.result?.vertex?.id,
    isForGraph: true,
  }),
  target: createStationFx,
});

// Если удалось создать ТПУ в качестве конечной точки графа, то записать её id
sample({
  clock: createStationFx.done,
  filter: request => !!request.params.isForGraph,
  fn: request => {
    return request.result?.instance?.id || '';
  },
  target: [EditorMapApi.addDrawGraphEndStation],
});

// // TODO возможно стоит вынести передачу промежуточных вершин в создание граф айтема
// sample({
//   clock: createStationFx.done,
//   filter: request => !!request.params.isForGraph,
//   fn: payload => payload.result?.instance?.id,
//   target: EditorMapApi.addIntermediateStations,
// });

// По завершении строительства графа, если у него есть и начальная и конечная точки отправить его на бэк
sample({
  clock: [EditorMapApi.addDrawGraphEndStation],
  source: { UI: $UI, EditorMap: $EditorMap, FeatureSettings: $FeatureSettings },
  filter: ({ UI, EditorMap }) =>
    !UI.drawGraph &&
    !!EditorMap.drawGraph &&
    EditorMap.drawGraph.nodes.length > 1 &&
    !!EditorMap.drawGraph.endStationId,
  fn: ({ EditorMap, FeatureSettings }) => {
    const startVertexId = EditorMap.stations.find(
      item => item.id === EditorMap.drawGraph?.startStationId,
    )?.vertexId;
    const endVertexId = EditorMap.stations.find(
      item => item?.id === EditorMap.drawGraph?.endStationId,
    )?.vertexId;

    return {
      geometry: {
        coordinates: EditorMap.drawGraph?.nodes.map(node => ({
          lat: node[1],
          lon: node[0],
        })),
      },
      scenarioId: FeatureSettings.scenarioId || '',
      startVertexId: startVertexId,
      endVertexId: endVertexId,
      year: new Date().getFullYear(),
      name: 'Безымянная линия',
    } as IMutationCreateGraphItemArgs;
  },
  target: createGraphItemFx,
});

createGraphItemFx.use(mutationCreateGraphItem);

// Переместить построенный граф из временного хранилища в постоянное
sample({
  clock: createGraphItemFx.done,
  source: { EditorMap: $EditorMap },
  fn: ({ EditorMap }, request) => {
    return {
      id: request.result?.instance?.id,
      name: request.result?.instance?.name || 'Безымянная линия',
      startStationId: EditorMap.drawGraph?.startStationId,
      endStationId: EditorMap.drawGraph?.endStationId,
      geometry: request.params.geometry?.coordinates,
      yearOfCommissioning: request.result?.instance?.year,
      maxSpeed: 0,
      delete: false,
      isUpdated: true,
      isSaved: false,
    } as CustomRailwayGraphGraphItem;
  },
  target: EditorMapApi.addGraph,
});

// Сбросить стейт рисуемого графа
sample({
  clock: createGraphItemFx.done,
  target: EditorMapApi.clearDrawGraph,
});

// // Получить параметры выбранного графа жд
sample({
  clock: $SelectedFeatures,
  source: { $selectedFeatures: $SelectedFeatures },
  filter: ({ $selectedFeatures }) =>
    $selectedFeatures[0]?.layout === EMapFeatureLayout.customRailwayGraph,
  fn: ({ $selectedFeatures }) => {
    return $selectedFeatures[0]?.id || null;
  },
  target: EditorMapApi.selectGraph,
});

// Указание типа инспектора
sample({
  clock: EditorMapApi.selectGraph,
  fn: () => {
    return eInspectorType.customRailwayGraph;
  },
  target: InspectorApi.setType,
});
