import { apiClient } from '@api/client';
import { IMutation } from '@api/gql/ag-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

import {
  $CurrentProject,
  CopyProject,
  CreateProject,
  CreateProjectFx,
  CreateProjectRequestParams,
  CurrentProjectApi,
  UpdateProject,
  UpdateProjectFx,
  UpdateProjectRequestParams,
} from '@features/ag-projects-registry/store/currentProject/store';
import { $Files, FilesApi } from '@features/ag-projects-registry/store/files';
import { FetchProjects } from '@features/ag-projects-registry/store/lists/projects';

// Триггер на создание проекта
sample({
  clock: CreateProject,
  source: {
    CurrentProject: $CurrentProject,
    Files: $Files,
  },
  filter: ({ CurrentProject }) => !!CurrentProject,
  fn: ({ CurrentProject, Files }) => {
    if (!CurrentProject) throw Error('CurrentProject is null');

    return {
      name: CurrentProject.name,
      agglomeration: CurrentProject.selectedAgglomeration?.id,
      files: Object.values(Files)
        .map(file => file.id)
        .filter(Boolean),
    } as CreateProjectRequestParams;
  },
  target: CreateProjectFx,
});

CreateProjectFx.use(async params => {
  const response = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation CreateProject(
        $name: String!
        $agglomeration: String!
        $files: [String]!
      ) {
        createProject(
          name: $name
          agglomeration: $agglomeration
          files: $files
        ) {
          project {
            id
            status
          }
        }
      }
    `,
    variables: {
      name: params.name,
      agglomeration: params.agglomeration,
      files: params.files,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });
  return response.data?.createProject || null;
});

// Сбросить выбранный проект, если запрос на его создание или обновление прошел успешно
sample({
  clock: [CreateProjectFx.done, UpdateProjectFx.done],
  target: [CurrentProjectApi.reset, FilesApi.reset, FetchProjects],
});

//-------------------------------------------------------

// Триггер на копирование проекта
sample({
  clock: CopyProject,
  source: {
    CurrentProject: $CurrentProject,
    Files: $Files,
  },
  filter: ({ CurrentProject }) => !!CurrentProject,
  fn: ({ CurrentProject, Files }) => {
    if (!CurrentProject) throw Error('CurrentProject is null');

    return {
      name: CurrentProject.name + ' (копия)',
      agglomeration: CurrentProject.selectedAgglomeration?.id,
      files: Object.values(Files)
        .map(file => file.id)
        .filter(Boolean),
    } as CreateProjectRequestParams;
  },
  target: CreateProjectFx,
});

//-------------------------------------------------------

// Триггер на обновление проекта
sample({
  clock: UpdateProject,
  source: {
    CurrentProject: $CurrentProject,
    Files: $Files,
  },
  filter: ({ CurrentProject }) => !!CurrentProject,
  fn: ({ CurrentProject, Files }) => {
    if (!CurrentProject) throw Error('CurrentProject is null');

    return {
      id: CurrentProject.id,
      name: CurrentProject.name,
      agglomerationId: CurrentProject.selectedAgglomeration?.id,
      files: Object.values(Files)
        .map(file => file.id)
        .filter(Boolean),
    } as UpdateProjectRequestParams;
  },
  target: UpdateProjectFx,
});

UpdateProjectFx.use(async params => {
  const response = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation UpdateProject(
        $id: String!
        $name: String!
        $agglomerationId: String!
        $files: [String]!
      ) {
        updateProject(
          id: $id
          name: $name
          agglomerationId: $agglomerationId
          files: $files
        ) {
          project {
            id
            status
          }
        }
      }
    `,
    variables: {
      id: params.id,
      name: params.name,
      agglomerationId: params.agglomerationId,
      files: params.files,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });
  return response.data?.updateProject || null;
});
