import { EMapFeatureLayout } from '@constants/map';

export const LAYERS_Z_INDEX = {
  [EMapFeatureLayout.highway]: 1,
  [EMapFeatureLayout.railway]: 2,
  [EMapFeatureLayout.areaBorder]: 3,
  [EMapFeatureLayout.directionHighway]: 4,
  [EMapFeatureLayout.directionRailway]: 5,
  [EMapFeatureLayout.directionAirline]: 6,
  [EMapFeatureLayout.busStation]: 7,
  [EMapFeatureLayout.railwayStation]: 8,
  [EMapFeatureLayout.airport]: 9,
  [EMapFeatureLayout.pointsAB]: 10,
  [EMapFeatureLayout.customRailwayGraph]: 11,
  [EMapFeatureLayout.customRailwayStation]: 12,
};
