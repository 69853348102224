export const SOC_ECO_TH_5_4 = [
  {
    id: 'transportObject',
    label: 'Транспортный объект',
  },
  {
    id: 'year',
    label: 'Год',
  },
  {
    id: 'simultaneousMaximumPassTraffic',
    label: 'Единовременный максимальный пассажиропоток, чел.',
  },
  {
    id: 'requiredNumberParkingSpaces',
    label: 'Необходимое число парковочных мест, шт.',
  },
];
