// import LogoImg from '@assets/logo.svg';
import { Box, Stack, Typography, useTheme } from '@mui/material';

import { CardModuleStyled } from '@features/modules/components/card/CardModule.styled';

export const MainCardModule = () => {
  const { palette } = useTheme();

  return (
    <CardModuleStyled
      bg={palette.primary.main}
      border="none"
    >
      <Stack
        m="24px"
        mt="128px"
      >
        <Stack gap="21px">
          <Box
            sx={{
              // backgroundImage: `url(${LogoImg})`,
              width: '99px',
              height: '44px',
            }}
          />
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: ({ typography }) => typography.pxToRem(16),
              color: 'white',
            }}
          >
            Транспортное моделирование и планирование
          </Typography>
        </Stack>
      </Stack>
    </CardModuleStyled>
  );
};
