import { CurrentProjectApi } from '@features/ag-projects-registry/store/currentProject';
import {
  FetchAgglomerationsFx,
  ListAgglomerationsApi,
} from '@features/ag-projects-registry/store/lists/agglomerations';
import { ListProjectsApi } from '@features/ag-projects-registry/store/lists/projects';
import { UIAgApi } from '@features/ag-projects-registry/store/ui';
import { useUnit } from 'effector-react';
import { useEffect } from 'react';

import { Grid } from '@mui/material';

import { DetailsProjectContainer } from './containers/DetailsProjectContainer/DetailsProjectContainer';
import { ListProjectsContainer } from './containers/ListProjectsContainer/ListProjectsContainer';
import { Modals } from './modals';
import './store';

export const AgglomerationProjectsRegistryContainer = () => {
  const fetchAgglomerations = useUnit(FetchAgglomerationsFx);
  const { reset: resetAgglomerations } = useUnit(ListAgglomerationsApi);
  const { reset: resetProjects } = useUnit(ListProjectsApi);
  const { reset: resetUI } = useUnit(UIAgApi);
  const { reset: resetCurrentProject } = useUnit(CurrentProjectApi);

  useEffect(() => {
    fetchAgglomerations().then();
    return () => {
      resetAgglomerations();
      resetProjects();
      resetUI();
      resetCurrentProject();
    };
  }, [
    fetchAgglomerations,
    resetAgglomerations,
    resetCurrentProject,
    resetProjects,
    resetUI,
  ]);

  return (
    <Grid
      sx={{
        p: 3,
        display: 'flex',
        columnGap: 3,
      }}
    >
      <ListProjectsContainer />

      <DetailsProjectContainer />

      <Modals />
    </Grid>
  );
};
