import { sample } from 'effector';
import { toLonLat } from 'ol/proj';

import { EMapFeatureLayout } from '@constants/map';

import { mutationCreateStation } from '@features/pt-forecast-new/stores/api/mutationCreateStation';
import { mutationCreateVertex } from '@features/pt-forecast-new/stores/api/mutationCreateVertex';
import { mutationDeleteStation } from '@features/pt-forecast-new/stores/api/mutationDeleteStation';
import {
  InspectorApi,
  eInspectorType,
} from '@features/pt-forecast-new/stores/inspector';
import { clickMap } from '@features/pt-forecast-new/stores/map';
import {
  CustomRailwayStationItem,
  EditorMapApi,
  createStationFx,
  createVertexFx,
  deleteStationFx,
} from '@features/pt-forecast-new/stores/map/editor/store';
import { $SelectedFeatures } from '@features/pt-forecast-new/stores/map/selectedFeatures';
import { $FeatureSettings } from '@features/pt-forecast-new/stores/settings';
import { $UI, UIApi } from '@features/pt-forecast-new/stores/ui';

// Определить координаты клика, и запросить создание вершины
sample({
  clock: clickMap,
  source: {
    UI: $UI,
    FeatureSettings: $FeatureSettings,
  },
  filter: ({ UI }) => UI.placeRailwayStation,
  fn: ({ FeatureSettings }, event) => {
    const coords = event.target.getCoordinateFromPixel(event.pixel);
    const lonLat = toLonLat(coords);
    return {
      geometry: {
        lat: lonLat[1],
        lon: lonLat[0],
      },
      scenarioId: FeatureSettings.scenarioId,
    };
  },
  target: createVertexFx,
});

createVertexFx.use(mutationCreateVertex);

// После создания вершины создание станции
sample({
  clock: createVertexFx.done,
  source: {
    FeatureSettings: $FeatureSettings,
  },
  filter: (_, request) => !request.params.isForGraph,
  fn: ({ FeatureSettings }, payload) => ({
    name: 'Безымянная станция',
    scenarioId: FeatureSettings.scenarioId,
    vertexId: payload.result?.vertex?.id,
    geometry: {
      lat: payload.params.geometry?.lat as number,
      lon: payload.params.geometry?.lon as number,
    },
  }),
  target: createStationFx,
});

createStationFx.use(mutationCreateStation);

// Если удалось создать ТПУ, то добавить его в карту
sample({
  clock: createStationFx.done,
  fn: request => {
    const [lon, lat] = request?.result?.instance?.vertex.theGeom
      .coordinates || [undefined, undefined];

    return {
      geometry: { lon, lat },
      id: request.result?.instance?.id || '',
      name: request.result?.instance?.name || 'Безымянная станция',
      transportArea: request.result?.instance?.transportRegion?.name,
      vertexId: request.result?.instance?.vertex.id,
      isSaved: false,
      isUpdated: true,
    } as CustomRailwayStationItem;
  },
  target: [EditorMapApi.addStation],
});

// Сеттер последнего шага построения на карте (станции)
sample({
  clock: createStationFx.done,
  fn: payload => ({
    isStation: true,
    stationId: payload.result?.instance?.id,
    coords: payload.result?.instance?.railwayStation?.geometry,
  }),
  target: EditorMapApi.setLastStep,
});

// Если удалось создать ТПУ, выйти из режима "построить станцию"
sample({
  clock: EditorMapApi.addStation,
  fn: () => false,
  target: UIApi.setPlaceRailwayStations,
});

deleteStationFx.use(mutationDeleteStation);

// Удаление станции из стора
sample({
  clock: deleteStationFx.done,
  fn: payload => payload.params.tpuId,
  target: EditorMapApi.deleteStation,
});

////////////////////////////////////////////////////////////////////////////////

// // Запросить данные по выбранному тпу
sample({
  clock: $SelectedFeatures,
  source: { SelectedFeatures: $SelectedFeatures },
  filter: ({ SelectedFeatures }) =>
    SelectedFeatures[0]?.layout === EMapFeatureLayout.customRailwayStation,
  fn: ({ SelectedFeatures }) => {
    return SelectedFeatures[0]?.id || null;
  },
  target: EditorMapApi.selectStation,
});

// Указание типа инспектора
sample({
  clock: EditorMapApi.selectStation,
  fn: () => {
    return eInspectorType.customRailwayStation;
  },
  target: InspectorApi.setType,
});

////////////////////////////////////////////////////////////////////////////////
