import { createEvent, createStore, sample } from 'effector';

import { SocEcoScenarioParams } from '@features/pt-constructor/constants/ecoParams';
import { ScenarioType } from '@features/pt-constructor/constants/socialScenarioType';
import {
  $Calculations,
  ScenarioOption,
  basicDefaultEcoParams,
} from '@features/pt-constructor/store';
import {
  checkingNameScenarioSocEco,
  shorteningValueAndMultiplyingBy100,
} from '@features/pt-constructor/utils';

export enum EStepModalSocioEconomic {
  one,
  two,
}

export type ModalSocioEconomicFields = {
  name: string;
};

type ModalSocioEconomic = {
  step: EStepModalSocioEconomic;
  name: string;
  type: ScenarioType;
  params: SocEcoScenarioParams;
  base: SocEcoScenarioParams;
  selectedId: string;
  isErrorName: boolean;
  isErrorScenario: boolean;
  isCheckErrors: boolean;
};

const defaultState = {
  step: EStepModalSocioEconomic.one,
  name: '',
  type: ScenarioType.Basic,
  params: basicDefaultEcoParams,
  base: basicDefaultEcoParams,
  selectedId: '',
  isErrorName: false,
  isErrorScenario: false,
  isCheckErrors: false,
};

export const $ModalSocioEconomic =
  createStore<ModalSocioEconomic>(defaultState);

export const setStep = createEvent<EStepModalSocioEconomic>();

export const setSocEcoName = createEvent<ModalSocioEconomic['name']>();

export const setSocEcoType = createEvent<ScenarioOption>();

export const setSocEcoParams = createEvent<ModalSocioEconomic['params']>();

const setBaseParams = createEvent<ModalSocioEconomic['base']>();

// проверка валидности имени нового сценария соц эко
export const checkErrorName = createEvent();

// проверка выбран ли базовый сценарий соц эко
export const checkErrorScenario = createEvent();

// установка динамической проверки ошибок значений на первом шаге
export const setErrorChecking = createEvent();

export const resetModalSocioEconomic = createEvent();

$ModalSocioEconomic
  .on(setStep, (state, payload) => ({
    ...state,
    step: payload,
  }))
  .on(setSocEcoName, (state, name) => ({
    ...state,
    name,
  }))
  .on(setSocEcoType, (state, option) => ({
    ...state,
    type: option.type,
    selectedId: option.value,
  }))
  .on(setSocEcoParams, (state, params) => ({
    ...state,
    params,
  }))
  .on(setBaseParams, (state, base) => ({
    ...state,
    base,
  }))
  .on(checkErrorName, state => ({
    ...state,
    isErrorName: checkingNameScenarioSocEco(state.name),
  }))
  .on(checkErrorScenario, state => ({
    ...state,
    isErrorScenario: !state.selectedId,
  }))
  .on(setErrorChecking, state => ({
    ...state,
    name: state.name.trim(),
    isErrorName: checkingNameScenarioSocEco(state.name.trim()),
    isErrorScenario: !state.selectedId,
    isCheckErrors: true,
  }))
  .reset(resetModalSocioEconomic);

sample({
  clock: setSocEcoType,
  source: $Calculations,
  fn(calculations, option) {
    const selectedScenario = calculations.socEcoScenarios.find(
      scenario => scenario.id === option.value,
    );

    return {
      gdpGrowthRate: shorteningValueAndMultiplyingBy100(
        selectedScenario?.gdpGrowthRate,
      ),
      populationGrowthRate: shorteningValueAndMultiplyingBy100(
        selectedScenario?.populationGrowthRate,
      ),
      growthRateOfMotorization: shorteningValueAndMultiplyingBy100(
        selectedScenario?.growthRateOfMotorization,
      ),
      growthRateInTheNumberOfPlacements: shorteningValueAndMultiplyingBy100(
        selectedScenario?.growthRateInTheNumberOfPlacements,
      ),
      jobGrowthRate: shorteningValueAndMultiplyingBy100(
        selectedScenario?.jobGrowthRate,
      ),
    } as SocEcoScenarioParams;
  },
  target: setBaseParams,
});

sample({
  clock: setSocEcoType,
  source: $Calculations,
  fn(calculations, option) {
    const selectedScenario = calculations.socEcoScenarios.find(
      scenario => scenario.id === option.value,
    );

    return {
      gdpGrowthRate: shorteningValueAndMultiplyingBy100(
        selectedScenario?.gdpGrowthRate,
      ),
      populationGrowthRate: shorteningValueAndMultiplyingBy100(
        selectedScenario?.populationGrowthRate,
      ),
      growthRateOfMotorization: shorteningValueAndMultiplyingBy100(
        selectedScenario?.growthRateOfMotorization,
      ),
      growthRateInTheNumberOfPlacements: shorteningValueAndMultiplyingBy100(
        selectedScenario?.growthRateInTheNumberOfPlacements,
      ),
      jobGrowthRate: shorteningValueAndMultiplyingBy100(
        selectedScenario?.jobGrowthRate,
      ),
    } as SocEcoScenarioParams;
  },
  target: setSocEcoParams,
});

// триггер проверки значений и переход на следующий шаг в случае отсутствия ошибок
sample({
  clock: setErrorChecking,
  source: $ModalSocioEconomic,
  filter: state => !state.isErrorName && !state.isErrorScenario,
  fn: () => EStepModalSocioEconomic.two,
  target: setStep,
});
