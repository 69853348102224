import { useUnit } from 'effector-react';
import { useCallback, useEffect } from 'react';

import { Close } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, Typography } from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';
import { CloseMiniIcon, DownloadIcon, InfoIcon } from '@components/icons';

import { AgglomerationSelect } from '@features/ag-projects-registry/components/DetailsProjectComponents/AgglomerationSelect';
import { ProjectName } from '@features/ag-projects-registry/components/DetailsProjectComponents/ProjectName';
import { UploadFilesBlock } from '@features/ag-projects-registry/components/DetailsProjectComponents/UploadFilesBlock';
import {
  $CurrentProject,
  CreateProject,
  CurrentProjectApi,
  UpdateProject,
} from '@features/ag-projects-registry/store/currentProject';
import {
  FilesApi,
  GetProjectFilesFx,
} from '@features/ag-projects-registry/store/files';
import {
  $Settings,
  SettingsApi,
} from '@features/ag-projects-registry/store/settings/store';
import { $UIAg, UIAgApi } from '@features/ag-projects-registry/store/ui';

export const DetailsProjectContainer = () => {
  const $currentProject = useUnit($CurrentProject);
  const { readonly } = useUnit($Settings);
  const { setStateReadonly } = useUnit(SettingsApi);
  const currentProjectApi = useUnit(CurrentProjectApi);
  const filesApi = useUnit(FilesApi);
  const { setVisibleProjectCreator, setModalView } = useUnit(UIAgApi);
  const $uiAg = useUnit($UIAg);
  const createProject = useUnit(CreateProject);
  const updateProject = useUnit(UpdateProject);

  const handleClose = useCallback(() => {
    setVisibleProjectCreator(false);
    currentProjectApi.reset();
    filesApi.reset();
    setStateReadonly(true);
  }, [currentProjectApi, filesApi, setStateReadonly, setVisibleProjectCreator]);

  const handleReset = useCallback(() => {
    currentProjectApi.reset();
    filesApi.reset();
  }, [currentProjectApi, filesApi]);

  const handleShowFileRequirements = useCallback(() => {
    setModalView({ fileRequirements: true });
  }, [setModalView]);

  const handleCreateProject = useCallback(() => {
    createProject();
  }, [createProject]);

  const handleEditProject = useCallback(() => {
    updateProject();
  }, [updateProject]);

  useEffect(() => {
    $currentProject?.id && GetProjectFilesFx({ id: $currentProject.id });
  }, [$currentProject?.id]);

  return (
    $uiAg.projectCreator && (
      <AutoFullHeight
        style={{
          backgroundColor: 'white',
          boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.15)',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          padding: '32px 0 24px',
          minWidth: '550px',
        }}
        mb={24}
      >
        <Box
          sx={{
            px: theme => theme.spacing(3),
            pb: theme => theme.spacing(1.5),
            borderBottom: theme => '1px solid ' + theme.palette.customs.bgHover,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            variant="subtitle1"
            fontSize={theme => theme.typography.pxToRem(14)}
          >
            {/* {!currentProject.id ? 'Создать новый проект' : 'Редактировать проект'} */}
            Агломерация
          </Typography>

          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 1.5,
            overflowY: 'auto',
            pl: theme => theme.spacing(3),
            pr: theme => theme.spacing(2),
            mr: theme => theme.spacing(1),
          }}
        >
          <ProjectName />

          <AgglomerationSelect />

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              columnGap: 0.75,
              color: theme => theme.palette.customs.link,
              cursor: 'pointer',
              userSelect: 'none',
            }}
            onClick={handleShowFileRequirements}
          >
            <InfoIcon />

            <Typography fontSize={theme => theme.typography.pxToRem(12)}>
              Требования к форматам файлов
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              columnGap: 0.75,
              color: theme => theme.palette.customs.link,
              cursor: 'pointer',
              userSelect: 'none',
            }}
          >
            <DownloadIcon />

            <Typography fontSize={theme => theme.typography.pxToRem(12)}>
              Скачать все шаблоны файлов
            </Typography>
          </Box>

          <Divider sx={{ my: 1.5 }} />

          <UploadFilesBlock />

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              columnGap: 0.5,
              pb: theme => theme.spacing(3),
            }}
          >
            <InfoIcon
              sx={{
                color: theme => theme.palette.secondary.main,
              }}
            />

            <Typography
              sx={{
                color: theme => theme.palette.text.secondary,
                fontSize: theme => theme.typography.pxToRem(12),
              }}
            >
              *Обязательные для загрузки
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            mt: 'auto',
            width: '100%',
            display: 'flex',
            columnGap: 1,
            px: theme => theme.spacing(3),
            pt: theme => theme.spacing(1.5),
            borderTop: theme => '1px solid ' + theme.palette.customs.bgHover,
          }}
        >
          {$currentProject.id ? (
            <Button
              fullWidth
              disabled={readonly}
              onClick={handleEditProject}
            >
              Сохранить изменения
            </Button>
          ) : (
            <Button
              fullWidth
              disabled={readonly}
              onClick={handleCreateProject}
            >
              Сохранить
            </Button>
          )}

          <Button
            fullWidth
            variant={'text'}
            disabled={readonly}
            onClick={handleReset}
            startIcon={<CloseMiniIcon />}
          >
            Сбросить всё
          </Button>
        </Box>
      </AutoFullHeight>
    )
  );
};
