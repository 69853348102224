import {
  IMutation,
  IMutationCreateGraphItemArgs,
  IMutationCreateStationArgs,
  IMutationCreateVertexArgs,
  IMutationDeleteStationArgs,
  IMutationUpdateGraphItemArgs,
  IMutationUpdateStationArgs,
  IQuery,
  IQueryGetScenarioGraphArgs,
} from '@api/gql/types';
import { createApi, createEffect, createEvent, createStore } from 'effector';
import { Coordinate } from 'ol/coordinate';

import { ECustomGraphRailwayType } from '@features/pt-forecast-new/constants/eCustomGraphRailwayType';

const initState: EditorMapStore = {
  stations: [],
  graphs: [],
  drawGraph: null,
  selectedStation: null,
  selectedGraph: null,
  lastStep: null,
};

export const $EditorMap = createStore<EditorMapStore>(initState);

export const EditorMapApi = createApi($EditorMap, {
  addStation: (state, payload: CustomRailwayStationItem) => ({
    ...state,
    stations: [...state.stations, payload],
  }),
  addGraph: (state, payload: CustomRailwayGraphGraphItem) => ({
    ...state,
    graphs: [...state.graphs, payload],
  }),
  addGraphNode: (store, payload: number[] | null) =>
    !store.drawGraph || !payload
      ? store
      : {
          ...store,
          drawGraph: {
            ...store.drawGraph,
            nodes: [...(store.drawGraph?.nodes || []), payload],
          },
        },
  addIntermediateStations: (store, payload) =>
    !store.drawGraph || !payload
      ? store
      : {
          ...store,
          drawGraph: {
            ...store.drawGraph,
            intermediateStations: [
              ...(store.drawGraph?.intermediateStations || []),
              payload,
            ],
          },
        },
  selectStation: (store, payload: string | null) => ({
    ...store,
    selectedStation: payload,
  }),
  selectGraph: (store, payload: string | null) => ({
    ...store,
    selectedGraph: payload,
  }),
  setInfrastructure: (
    store,
    payload: Pick<EditorMapStore, 'graphs' | 'stations'>,
  ) => ({
    ...store,
    ...payload,
  }),
  startDraw: (store, payload: DrawGraphState | null) =>
    !payload
      ? store
      : {
          ...store,
          drawGraph: payload,
        },
  // Здесь прокидывать ид станции на которой закончилось построение
  addDrawGraphEndStation: (store, payload: string | null) => ({
    ...store,
    drawGraph: !store.drawGraph
      ? null
      : {
          ...store.drawGraph,
          endStationId: payload,
        },
  }),
  setLastStep: (store, payload: NodeGraph | null) => ({
    ...store,
    lastStep: payload,
  }),
  clearDrawGraph: store => ({
    ...store,
    drawGraph: null,
  }),
  updateSelectedGraphParams: (
    store,
    payload: Partial<CustomRailwayGraphGraphItem>,
  ) =>
    !store.selectedGraph
      ? store
      : {
          ...store,
          graphs: store.graphs.map(graph =>
            graph.id === store.selectedGraph
              ? {
                  ...graph,
                  ...payload,
                  isUpdated: true,
                }
              : graph,
          ),
        },
  updateSelectedStationParams: (
    store,
    payload: Partial<CustomRailwayStationItem>,
  ) =>
    !store.selectedStation
      ? store
      : {
          ...store,
          stations: store.stations.map(station =>
            station.id === store.selectedStation
              ? {
                  ...station,
                  ...payload,
                  isUpdated: true,
                }
              : station,
          ),
        },
  deleteStation: (store, payload: string) => ({
    ...store,
    stations: store.stations.filter(item => item.id !== payload),
  }),
  setIsUpdatedStation: (store, payload: boolean) =>
    !store.selectedStation
      ? store
      : {
          ...store,
          stations: store.stations.map(station =>
            station.id === store.selectedStation
              ? {
                  ...station,
                  isUpdated: payload,
                }
              : station,
          ),
        },
  setIsUpdatedGraph: (store, payload: boolean) =>
    !store.selectedGraph
      ? store
      : {
          ...store,
          graphs: store.graphs.map(graph =>
            graph.id === store.selectedGraph
              ? {
                  ...graph,
                  isUpdated: payload,
                }
              : graph,
          ),
        },
  reset: () => initState,
});

export const EditorMapEvents = {
  updateInfrastructure: createEvent(),
  saveInfrastructure: createEvent(),
  fetchInfrastructure: createEvent(),
};

// Эффект получения всех графов
export const getGraphItemsFx = createEffect<
  IQueryGetScenarioGraphArgs,
  IQuery['getScenarioGraph'] | undefined
>();

// Эффект создания виртуальной точки для создания станции
export const createVertexFx = createEffect<
  IMutationCreateVertexArgs & { isForGraph?: boolean },
  IMutation['createVertex'] | undefined
>();

// Эффект создания тпу
export const createStationFx = createEffect<
  IMutationCreateStationArgs & {
    isForGraph?: boolean;
    geometry?: { lon: number; lat: number };
  },
  IMutation['createStation'] | undefined
>();

// Эффект создания графа ТПУ
export const createGraphItemFx = createEffect<
  IMutationCreateGraphItemArgs,
  IMutation['createGraphItem'] | undefined
>();

// Эффект обновления графа
export const updateGraphItemFx = createEffect<
  IMutationUpdateGraphItemArgs,
  IMutation['updateGraphItem'] | undefined
>();

// Эффект обновления станции
export const updateStationFx = createEffect<
  IMutationUpdateStationArgs,
  IMutation['updateStation'] | undefined
>();

// Эффект удаления станции
export const deleteStationFx = createEffect<
  IMutationDeleteStationArgs,
  IMutation['deleteStation'] | undefined
>();

export type CustomRailwayStationItem = {
  id: string;
  name: string;
  transportArea: string;
  geometry: { lon: number; lat: number };
  vertexId: string;
  isSaved: boolean;
  isUpdated: boolean;
  delete: boolean;
};

export type CustomRailwayGraphGraphItem = {
  id: string;
  name: string;
  infrastructureType: keyof typeof ECustomGraphRailwayType;
  maxSpeed: string | number;
  yearOfCommissioning: string | number;
  startStationId: string;
  endStationId: string;
  intermediateStations: string[];
  geometry: Array<{ lon: number; lat: number }>;
  isSaved: boolean;
  isUpdated: boolean;
  delete: boolean;
};

export type DrawGraphState = {
  startStationId: string | null;
  endStationId: string | null;
  intermediateStations: string[];
  nodes: number[][];
};

export type NodeGraph = {
  isStation: boolean;
  stationId: string | null;
  coords: Coordinate;
};

export type EditorMapStore = {
  stations: CustomRailwayStationItem[];
  graphs: CustomRailwayGraphGraphItem[];
  drawGraph: DrawGraphState | null;
  selectedStation: string | null;
  selectedGraph: string | null;
  lastStep: NodeGraph | null;
};
