import { useUnit } from 'effector-react';
import { useCallback } from 'react';

import Grid from '@mui/material/Unstable_Grid2';

import { AccordionFilter } from '@components/MapFilters/AccordionFilter';

import { DirectionPointField } from '@features/pt-forecast-new/components/MapFilters/DirectionPoints/DirectionPointField';
import {
  $FilterMap,
  FilterMapApi,
  directionPoints,
} from '@features/pt-forecast-new/stores/filters';
import { RegionOptionItem } from '@features/pt-forecast-new/stores/lists/regionOptions';
import { $UI } from '@features/pt-forecast-new/stores/ui';

export const DirectionPoints = () => {
  const { fromSelectedRegion, toSelectedRegion } = useUnit($FilterMap);
  const { setRegionFrom, setRegionTo, setDirectionPoint } =
    useUnit(FilterMapApi);
  const { isSetPoint, showDashboard, drawGraph, placeRailwayStation } =
    useUnit($UI);

  const onSelectFrom = useCallback(
    (option: RegionOptionItem) => {
      setRegionFrom(option);
    },
    [setRegionFrom],
  );

  const onSelectTo = useCallback(
    (option: RegionOptionItem) => {
      setRegionTo(option);
    },
    [setRegionTo],
  );

  const onClearRegions = useCallback(() => {
    setRegionFrom(null);
    setRegionTo(null);
  }, [setRegionFrom, setRegionTo]);

  const toggleIsSetPoint = useCallback(
    (directions: directionPoints) => {
      if (isSetPoint) setDirectionPoint(null);
      else setDirectionPoint(directions);
    },
    [isSetPoint, setDirectionPoint],
  );

  return (
    <AccordionFilter title={'Между транспортными районами'}>
      <Grid
        container
        spacing={2}
        flexDirection={'column'}
      >
        <DirectionPointField
          onSetPoint={() => toggleIsSetPoint(directionPoints.from)}
          placeholder={'А'}
          value={fromSelectedRegion}
          disabled={isSetPoint || drawGraph || placeRailwayStation}
          onClearSelectedOption={onClearRegions}
          onSelectOption={onSelectFrom}
          disabledOnlyPoints={showDashboard}
        />
        <DirectionPointField
          onSetPoint={() => toggleIsSetPoint(directionPoints.to)}
          placeholder={'Б'}
          value={toSelectedRegion}
          disabled={isSetPoint || drawGraph || placeRailwayStation}
          onClearSelectedOption={onClearRegions}
          onSelectOption={onSelectTo}
          disabledOnlyPoints={showDashboard}
        />
      </Grid>
    </AccordionFilter>
  );
};
