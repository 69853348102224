import {sample} from "effector";
import {$UI, UIApi} from "@features/pt-forecast-new/stores/ui";
import {
  $EditorMap, EditorMapApi, EditorMapStore, getGraphItemsFx, updateGraphItemFx,
  updateStationFx
} from "@features/pt-forecast-new/stores/map/editor";
import './customRailwayStation'
import './customRailwayGraph'
import {
  IMutationUpdateGraphItemArgs,
  IMutationUpdateStationArgs
} from "@api/gql/types";
import {
  mutationUpdateStation
} from "@features/pt-forecast-new/stores/api/mutationUpdateStation";
import {
  mutationUpdateGraph
} from "@features/pt-forecast-new/stores/api/mutationUpdateGraph";
import {
  queryGetScenarioGraph
} from "@features/pt-forecast-new/stores/api/queryGetScenarioGraph";

// Закрытие инспектора кастомной инфраструктуры
const closeInspectorCustomInfrastructure = sample({
  clock: UIApi.setVisibleInspector,
  source: {UI: $UI, EditorMap: $EditorMap},
  filter: ({UI}, payload) => UI.editMode && !payload,
})

let targetStation = null
// Если поля были изменены
sample({
  clock: closeInspectorCustomInfrastructure,
  source: {EditorMap: $EditorMap},
  filter: ({EditorMap}) => {
    if (!EditorMap.selectedStation) return false
    targetStation = EditorMap.stations.find(({id}) => id === EditorMap.selectedStation)
    return !!targetStation?.isUpdated;
  },
  fn: () => {
    return {
      name: targetStation!.name,
      stationId: targetStation!.id,
    } as IMutationUpdateStationArgs
  },
  target: updateStationFx
})

updateStationFx.use(mutationUpdateStation);

// параметры станции успешно обновились
sample({
  clock: updateStationFx.done,
  fn: () => false,
  target: [EditorMapApi.setIsUpdatedStation]
})

let targetGraph = null
// Если поля были изменены
sample({
  clock: closeInspectorCustomInfrastructure,
  source: {EditorMap: $EditorMap},
  filter: ({EditorMap}) => {
    if (!EditorMap.selectedGraph) return false
    targetGraph = EditorMap.graphs.find(({id}) => id === EditorMap.selectedGraph)
    return !!targetGraph?.isUpdated;
  },
  fn: () => {
    return {
      name: targetGraph!.name,
      year: targetGraph!.year,
      graphItemId: targetGraph!.id,
    } as IMutationUpdateGraphItemArgs
  },
  target: updateGraphItemFx
})

updateGraphItemFx.use(mutationUpdateGraph)

// параметры графа успешно обновились
sample({
  clock: updateGraphItemFx.done,
  fn: () => false,
  target: [EditorMapApi.setIsUpdatedGraph]
})

getGraphItemsFx.use(queryGetScenarioGraph)

// парсинг пользовательской геометрии
sample({
  clock: getGraphItemsFx.done,
  fn: (request) => {
    return {
      graphs: request.result?.scenarioVertices
        .filter(graph => graph.graphScenario.length)
        .map(graph => ({
          id: graph.graphScenario[0].id || '',
          name: graph.graphScenario[0].name || '',
          infrastructureType: graph.graphScenario[0].type || 'A_3',
          maxSpeed: 0,
          yearOfCommissioning: 0,
          startStationId: graph.graphScenario[0].startVertex?.id || '',
          endStationId: graph.graphScenario[0].endVertex?.id || '',
          intermediateStations: [] as string[],
          geometry: graph.graphScenario[0].geometry?.coordinates[0].map((coords: number[]) => ({
            lon: coords[1],
            lat: coords[0],
          })),
          isSaved: true,
          isUpdated: false,
          delete: false
        })),
      stations: request.result?.scenarioStations.map(station => ({
        id: station.id || '',
        name: station.name || '',
        transportArea: station.transportRegion?.id || '',
        geometry: {
          lon: station.vertex.theGeom.coordinates[0],
          lat: station.vertex.theGeom.coordinates[1],
        },
        vertexId: station.vertex.id || '',
        isSaved: true,
        isUpdated: false,
        delete: false
      }))
    } as Pick<EditorMapStore, 'graphs' | 'stations'>
  },
  target: EditorMapApi.setInfrastructure
})
