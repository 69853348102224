import { SOCECO_BLOCK_IDS } from '@widgets/widget-soceco-indicators/constants/tables/blockIds';
import { SOCECO_TABLE_IDS } from '@widgets/widget-soceco-indicators/constants/tables/tableIds';

export const SOCECO_TABLES_BLOCKS = {
  [SOCECO_BLOCK_IDS.BLOCK1]: [
    SOCECO_TABLE_IDS.TABLE_1_1,
    SOCECO_TABLE_IDS.TABLE_1_2,
    SOCECO_TABLE_IDS.TABLE_1_3,
    SOCECO_TABLE_IDS.TABLE_1_4,
    SOCECO_TABLE_IDS.TABLE_1_5,
  ],
  [SOCECO_BLOCK_IDS.BLOCK2]: [
    SOCECO_TABLE_IDS.TABLE_2_1,
    // SOCECO_TABLE_IDS.TABLE_2_2,
    SOCECO_TABLE_IDS.TABLE_2_3,
    SOCECO_TABLE_IDS.TABLE_2_4,
    SOCECO_TABLE_IDS.TABLE_2_5,
  ],
  [SOCECO_BLOCK_IDS.BLOCK3]: [
    SOCECO_TABLE_IDS.TABLE_3_1,
    SOCECO_TABLE_IDS.TABLE_3_2,
    SOCECO_TABLE_IDS.TABLE_3_3,
    SOCECO_TABLE_IDS.TABLE_3_4,
    SOCECO_TABLE_IDS.TABLE_3_5,
    SOCECO_TABLE_IDS.TABLE_3_6,
    SOCECO_TABLE_IDS.TABLE_3_7,
  ],
  [SOCECO_BLOCK_IDS.BLOCK4]: [
    SOCECO_TABLE_IDS.TABLE_4_1,
    SOCECO_TABLE_IDS.TABLE_4_2,
    SOCECO_TABLE_IDS.TABLE_4_3,
    SOCECO_TABLE_IDS.TABLE_4_4,
  ],
  [SOCECO_BLOCK_IDS.BLOCK5]: [
    SOCECO_TABLE_IDS.TABLE_5_1,
    SOCECO_TABLE_IDS.TABLE_5_2,
    SOCECO_TABLE_IDS.TABLE_5_3,
    SOCECO_TABLE_IDS.TABLE_5_4,
    SOCECO_TABLE_IDS.TABLE_5_5,
    SOCECO_TABLE_IDS.TABLE_5_6,
    SOCECO_TABLE_IDS.TABLE_5_7,
    SOCECO_TABLE_IDS.TABLE_5_8,
  ],
  [SOCECO_BLOCK_IDS.BLOCK6]: [
    SOCECO_TABLE_IDS.TABLE_6_1,
    SOCECO_TABLE_IDS.TABLE_6_2,
    SOCECO_TABLE_IDS.TABLE_6_3,
  ],
  [SOCECO_BLOCK_IDS.BLOCK7]: [
    SOCECO_TABLE_IDS.TABLE_7_1,
    SOCECO_TABLE_IDS.TABLE_7_2,
    SOCECO_TABLE_IDS.TABLE_7_3,
    SOCECO_TABLE_IDS.TABLE_7_4,
    SOCECO_TABLE_IDS.TABLE_7_5,
  ],
};
