import { apiClient } from '@api/client';
import {
  IQuery,
  IQueryGetComputedPassTrafficArgs,
  IQueryGetComputingRegistryInstanceByIdArgs,
} from '@api/gql/types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { ECalculateStatus } from '@features/pt-forecast-new/constants/ECalculateStatus';
import { $FilterMap } from '@features/pt-forecast-new/stores/filters';
import { getComputedResultsFx } from '@features/pt-forecast-new/stores/lists/computed';
import {
  $FeatureSettings,
  FeatureSettingsApi,
  FeatureSettingsStore,
  getScenarioComputedStatusFx,
  getScenarioDetailsFx,
} from '@features/pt-forecast-new/stores/settings/store';
import { UIApi } from '@features/pt-forecast-new/stores/ui';

// Как только получен идентефикатор сценария, запросить более подробную информацию по этому сценарию и его статус
sample({
  clock: FeatureSettingsApi.setScenarioId,
  filter: payload => !!payload,
  fn: payload => {
    return {
      id: payload!,
    } as IQueryGetComputingRegistryInstanceByIdArgs;
  },
  target: [getScenarioDetailsFx, getScenarioComputedStatusFx],
});

getScenarioDetailsFx.use(async params => {
  const result = await apiClient.query<
    Pick<IQuery, 'getComputingRegistryInstanceById'>,
    IQueryGetComputingRegistryInstanceByIdArgs
  >({
    query: gql`
      query QueryGetComputingRegistryInstanceById($id: String) {
        getComputingRegistryInstanceById(id: $id) {
          id
          name
          socEcoScenario {
            gdpGrowthRate
            growthRateInTheNumberOfPlacements
            growthRateOfMotorization
            id
            name
            populationGrowthRate
            scenarioType
            year
            jobGrowthRate
          }
          transportScenario {
            id
            name
            scenarioType
            transportProjects {
              id
              year
              transportProject {
                name
              }
            }
          }
        }
      }
    `,
    variables: {
      id: params.id,
    },
  });
  return result.data.getComputingRegistryInstanceById;
});

// Реформаттинг и запись результата в стор
sample({
  clock: getScenarioDetailsFx.done,
  fn: request => {
    return {
      name: request.result?.name || '',
      socEcoScenario: request.result?.socEcoScenario || null,
      transportScenario: request.result?.transportScenario || null,
    } as FeatureSettingsStore['scenarioDetails'];
  },
  target: FeatureSettingsApi.setScenarioDetails,
});

getScenarioComputedStatusFx.use(async params => {
  const result = await apiClient.query<
    Pick<IQuery, 'getComputingRegistryInstanceById'>,
    IQueryGetComputingRegistryInstanceByIdArgs
  >({
    query: gql`
      query QueryGetComputingRegistryInstanceById($id: String) {
        getComputingRegistryInstanceById(id: $id) {
          resultStatus
        }
      }
    `,
    variables: {
      id: params.id,
    },
  });
  params.isPending && (await new Promise(resolve => setTimeout(resolve, 2000)));

  return result.data.getComputingRegistryInstanceById;
});

// Обновление стейта статуса расчета в сторе
sample({
  clock: getScenarioComputedStatusFx.done,
  fn: request => {
    return (request.result?.resultStatus ||
      ECalculateStatus.FAILURE) as unknown as ECalculateStatus;
  },
  target: FeatureSettingsApi.setScenarioCalculateStatus,
});

// Блокировка ползунков панели пересчета
sample({
  clock: getScenarioComputedStatusFx.done,
  fn: request => {
    const status = request.result?.resultStatus as unknown as ECalculateStatus;

    switch (status) {
      case ECalculateStatus.SUCCESS:
      case ECalculateStatus.DRAFT:
      case ECalculateStatus.FAILURE:
        return false;
      default:
        return true;
    }
  },
  target: UIApi.setDisabledRecalculation,
});

// если статус сценария не успешно и не ошибка, запросить еще раз
sample({
  clock: getScenarioComputedStatusFx.done,
  filter: request =>
    ![ECalculateStatus.SUCCESS, ECalculateStatus.FAILURE].includes(
      request.result!.resultStatus! as unknown as ECalculateStatus,
    ),
  fn: request => {
    return {
      ...request.params,
      isPending: true,
    };
  },
  target: getScenarioComputedStatusFx,
});

// Если статус расчета изменился на - успешно, и выбраны - регион отправления и регион прибытия, перезапросить корреспонденцию
sample({
  clock: getScenarioComputedStatusFx.done,
  source: { FilterMap: $FilterMap, FeatureSettings: $FeatureSettings },
  filter: ({ FilterMap }, request) =>
    (request.result!.resultStatus as unknown as ECalculateStatus) ===
      ECalculateStatus.SUCCESS &&
    !!FilterMap.fromSelectedRegion &&
    !!FilterMap.toSelectedRegion,
  fn: ({ FilterMap, FeatureSettings }) => {
    return {
      computingRegistryId: FeatureSettings.scenarioId,
      regionFrom: FilterMap.fromSelectedRegion!.id,
      regionTo: FilterMap.toSelectedRegion!.id,
    } as IQueryGetComputedPassTrafficArgs;
  },
  target: getComputedResultsFx,
});
