import { useUnit } from 'effector-react';
import { useCallback, useMemo } from 'react';

import { IconButton, Stack, Typography } from '@mui/material';

import { ChevronRightIcon } from '@components/icons/ChevronRight';

import { CashedField } from '@features/pt-forecast-new/components/Inspector/CashedField';
import {
  $EditorMap,
  EditorMapApi,
} from '@features/pt-forecast-new/stores/map/editor';
import { $UI, UIApi } from '@features/pt-forecast-new/stores/ui';

export const InspectorCustomRailwayStation = () => {
  const { selectedStation, stations } = useUnit($EditorMap);
  const { updateSelectedStationParams } = useUnit(EditorMapApi);
  const { editMode } = useUnit($UI);
  const { setVisibleInspector } = useUnit(UIApi);

  const selectedStationItem = useMemo(() => {
    return stations.find(station => station.id === selectedStation);
  }, [selectedStation, stations]);

  const changeNameHandler = useCallback(
    (value: string) => {
      // updateStationFx({
      //   name: value,
      //   stationId: selectedStationItem?.id,
      // });
      updateSelectedStationParams({ name: value });
    },
    [selectedStationItem?.id, updateSelectedStationParams],
  );

  const onClose = useCallback(() => {
    setVisibleInspector(false);
  }, [setVisibleInspector]);

  if (!selectedStation || !selectedStationItem) return null;
  return (
    <Stack
      height="100%"
      direction="column"
      justifyContent="space-between"
    >
      <Stack>
        <Stack
          alignItems={'center'}
          flexDirection={'row'}
          mb={3}
          columnGap={2}
        >
          <IconButton
            sx={{
              width: '32px',
              height: '32px',
              backgroundColor: 'primary.main',
              borderRadius: 1,
              color: 'white',
              '&:hover': {
                backgroundColor: 'primary.dark',
              },
            }}
            onClick={onClose}
          >
            <ChevronRightIcon />
          </IconButton>

          <Typography variant="subtitle2">Железнодорожная станция</Typography>
        </Stack>

        <Stack rowGap={3}>
          <CashedField
            id="tpuName"
            title="Наименование"
            placeholder="Введите название станции"
            value={selectedStationItem.name}
            onBlur={value => changeNameHandler(value as string)}
            disabled={!editMode}
          />

          <CashedField
            id="transportArea"
            title="Транспортный район"
            placeholder="Название ТР заполнится автоматически"
            value={selectedStationItem.transportArea}
            disabled
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
