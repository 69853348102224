import { Event } from 'effector';
import { Feature, MapBrowserEvent, Map as MapOl } from 'ol';
import { FeatureLike } from 'ol/Feature';
import { LineString, Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat } from 'ol/proj';
import RenderFeature from 'ol/render/Feature';
import VectorSource from 'ol/source/Vector';
import { getDistance } from 'ol/sphere';
import { RefObject, useEffect, useRef } from 'react';

import { EMapFeatureLayout } from '@constants/map';

import { tpuGraphsVectorStyle } from '@utils/map/styles/TPUGraphsVectorStyle';

export enum EPipeMapFollowPath {
  startFollow,
  stopFollow,
  requestPointCoordinate,
  updateTrackedLayers,
}

export type PipeMapFollowPath =
  | {
      action: EPipeMapFollowPath.startFollow;
      payload: {
        coords: number[];
      };
    }
  | {
      action: EPipeMapFollowPath.stopFollow;
      payload: null;
    }
  | {
      action: EPipeMapFollowPath.requestPointCoordinate;
      payload: {
        cb: (args: RequestPointCoordinateCbArgs | null) => void;
      };
    }
  | {
      action: EPipeMapFollowPath.updateTrackedLayers;
      payload: {
        layers: EMapFeatureLayout[];
      };
    };

type RequestPointCoordinateCbArgs = {
  coords: number[];
  featureUUID: string;
};

export type UseMapFollowPathProps = {
  mapRef: RefObject<MapOl | null>;
  event: Event<PipeMapFollowPath>;
  zIndex: number;
};

export const useMapFollowPath = (props: UseMapFollowPathProps) => {
  const { mapRef, event, zIndex } = props;
  // Временный слой для трассировки визуализации построения
  const tempLayerRef = useRef<VectorLayer<VectorSource> | null>();
  // Флаг прослушивания перемещения курсора
  const isPointerMoveListenRef = useRef<boolean>(false);
  // Последнее событие перемещения курсора
  const pointerEventRef = useRef<MapBrowserEvent<MouseEvent> | null>(null);
  // отправная точка для построения отрезка
  const firstPointRef = useRef<number[] | null>(null);
  // Координаты конечной точки отрезка если есть пересечение с графом
  const confluencePointRef = useRef<RequestPointCoordinateCbArgs | null>(null);
  // Координаты конечной точки отрезка если есть пересечение с графом в момент клика
  const clickShotRef = useRef<RequestPointCoordinateCbArgs | null>(null);
  // Источник временного слоя визуализации построения
  const tempLayerSourceRef = useRef<VectorSource | null>();
  // Слои разрешенные для прилипания
  const allowedLayers = useRef<EMapFeatureLayout[]>([
    EMapFeatureLayout.graphAuto,
  ]);

  // Перерендер временного слоя, для рисования линии между двумя точками
  const updateLayer = () => {
    tempLayerSourceRef.current!.clear();
    if (
      firstPointRef.current &&
      (confluencePointRef.current || pointerEventRef!.current!.coordinate!)
    ) {
      tempLayerSourceRef.current!.addFeature(
        new Feature({
          geometry: new LineString([
            firstPointRef.current,
            pointerEventRef.current!.originalEvent.shiftKey
              ? pointerEventRef!.current!.coordinate!
              : confluencePointRef.current?.coords ||
                pointerEventRef!.current!.coordinate!,
          ]),
        }),
      );
      if (
        confluencePointRef.current &&
        !pointerEventRef.current!.originalEvent.shiftKey
      ) {
        tempLayerSourceRef.current!.addFeature(
          new Feature({
            geometry: new Point(confluencePointRef.current.coords),
          }),
        );
      }
    }
    mapRef.current?.render();
  };

  // Вызывается каждый кадр если рисуется линия
  const frameHandler = () => {
    if (pointerEventRef.current!.originalEvent.shiftKey) {
      updateLayer();
      return;
    }

    // Получение текущего уровня зума
    const zoomLevel = mapRef!.current!.getView().getZoom()!;

    const pixel = mapRef.current!.getEventPixel(
      pointerEventRef.current!.originalEvent,
    );
    const features: FeatureLike[] = [];
    mapRef.current!.forEachFeatureAtPixel(
      pixel,
      feature => {
        features.push(feature);
      },
      {
        layerFilter: layer =>
          allowedLayers.current.includes(layer.get('layout')),
        hitTolerance: calculateDistanceByZoom(zoomLevel, 3, 50),
      },
    );

    tempLayerSourceRef.current!.clear();

    const coords: number[][] = [];

    const feature = features[0] as RenderFeature;
    if (feature) {
      const flatCoords = feature.getFlatCoordinates();
      flatCoords.forEach((_, index) => {
        if (index % 2 === 0)
          coords.push([flatCoords[index], flatCoords[index + 1]]);
      });

      const virtualFeature = new Feature(new LineString(coords));

      const lineString = virtualFeature.getGeometry();
      const closestPoint = lineString?.getClosestPoint(
        pointerEventRef!.current!.coordinate!,
      );
      const distance = getDistance(
        pointerEventRef!.current!.coordinate!,
        closestPoint!,
      );

      const distanceThreshold = calculateDistanceByZoom(
        zoomLevel,
        100000,
        500000000,
      );

      if (distance < distanceThreshold) {
        confluencePointRef.current = {
          coords: closestPoint!,
          featureUUID: feature.get('id'),
        };
      } else {
        confluencePointRef.current = null;
      }
    } else {
      confluencePointRef.current = null;
    }
    updateLayer();
  };

  // Синхронизировать перемещения курсора с фреймрейтом
  const pointerMoveHandlerCb = (event: MapBrowserEvent<MouseEvent>) => {
    pointerEventRef.current = event;
    requestAnimationFrame(frameHandler);
  };

  // Проинициализировать временный слой
  const initTempLayer = () => {
    tempLayerSourceRef.current = new VectorSource();
    tempLayerRef.current = new VectorLayer({
      source: tempLayerSourceRef.current,
      zIndex: zIndex,
      style: (feature, resolution) => {
        return tpuGraphsVectorStyle({
          feature,
          resolution,
          map: mapRef.current!,
        });
      },
    });
    mapRef.current!.addLayer(tempLayerRef.current);
  };

  const mapClickHandler = () => {
    clickShotRef.current = confluencePointRef.current;
  };

  // Подписаться на перемещение курсора
  const initPointerMoveListen = () => {
    mapRef.current!.on('pointermove', pointerMoveHandlerCb);
    mapRef.current!.on('click', mapClickHandler);
    isPointerMoveListenRef.current = true;
  };

  // Запускает слежение за курсором линией
  const startFollowListener = (coordsStart: number[]) => {
    confluencePointRef.current = null;

    // Если временный слой еще не был проинициализирован
    if (!tempLayerRef.current) {
      initTempLayer();
    }
    // Если перемещение курсора не прослушивается
    if (!isPointerMoveListenRef.current) {
      initPointerMoveListen();
    }
    firstPointRef.current = fromLonLat(coordsStart);
  };

  const stopFollowListener = () => {
    mapRef.current!.un('pointermove', pointerMoveHandlerCb);
    mapRef.current!.un('click', mapClickHandler);
    isPointerMoveListenRef.current = false;
    firstPointRef.current = null;
    if (tempLayerRef.current) {
      mapRef.current!.removeLayer(tempLayerRef.current);
      tempLayerRef.current = null;
    }
  };

  // Возвращает координаты конечной точки линии в момент клика
  const requestPointCoordinateListener = (
    cb: (args: RequestPointCoordinateCbArgs | null) => void,
  ) => {
    if (
      clickShotRef.current &&
      !pointerEventRef.current!.originalEvent.shiftKey
    ) {
      cb({
        coords: clickShotRef.current.coords,
        featureUUID: clickShotRef.current.featureUUID,
      });
    } else cb(null);
  };

  const updateTrackedLayers = (layers: EMapFeatureLayout[]) => {
    allowedLayers.current = layers;
  };

  const listenerSwitcher = (args: PipeMapFollowPath) => {
    switch (args.action) {
      case EPipeMapFollowPath.startFollow:
        startFollowListener(args.payload.coords);
        break;
      case EPipeMapFollowPath.requestPointCoordinate:
        requestPointCoordinateListener(args.payload.cb);
        break;
      case EPipeMapFollowPath.stopFollow:
        stopFollowListener();
        break;
      case EPipeMapFollowPath.updateTrackedLayers:
        updateTrackedLayers(args.payload.layers);
        break;
      default:
        throw new Error('Unknown action');
    }
  };

  useEffect(() => {
    const subscriber = event.watch(listenerSwitcher);

    return () => {
      subscriber.unsubscribe();
      stopFollowListener();
    };
  }, []);
};

// Функция для расчета дистанции на основе зума
function calculateDistanceByZoom(
  zoomLevel: number,
  minDistance: number,
  maxDistance: number,
  minZoom = 12,
  maxZoom = 20,
) {
  // Нормализация уровня зума
  const normalizedZoom =
    ((zoomLevel - minZoom) / (maxZoom - minZoom) - 0.5) * 12;

  // Применение сигмоидальной функции
  const sigmoid = 1 / (1 + Math.exp(-normalizedZoom));

  // Расчет дистанции
  return minDistance + (maxDistance - minDistance) * sigmoid;
}
