import { apiClient } from '@api/client';
import { IQuery } from '@api/gql/types';
import { gql } from '@apollo/client';

export type QueryGetSocEcoAndTransportScenariosResponse = Pick<
  IQuery,
  'transportScenarioInstances' | 'socEcoScenarioInstances'
>;

export const queryGetSocEcoAndTransportScenarios = async () => {
  const response =
    await apiClient.query<QueryGetSocEcoAndTransportScenariosResponse>({
      query: gql`
        query QueryGetSocEcoAndTransportScenarios {
          transportScenarioInstances {
            name
            id
            scenarioType
            transportProjects {
              id
              year
              transportProject {
                name
              }
            }
          }
          socEcoScenarioInstances {
            id
            name
            jobGrowthRate
            growthRateOfMotorization
            growthRateInTheNumberOfPlacements
            gdpGrowthRate
            populationGrowthRate
            scenarioType
            computed
          }
        }
      `,
    });

  return response.data;
};
