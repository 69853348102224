import { ButtonPagination } from '@features/ag-projects-registry/components/TableProjects/ButtonPagination';
import React from 'react';

import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';

import { ChevronLeftIcon, ChevronRightIcon } from '@components/icons';

type TableProjectPaginationActionsProps = TablePaginationActionsProps & {
  rowsPerPage: number;
};

export const TableProjectPaginationActions = (
  props: TableProjectPaginationActionsProps,
) => {
  const { onPageChange, page, rowsPerPage, count } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div
      style={{
        flexShrink: 0,
        marginLeft: 20,
        columnGap: '8px',
        display: 'flex',
      }}
    >
      {page > 0 && (
        <ButtonPagination
          isSelected={false}
          onClick={handleFirstPageButtonClick}
        >
          <ChevronLeftIcon />
        </ButtonPagination>
      )}

      {page > 0 && (
        <ButtonPagination
          isSelected={false}
          onClick={handleBackButtonClick}
        >
          {page}
        </ButtonPagination>
      )}

      <ButtonPagination
        isSelected={true}
        onClick={() => {}}
      >
        {page + 1}
      </ButtonPagination>

      {page < Math.ceil(count / rowsPerPage) - 1 && (
        <ButtonPagination
          isSelected={false}
          onClick={handleNextButtonClick}
        >
          {page + 2}
        </ButtonPagination>
      )}

      {page < Math.ceil(count / rowsPerPage) - 1 && (
        <ButtonPagination
          isSelected={false}
          onClick={handleLastPageButtonClick}
        >
          <ChevronRightIcon />
        </ButtonPagination>
      )}
    </div>
  );
};
