import { apiClient } from '@api/client';
import {
  IMutation,
  IMutationCreateGraphArgs,
  IQuery,
  IQueryGetClosestInstancePointByPointArgs,
} from '@api/gql/ag-types';
import { gql } from '@apollo/client';
import { createEvent, sample } from 'effector';
import { toLonLat } from 'ol/proj';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';
import { EMapFeatureLayout } from '@constants/map';

import { $FilterMap, FilterMapApi } from '@features/ag-forecast/stores/filters';
import {
  $SelectedFeatures,
  clickMap,
  pipeMapFollowPath,
} from '@features/ag-forecast/stores/map';
import {
  $EditorMap,
  EditorMapApi,
  SetStartPointPayload,
  TpuGraphItem,
  createGraphFx,
  getClosestInstancePointByPointFx,
} from '@features/ag-forecast/stores/map/editor/store';
import { $FeatureSettings } from '@features/ag-forecast/stores/settings';
import { $UI, UIApi } from '@features/ag-forecast/stores/ui';
import {
  EPipeMapFollowPath,
  PipeMapFollowPath,
} from '@features/ag-forecast/utils/useMapFollowPath';
import {
  layerIdKey,
  layerLayoutKey,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox';

import { getFeatureAtPixel } from '@utils/map/tools/getFeatureAtPixel';

// Определить тпу для построения графа
sample({
  clock: clickMap,
  source: { UI: $UI, FeatureSettings: $FeatureSettings, EditorMap: $EditorMap },
  // Если режим построения графов, и начальная точка еще не определена
  filter: ({ UI, EditorMap }) => UI.drawGraph && EditorMap.drawGraph === null,
  fn: ({ EditorMap }, event) => {
    if (!EditorMap.tpus?.length) throw new Error('No TPU');

    const featureAtPixel = getFeatureAtPixel({
      map: event.map,
      pixel: event.pixel,
      includeLayouts: [EMapFeatureLayout.tpu],
      hitTolerance: 1,
    });

    if (!featureAtPixel) return null;
    const id = featureAtPixel.get(layerIdKey);
    const tpu = EditorMap.tpus.find(item => item.id === String(id));
    if (!tpu) return null;

    return {
      startPointId: id,
      node: [tpu.geometry.lon, tpu.geometry.lat],
    } as SetStartPointPayload;
  },
  target: EditorMapApi.setStartPoint,
});

type addGraphNodeMiddleWareEventType = {
  coords: number[];
  featureUUID?: string;
  isNeedCalibrate: boolean;
};

const addGraphNodeMiddleWareEvent =
  createEvent<addGraphNodeMiddleWareEventType | null>();

// Указание узлов нового графа
sample({
  clock: clickMap,
  source: { UI: $UI, FeatureSettings: $FeatureSettings, EditorMap: $EditorMap },
  // Если режим построения графов, и начальная точка уже определена
  filter: ({ UI, EditorMap }) => UI.drawGraph && EditorMap.drawGraph !== null,
  fn: (_, event) => {
    pipeMapFollowPath({
      action: EPipeMapFollowPath.stopFollow,
      payload: null,
    });

    const featureAtPixel = getFeatureAtPixel({
      map: event.map,
      pixel: event.pixel,
      includeLayouts: [EMapFeatureLayout.tpu],
      hitTolerance: 1,
    });
    if (featureAtPixel?.get(layerLayoutKey) === EMapFeatureLayout.tpu)
      return null;

    let targetCoords: number[] | null = null;
    let targetFeatureUUID: string | null = null;

    pipeMapFollowPath({
      action: EPipeMapFollowPath.requestPointCoordinate,
      payload: {
        cb: args => {
          if (args) {
            targetCoords = args.coords;
            targetFeatureUUID = args.featureUUID;
          }
        },
      },
    });

    if (targetCoords && targetFeatureUUID) {
      return {
        coords: toLonLat(targetCoords),
        featureUUID: targetFeatureUUID,
        isNeedCalibrate: true,
      } as addGraphNodeMiddleWareEventType;
    }

    return {
      coords: toLonLat(event.target.getCoordinateFromPixel(event.pixel)),
      isNeedCalibrate: false,
    } as addGraphNodeMiddleWareEventType;
  },
  target: addGraphNodeMiddleWareEvent,
});

// если не требуется слияние с другой геометрией просто добавляем точку
sample({
  clock: addGraphNodeMiddleWareEvent,
  filter: payload => !!payload && !payload.isNeedCalibrate,
  fn: payload => payload!.coords,
  target: EditorMapApi.addGraphNode,
});

// если требуется слияние с другой геометрией запрашиваем точные координаты пересечения
sample({
  clock: addGraphNodeMiddleWareEvent,
  filter: payload => !!payload && payload.isNeedCalibrate,
  fn: payload => {
    return {
      instanceId: payload!.featureUUID!,
      lon: payload!.coords[0],
      lat: payload!.coords[1],
    } as IQueryGetClosestInstancePointByPointArgs;
  },
  target: getClosestInstancePointByPointFx,
});

getClosestInstancePointByPointFx.use(async params => {
  const response = await apiClient.query<
    Pick<IQuery, 'getClosestInstancePointByPoint'>,
    IQueryGetClosestInstancePointByPointArgs
  >({
    query: gql`
      query QueryGetClosestInstancePointByPoint(
        $lon: Float!
        $lat: Float!
        $instanceId: UUID!
      ) {
        getClosestInstancePointByPoint(
          lon: $lon
          lat: $lat
          instanceId: $instanceId
        ) {
          point {
            lon
            lat
          }
        }
      }
    `,
    variables: {
      lon: params.lon,
      lat: params.lat,
      instanceId: params.instanceId,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });

  return response.data?.getClosestInstancePointByPoint || null;
});

// Добавляем точку кривой по точным координатам пересечения с другой геометрией
sample({
  clock: getClosestInstancePointByPointFx.done,
  filter: request => !!request.result,
  fn: request => {
    return [request.result!.point!.lon!, request.result!.point!.lat!];
  },
  target: EditorMapApi.addGraphNode,
});

// Если бэк успешно нашел координаты пересечения, завершить построение графа
sample({
  clock: getClosestInstancePointByPointFx.done,
  filter: request => !!request.result,
  fn: () => false,
  target: UIApi.setCreateGraph,
});

// Если пользователь начал строить граф, пнуть утилку для визуализации построения графа и передать ей координаты последней ноды
sample({
  clock: [EditorMapApi.addGraphNode, EditorMapApi.setStartPoint],
  source: { EditorMap: $EditorMap },
  filter: ({ EditorMap }) => !!EditorMap.drawGraph?.nodes?.length,
  fn: ({ EditorMap }) => {
    return {
      action: EPipeMapFollowPath.startFollow,
      payload: {
        coords:
          EditorMap.drawGraph!.nodes[EditorMap.drawGraph!.nodes.length - 1],
      },
    } as PipeMapFollowPath;
  },
  target: pipeMapFollowPath,
});

// Если пользователь отменил построение графа
sample({
  clock: [UIApi.toggleDrawGraph, UIApi.setCreateGraph],
  source: { UI: $UI, EditorMap: $EditorMap, FeatureSettings: $FeatureSettings },
  filter: ({ UI, EditorMap }) =>
    !UI.drawGraph &&
    !!EditorMap.drawGraph &&
    EditorMap.drawGraph.nodes.length < 2,
  fn: () => {
    pipeMapFollowPath({
      action: EPipeMapFollowPath.stopFollow,
      payload: null,
    });
  },
  target: EditorMapApi.clearDrawGraph,
});

// По завершении строительства графа, отправить его на бэк
sample({
  clock: [UIApi.toggleDrawGraph, UIApi.setCreateGraph],
  source: { UI: $UI, EditorMap: $EditorMap, FeatureSettings: $FeatureSettings },
  filter: ({ UI, EditorMap }) =>
    !UI.drawGraph &&
    !!EditorMap.drawGraph &&
    EditorMap.drawGraph.nodes.length > 1,
  fn: ({ EditorMap, FeatureSettings }) => {
    pipeMapFollowPath({
      action: EPipeMapFollowPath.stopFollow,
      payload: null,
    });

    return {
      geometry: {
        coordinates: EditorMap.drawGraph?.nodes.map(node => ({
          lat: node[1],
          lon: node[0],
        })),
      },
      scenarioId: FeatureSettings.scenarioUUID || '',
      tpuId: EditorMap.drawGraph?.startPointId,
    } as IMutationCreateGraphArgs;
  },
  target: createGraphFx,
});

createGraphFx.use(async params => {
  const response = await apiClient.mutate<
    Pick<IMutation, 'createGraph'>,
    IMutationCreateGraphArgs
  >({
    mutation: gql`
      mutation MutationCreateGraph(
        $geometry: MultiLineStringInput
        $scenarioId: UUID!
        $tpuId: UUID!
      ) {
        createGraph(
          geometry: $geometry
          scenarioId: $scenarioId
          tpuId: $tpuId
        ) {
          scenarioGraph {
            id
          }
        }
      }
    `,
    variables: {
      geometry: params.geometry,
      scenarioId: params.scenarioId,
      tpuId: params.tpuId,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });
  return response.data?.createGraph || null;
});

// Переместить построенный граф из временного хранилища в постоянное //TODO: Получать с бэка
sample({
  clock: createGraphFx.done,
  fn: request => {
    return {
      id: request.result?.scenarioGraph?.id,
      name: request.result?.scenarioGraph?.name,
      tpuId: request.params.tpuId,
      geometry: request.params.geometry?.coordinates,
      vehicles: [],
      capacityHour: 0,
      frequencyWeekend: 0,
      freeSpeed: 0,
      frequencyWeekdays: 0,
      capacity: 0,
      category: '',
      cost: 0,
      laneNum: 0,
      oneway: false,
      parkingCo: 0,
      separated: '',
      year: 0,
      zone: 0,
      isSaved: false,
      isUpdated: true,
      delete: false,
    } as TpuGraphItem;
  },
  target: EditorMapApi.addGraph,
});

///////////////////////////////////////////////////////////////////////////////

// // Получить параметры выбранного графа тпу
sample({
  clock: $SelectedFeatures,
  source: { $selectedFeatures: $SelectedFeatures },
  filter: ({ $selectedFeatures }) =>
    $selectedFeatures[0]?.layout === EMapFeatureLayout.tpuGraphs,
  fn: ({ $selectedFeatures }) => {
    return $selectedFeatures[0]?.id || null;
  },
  target: EditorMapApi.selectTpuGraph,
});

// Слежение за изменением фильтров, и актуализация списка контроллера визуализации построения графа
sample({
  clock: [FilterMapApi.setFilters, EditorMapApi.setStartPoint],
  source: { Filters: $FilterMap },
  fn: ({ Filters }) => {
    const layers = [];
    if (Filters.actual.graphAuto) layers.push(EMapFeatureLayout.graphAuto);
    if (Filters.actual.graphAvia) layers.push(EMapFeatureLayout.graphAvia);
    if (Filters.actual.graphBus) layers.push(EMapFeatureLayout.graphBus);
    if (Filters.actual.graphMetro) layers.push(EMapFeatureLayout.graphMetro);
    if (Filters.actual.graphFunicular)
      layers.push(EMapFeatureLayout.graphFunicular);
    if (Filters.actual.graphTram) layers.push(EMapFeatureLayout.graphTram);
    if (Filters.actual.graphTrolleybus)
      layers.push(EMapFeatureLayout.graphTrolleybus);
    if (Filters.actual.graphMonoRailWay)
      layers.push(EMapFeatureLayout.graphMonoRailWay);
    if (Filters.actual.graphPedestrain)
      layers.push(EMapFeatureLayout.graphPedestrain);
    if (Filters.actual.graphRopeWay)
      layers.push(EMapFeatureLayout.graphRopeWay);
    if (Filters.actual.graphWaterWay)
      layers.push(EMapFeatureLayout.graphWaterWay);
    if (Filters.actual.graphSuburbanRailway)
      layers.push(EMapFeatureLayout.graphSuburbanRailway);

    return {
      action: EPipeMapFollowPath.updateTrackedLayers,
      payload: {
        layers: layers,
      },
    } as PipeMapFollowPath;
  },
  target: pipeMapFollowPath,
});
