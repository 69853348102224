import { sample } from 'effector';

import { SocEcoInfrastructureType } from '@widgets/widget-soceco-indicators/constants/map/SocEcoInfrastructureType';
// import { AnimalPassage } from '@widgets/widget-soceco-indicators/constants/map/featureMockCollection/animalPassage';
// import { AnimalProtectionVibration } from '@widgets/widget-soceco-indicators/constants/map/featureMockCollection/animalProtectionVibration';
import { ChangingRoutes } from '@widgets/widget-soceco-indicators/constants/map/featureMockCollection/changingRoutes';
// import { Culverts } from '@widgets/widget-soceco-indicators/constants/map/featureMockCollection/culverts';
// import { ProtectionVibration } from '@widgets/widget-soceco-indicators/constants/map/featureMockCollection/protectionVibration';
// import { SafeCrossing } from '@widgets/widget-soceco-indicators/constants/map/featureMockCollection/safeCrossing';
import { StationAccessibility } from '@widgets/widget-soceco-indicators/constants/map/featureMockCollection/stationAccessibility';
import { SOCECO_TABLE_IDS } from '@widgets/widget-soceco-indicators/constants/tables/tableIds';
import {
  FeatureItem,
  SocEcoInfrastructureApi,
  SocEcoInfrastructureFetchEvents,
  TargetedPayload,
} from '@widgets/widget-soceco-indicators/stores/map/infrastructure/store';
import {
  getSocEcoTable_1_1Fx,
  getSocEcoTable_2_5Fx,
  getSocEcoTable_3_1Fx,
  getSocEcoTable_3_2Fx,
  getSocEcoTable_3_4Fx,
  getSocEcoTable_4_2Fx,
} from '@widgets/widget-soceco-indicators/stores/tables/effects';

sample({
  clock: getSocEcoTable_3_1Fx.done,
  fn: payload => {
    return {
      type: SocEcoInfrastructureType[SOCECO_TABLE_IDS.TABLE_3_1],
      data: payload.result?.animalWall?.map(feature => ({
        id: feature?.crsId,
        name: feature?.name,
        geometry: {
          line: feature?.line.coordinates,
          multipoint: feature?.stMulti?.coordinates || [],
          point: null,
          multiline: null,
          polygon: null,
        },
      })),
    } as TargetedPayload<FeatureItem[]>;
  },
  target: SocEcoInfrastructureApi.setFeatures,
});

sample({
  clock: getSocEcoTable_3_2Fx.done,
  fn: payload => {
    return {
      type: SocEcoInfrastructureType[SOCECO_TABLE_IDS.TABLE_3_2],
      data: payload.result?.noiseProtectionForest?.map(feature => ({
        id: feature?.crsId,
        name: feature?.name,
        geometry: {
          line: null,
          point: null,
          multiline: feature?.stMulti.coordinates,
          polygon: null,
        },
      })),
    } as TargetedPayload<FeatureItem[]>;
  },
  target: SocEcoInfrastructureApi.setFeatures,
});

sample({
  clock: SocEcoInfrastructureFetchEvents[SOCECO_TABLE_IDS.TABLE_5_2],
  fn: () => {
    return {
      type: SocEcoInfrastructureType[SOCECO_TABLE_IDS.TABLE_5_2],
      data: ChangingRoutes.features.map(feature => ({
        id: feature.properties.id,
        name: feature.properties.name,
        geometry: {
          line: null,
          point: null,
          multiline: feature.geometry.coordinates,
          polygon: null,
        },
      })),
    } as TargetedPayload<FeatureItem[]>;
  },
  target: SocEcoInfrastructureApi.setFeatures,
});

sample({
  clock: getSocEcoTable_3_4Fx.done,
  fn: payload => {
    return {
      type: SocEcoInfrastructureType[SOCECO_TABLE_IDS.TABLE_3_4],
      data: payload.result?.pipes?.map(feature => ({
        id: feature?.crsId,
        name: feature?.name,
        geometry: {
          line: feature?.line.coordinates,
          multipoint: feature?.stMulti?.coordinates || [],
          point: null,
          multiline: null,
          polygon: null,
        },
      })),
    } as TargetedPayload<FeatureItem[]>;
  },
  target: SocEcoInfrastructureApi.setFeatures,
});

sample({
  clock: getSocEcoTable_2_5Fx.done,
  fn: payload => {
    return {
      type: SocEcoInfrastructureType[SOCECO_TABLE_IDS.TABLE_2_5],
      data: payload.result?.noiseProtection?.map(feature => ({
        id: feature?.crsId,
        name: feature?.name,
        geometry: {
          line: null,
          point: null,
          multiline: feature?.stMulti.coordinates,
          polygon: null,
        },
      })),
    } as TargetedPayload<FeatureItem[]>;
  },
  target: SocEcoInfrastructureApi.setFeatures,
});

sample({
  clock: getSocEcoTable_4_2Fx.done,
  fn: payload => {
    return {
      type: SocEcoInfrastructureType[SOCECO_TABLE_IDS.TABLE_4_2],
      data: payload.result?.plannedRailwayIntersection?.map((feature, i) => ({
        id: +Date() + i,
        name: feature?.projectName,
        geometry: {
          line: null,
          multiline: null,
          point: null,
          multipoint: feature?.intersectedPoints.coordinates,
          polygon: null,
        },
      })),
    } as TargetedPayload<FeatureItem[]>;
  },
  target: SocEcoInfrastructureApi.setFeatures,
});

sample({
  clock: SocEcoInfrastructureFetchEvents[SOCECO_TABLE_IDS.TABLE_5_3],
  fn: () => {
    return {
      type: SocEcoInfrastructureType.station_accessibility,
      data: StationAccessibility.features.map(feature => ({
        id: feature.properties.id,
        name: feature.properties.name,
        geometry: {
          line: null,
          multiline: null,
          point: feature.geometry.coordinates,
          polygon: null,
        },
      })),
    } as TargetedPayload<FeatureItem[]>;
  },
  target: SocEcoInfrastructureApi.setFeatures,
});

sample({
  clock: getSocEcoTable_1_1Fx.done,
  fn: payload => {
    const data: FeatureItem[] = [];

    payload.result?.scenarioTpusFlow?.forEach(itemYear => {
      const features = itemYear?.scenarioTpus?.map(
        item =>
          ({
            id: item?.tpu?.id,
            name: item?.tpu?.name,
            geometry: {
              line: null,
              point: item?.tpu?.geometry.coordinates,
              multiline: null,
              polygon: item?.tpu?.walkingArea.coordinates,
            },
          }) as FeatureItem,
      );

      data.push(...(features as FeatureItem[]));
    });

    return {
      type: SocEcoInfrastructureType[SOCECO_TABLE_IDS.TABLE_1_1],
      data,
    } as TargetedPayload<FeatureItem[]>;
  },
  target: SocEcoInfrastructureApi.setFeatures,
});
