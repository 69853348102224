import { sample } from 'effector';

import { EFilterMapLayout } from '@components/MapFilters';

import { railwaySource } from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import { LAYERS_Z_INDEX } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/layersZIndex';
import {
  $FilterMap,
  $FilterRegions,
  AddMapTileLayer,
  SetMapFeaturesTypes,
  addMapTileLayer,
  changeMap,
  setMapFeatures,
  setMapFn,
} from '@features/passenger-traffic/stores';

// Контроль рендера слоя всех жд путей
sample({
  clock: changeMap,
  source: { FilterMap: $FilterMap, FilterRegions: $FilterRegions },
  fn: ({ FilterMap, FilterRegions }) => {
    const layer: SetMapFeaturesTypes = {
      params: {
        layout: EMapFeatureLayout.railway,
        visible: false,
      },
      features: [],
    };

    // Отображать только для актуальной инфраструктуры
    if (!FilterMap.actual) return layer;

    // В фильтре отключено отображение всех дорог
    if (!FilterMap.railwayRoot) return layer;

    // Находимся не на слое инфраструктуры
    if (FilterMap.layout !== EFilterMapLayout.Infrastructure) return layer;

    // Установлен поинт А или Б
    if (FilterRegions.selectedRegionA && FilterRegions.selectedRegionB)
      return layer;

    // Если ограничений нет - отобразить
    layer.params.visible = true;
    return layer;
  },
  target: setMapFeatures,
});

// При получении инстанса карты добавить тайловый слой жд дорог
sample({
  clock: setMapFn,
  fn: () =>
    ({
      source: railwaySource(),
      layout: EMapFeatureLayout.railway,
      zIndex: LAYERS_Z_INDEX[EMapFeatureLayout.railway],
    }) as AddMapTileLayer,
  target: addMapTileLayer,
});
