import { IFileUploadMutation, IProjectType, Maybe } from '@api/gql/ag-types';
import { createApi, createStore } from 'effector';
import { createEffect } from 'effector/effector.umd';

export enum EFileStatuses {
  empty,
  uploading,
  error,
  success,
}

export enum EFileTypes {
  graph = 'graph',
  graphStops = 'graphStops',
  transportAreas = 'transportAreas',
  centersOfTransportAreas = 'centersOfTransportAreas',
  transportZones = 'transportZones',
  centersOfTransportZones = 'centersOfTransportZones',
  indicatorsOfTransportAreasAndTravelParameters = 'indicatorsOfTransportAreasAndTravelParameters',
  dataOnSuburbanRailwayRoutes = 'dataOnSuburbanRailwayRoutes',
}

const initFileState = {
  status: EFileStatuses.empty,
  id: null,
  error: null,
};

export const initFileStore = {
  graph: initFileState,
  graphStops: initFileState,
  transportAreas: initFileState,
  centersOfTransportAreas: initFileState,
  transportZones: initFileState,
  centersOfTransportZones: initFileState,
  indicatorsOfTransportAreasAndTravelParameters: initFileState,
  dataOnSuburbanRailwayRoutes: initFileState,
};

export const $Files = createStore<FilesStore>(initFileStore);

export const UploadFile = createEffect<
  UploadFileParams,
  Maybe<IFileUploadMutation>
>();

export const GetProjectFilesFx = createEffect<
  GetProjectFilesParams,
  Maybe<IProjectType>
>();

export const FilesApi = createApi($Files, {
  setStatus: (store, { status, key }: SetStatusPayload) => ({
    ...store,
    [key]: {
      ...store[key],
      status,
    },
  }),
  setFileId: (store, { id, key }: SetFileIdPayload) => ({
    ...store,
    [key]: {
      ...store[key],
      id,
    },
  }),
  setStateFile: (store, { state, key }: SetStateFilePayload) => ({
    ...store,
    [key]: state,
  }),
  setFiles: (_store, files) => ({
    ...files,
  }),
  reset: () => initFileStore,
});

export type FilesStore = Record<keyof typeof EFileTypes, FileState>;

export type FileState = {
  status: EFileStatuses;
  id: string | null;
  error: string | null;
};

export type SetStatusPayload = {
  key: keyof FilesStore;
  status: EFileStatuses;
};

export type SetFileIdPayload = {
  key: keyof FilesStore;
  id: string | null;
};

export type SetStateFilePayload = {
  key: EFileTypes;
  state: FileState;
};

export type UploadFileParams = {
  file: File;
  type: number;
};

export type GetProjectFilesParams = {
  id: string;
};
