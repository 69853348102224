import { apiClient } from '@api/client';
import { IMutation, IMutationCreateStationArgs } from '@api/gql/types';
import { gql } from '@apollo/client';

export const mutationCreateStation = async (
  newStation: IMutationCreateStationArgs,
) => {
  const result = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation CreateStationMutation(
        $name: String!
        $scenarioId: UUID!
        $railwayId: UUID
        $railwayStationId: UUID
        $vertexId: UUID!
      ) {
        createStation(
          name: $name
          scenarioId: $scenarioId
          railwayId: $railwayId
          railwayStationId: $railwayStationId
          vertexId: $vertexId
        ) {
          instance {
            id
            name
            railwayStation {
              geometry
            }
            vertex {
              id
              theGeom
            }
            transportRegion {
              name
            }
          }
        }
      }
    `,
    variables: newStation,
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result.data?.createStation;
};
