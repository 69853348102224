import { createApi, createEffect, createEvent, createStore } from 'effector';

import { SocEcoScenarioParams } from '@features/pt-constructor/constants/ecoParams';
import { ScenarioType } from '@features/pt-constructor/constants/socialScenarioType';
import { QueryGetAllScenariosResponse } from '@features/pt-constructor/store/api/queryGetAllScenarios';
import { QueryGetSocEcoAndTransportScenariosResponse } from '@features/pt-constructor/store/api/queryGetSocEcoAndTransportScenarios';

export const maxEcoParamValue = 100;
export const minEcoParamValue = -100;
export const baseEcoParamValue = 1;

export const basicDefaultEcoParams: DefaultEcoParams = {
  gdpGrowthRate: 1,
  growthRateInTheNumberOfPlacements: 1,
  growthRateOfMotorization: 1,
  jobGrowthRate: 1,
  populationGrowthRate: 1,
} as const;

const initState: CalculationsState = {
  userScenarios: [],
  transportProjects: [],
  accessibleScenarios: [],
  calculationResult: null,
  socEcoScenarios: [],
  transportScenarios: [],
  readonly: true,
  isNewCalculationScenario: false,
};

export const $Calculations = createStore<CalculationsState>(initState);

// Инициировать запрос на получение всех сценариев
export const fetchAllScenarios = createEvent();
// Инициировать запрос на получение всех статусов сценариев
export const fetchAllStatuses = createEvent();

export const fetchAllCalculationsDataFx = createEffect<
  void,
  QueryGetAllScenariosResponse
>();

export const fetchScenariosFx = createEffect<
  void,
  QueryGetSocEcoAndTransportScenariosResponse
>();

export const CalculationsApi = createApi($Calculations, {
  setCalculationsState: (
    state,
    payload: Omit<CalculationsState, 'isNewCalculationScenario' | 'readonly'>,
  ) => ({
    ...state,
    ...payload,
  }),
  pushCalculationScenarioInRegistry: (state, payload: CalculationScenario) => {
    const userScenarios = [
      payload,
      ...state.userScenarios.filter(scenario => scenario.id !== payload.id),
    ];

    return {
      ...state,
      userScenarios,
    };
  },
  removeCalculationScenarioInRegistry: (state, payload: string) => {
    const userScenarios = state.userScenarios.filter(
      scenario => scenario.id !== payload,
    );

    return {
      ...state,
      userScenarios,
    };
  },
  updateScenarios: (
    state,
    payload: Pick<CalculationsState, 'socEcoScenarios' | 'transportScenarios'>,
  ) => ({
    ...state,
    socEcoScenarios: payload.socEcoScenarios,
    transportScenarios: payload.transportScenarios,
  }),
  setReadonly: (state, payload: boolean) => ({
    ...state,
    readonly: payload,
  }),
  setVisibleFormCreateCalculationScenario: (state, payload: boolean) => ({
    ...state,
    isNewCalculationScenario: payload,
  }),
});

export interface ComputingResults extends Record<string, number> {
  year: number;
}

export type ComputingResult = {
  id: string;
  totalFlow: number;
  nightTrain: number;
  dayTrain: number;
  avia: number;
  bus: number;
  auto: number;
};

export type TransportProject = {
  name: string;
  id: string;
  year: number;
};

export type SocEcoScenarioListItem = SocEcoScenarioParams & {
  id: string | undefined;
  name: string;
  computed: boolean;
  type: ScenarioType;
};

export type TransportScenarioListItem = {
  id: string | undefined;
  name: string;
  type: ScenarioType;
  transportProjects: TransportProject[];
};

export interface ScenarioOption {
  label: string;
  value: string;
  type: ScenarioType;
  disabled?: boolean;
}

type DefaultEcoParams = Readonly<Record<keyof SocEcoScenarioParams, number>>;

export interface CalculationScenario extends SocEcoScenarioParams {
  id: string;
  name: string;
  socEcoScenarioType: ScenarioType;
  socEcoComputeName: SocEcoScenarioListItem['name'];
  socEcoScenarioId: SocEcoScenarioListItem['id'];
  transportScenarioType: ScenarioType;
  projectObjects: TransportScenarioListItem['transportProjects'];
  transportComputeName: TransportScenarioListItem['name'];
  transportScenarioId: TransportScenarioListItem['id'];
  computingResults: ComputingResults[];
  computingresultsSet: ComputingResult[] | null;
}

export type CalculationsState = {
  // Пользовательские расчеты
  userScenarios: CalculationScenario[];
  // Все транспортные проекты
  transportProjects: TransportProject[];
  // Все сценарии соц-эко
  socEcoScenarios: SocEcoScenarioListItem[];
  // Все транспортные сценарии
  transportScenarios: TransportScenarioListItem[];
  // ??
  accessibleScenarios: unknown;
  // Режим редактирования расчета
  readonly: boolean;
  // Результаты расчета
  calculationResult: null | undefined;
  isNewCalculationScenario: boolean;
};
