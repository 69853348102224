export const SOC_ECO_TH_5_3 = [
  {
    id: 'transportObject',
    label: 'Транспортный объект',
  },
  {
    id: 'stoppingPoints',
    label: 'Остановочные пункты',
  },
  {
    id: 'year',
    label: 'Год',
  },
  {
    id: 'averageTimeTransferFromRoadTransport',
    label: 'Среднее время на пересадку с автомобильного транспорта, мин.',
  },
  {
    id: 'averageTimeTransferFromPublicTransport',
    label: 'Среднее время на пересадку с общественного транспорта, мин.',
  },
];
