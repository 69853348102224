import { Stack, Typography } from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';

import { palette } from '@system/theme/palette';

import { TableRoutesParams } from '@widgets/widget-routes-params/components/TableRoutesParams/TableRoutesParams';
import { ToolBar } from '@widgets/widget-routes-params/components/ToolBar/ToolBar';

export const WidgetRoutesParams = ({
  closeRoutesParams,
}: WidgetRoutesParamsType) => {
  return (
    <>
      <ToolBar closeRoutesParams={closeRoutesParams} />

      <AutoFullHeight
        style={{
          padding: '24px',
          backgroundColor: palette.background.default,
        }}
      >
        <Stack
          sx={{
            backgroundColor: theme => theme.palette.common.white,
            borderRadius: 2,
            boxShadow: '0px 0px 8px 0px #00000026',
            width: '100%',
            height: '100%',
            p: 3,
            rowGap: 3,
          }}
        >
          <TableRoutesParams />

          <Typography
            fontSize={12}
            color={theme => theme.palette.text.secondary}
          >
            *Доступны для ввода белые ячейки.
          </Typography>
        </Stack>
      </AutoFullHeight>
    </>
  );
};

type WidgetRoutesParamsType = {
  closeRoutesParams: () => void;
};
