import { NavigateOptions, To, useNavigate } from 'react-router-dom';

import { useBlockNavigation } from '@utils/NavigationBlockContext/NavigationBlockContext';

export const useCustomNavigate = () => {
  const navigate = useNavigate();
  const { blockNavigation } = useBlockNavigation();

  return (to: To, options?: NavigateOptions) => {
    if (!blockNavigation) {
      navigate(to, options);
    } else {
      // Обработка заблокированной навигации (опционально)
      console.log('Navigation is blocked!');
    }
  };
};
