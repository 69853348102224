import { sample } from 'effector';
import { Feature } from 'ol';
import { MultiLineString } from 'ol/geom';

import { EFilterMapLayout } from '@components/MapFilters';

import {
  getWidthLine,
  layerIdKey,
  layerLayoutKey,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import {
  $FilterMap,
  $FilterRegions,
  $Map,
  SetMapFeaturesTypes,
  changeMap,
  setMapFeatures,
} from '@features/passenger-traffic/stores';

import { recursivelyFromLonLat } from '@utils/geometry';

// Контроль рендера фичи железнодорожного пути АБ
sample({
  clock: changeMap,
  source: {
    FilterMap: $FilterMap,
    FilterRegions: $FilterRegions,
    Map: $Map,
  },
  fn: ({ FilterMap, FilterRegions, Map }) => {
    const layer: SetMapFeaturesTypes = {
      params: {
        layout: EMapFeatureLayout.directionRailway,
        width: 5,
      },
      features: [],
    };

    // В фильтре отключено отображение ЖД
    if (!FilterMap.railwayRoot) return layer;

    // Не выбрано отображение существующих структур
    if (!FilterMap.actual) return layer;

    // Не установлены оба поина АБ
    if (!FilterRegions.selectedRegionA || !FilterRegions.selectedRegionB)
      return layer;

    // НЕ блокирующее условие определения толщины линии
    if (FilterMap.layout === EFilterMapLayout.PassengerFlows) {
      layer.params.width = getWidthLine(
        (FilterMap.nightTrain
          ? Map.passengerTrafficPercent.nightTrain || 0
          : 0) +
          (FilterMap.dayTrain ? Map.passengerTrafficPercent.dayTrain || 0 : 0) +
          (FilterMap.suburbanTrain
            ? Map.passengerTrafficPercent.suburbanTrain || 0
            : 0),
      );
    }

    //Если поинты АБ установлены, можно построить жд сообщение между районами
    if (FilterRegions.selectedRegionA && FilterRegions.selectedRegionB) {
      layer.features = FilterRegions.directions.railway.map(
        item =>
          new Feature({
            geometry: new MultiLineString(
              recursivelyFromLonLat(item.geometry.coordinates),
            ),
            [layerIdKey]: item.id,
            [layerLayoutKey]: EMapFeatureLayout.directionRailway,
          }),
      );
      return layer;
    }

    // Пока глобально отображать нечего
    return layer;
  },
  target: setMapFeatures,
});
