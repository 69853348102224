import { IQuery, IQueryGetScenarioByIdArgs } from '@api/gql/ag-types';
import { createEffect } from 'effector';

export const getSocEcoTable_1_1Fx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQuery['getScenarioById']
>();
export const getSocEcoTable_1_2Fx = createEffect();
export const getSocEcoTable_1_3Fx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQuery['getScenarioById']
>();
export const getSocEcoTable_1_4Fx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQuery['getScenarioById']
>();
export const getSocEcoTable_1_5Fx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQuery['getScenarioById']
>();

export const getSocEcoTable_2_1Fx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQuery['getScenarioById']
>();
export const getSocEcoTable_2_2Fx = createEffect();
export const getSocEcoTable_2_3Fx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQuery['getScenarioById']
>();
export const getSocEcoTable_2_4Fx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQuery['getScenarioById']
>();
export const getSocEcoTable_2_5Fx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQuery['getScenarioById']
>();

export const getSocEcoTable_3_1Fx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQuery['getScenarioById']
>();
export const getSocEcoTable_3_2Fx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQuery['getScenarioById']
>();
export const getSocEcoTable_3_3Fx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQuery['getScenarioById']
>();
export const getSocEcoTable_3_4Fx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQuery['getScenarioById']
>();
export const getSocEcoTable_3_5Fx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQuery['getScenarioById']
>();
export const getSocEcoTable_3_6Fx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQuery['getScenarioById']
>();
export const getSocEcoTable_3_7Fx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQuery['getScenarioById']
>();

export const getSocEcoTable_4_1Fx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQuery['getScenarioById']
>();
export const getSocEcoTable_4_2Fx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQuery['getScenarioById']
>();
export const getSocEcoTable_4_3Fx = createEffect();
export const getSocEcoTable_4_4Fx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQuery['getScenarioById']
>();

export const getSocEcoTable_5_1Fx = createEffect();
export const getSocEcoTable_5_2Fx = createEffect();
export const getSocEcoTable_5_3Fx = createEffect();
export const getSocEcoTable_5_4Fx = createEffect();
export const getSocEcoTable_5_5Fx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQuery['getScenarioById']
>();
export const getSocEcoTable_5_6Fx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQuery['getScenarioById']
>();
export const getSocEcoTable_5_7Fx = createEffect();
export const getSocEcoTable_5_8Fx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQuery['getScenarioById']
>();

export const getSocEcoTable_6_1Fx = createEffect();
export const getSocEcoTable_6_2Fx = createEffect();
export const getSocEcoTable_6_3Fx = createEffect();

export const getSocEcoTable_7_1Fx = createEffect();
export const getSocEcoTable_7_2Fx = createEffect();
export const getSocEcoTable_7_3Fx = createEffect();
export const getSocEcoTable_7_4Fx = createEffect();
export const getSocEcoTable_7_5Fx = createEffect();
