export const ChangingRoutes = {
  features: [
    {
      type: 'Feature',
      properties: { id: 1, name: 'Маршрут_1' },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [92.762094572299446, 56.040721045101826],
            [92.764896904390085, 56.040223589701121],
            [92.768064037107919, 56.039543733986818],
            [92.769191602682852, 56.039195515206323],
            [92.77013676794418, 56.038200604404913],
            [92.774249065923328, 56.0324798672968],
            [92.775326885958194, 56.030854846321148],
            [92.776089650905945, 56.029843353673044],
            [92.778029726968697, 56.028135423463958],
            [92.780052712264904, 56.026792293882053],
            [92.786702032787659, 56.022878978063169],
            [92.797090559739047, 56.016743694787777],
            [92.800489838310526, 56.014787036878346],
            [92.800875675608822, 56.01414838829043],
            [92.800934454542983, 56.013454796867308],
            [92.80124010500063, 56.009163934673417],
            [92.801522243884619, 56.005813535426121],
            [92.801628045966083, 56.004120702122229],
            [92.801498732310932, 56.003579935927931],
            [92.800217351546181, 56.00142862693756],
            [92.799641317991387, 56.000582210285614],
            [92.799300400173237, 56.00012373459915],
            [92.796761150217392, 55.998219297132266],
            [92.79582068727079, 55.997749065658958],
            [92.794997782192496, 55.997372880480327],
            [92.794268923408879, 55.997043718449014],
            [92.793786936148734, 55.996808602712363],
            [92.78993103806765, 55.996326615452226],
            [92.78930210347211, 55.996211996530576],
            [92.787961943773183, 55.996100316555662],
            [92.787103771334401, 55.996088560768833],
            [92.785740100061815, 55.995912223966343],
            [92.783030391196931, 55.995541916681105],
            [92.781437482081103, 55.995283289370789],
            [92.779762282457469, 55.995007028380222],
            [92.7791215920751, 55.99490122629873],
            [92.778281053316562, 55.994860081044813],
            [92.774848363561432, 55.994636721094992],
            [92.773696296451831, 55.994536796906914],
            [92.772990949241873, 55.994542674800329],
            [92.771262848577479, 55.994525041120085],
            [92.770052002533717, 55.994554430587179],
            [92.768464971311332, 55.994401605358327],
            [92.767618554659379, 55.994072443327013],
            [92.762681124189683, 55.992403121596787],
            [92.760988290885791, 55.991885866976155],
            [92.757673158999012, 55.990075475803934],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: 2, name: 'Маршрут_2' },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [92.762136026916096, 56.040969772802171],
            [92.768486874198445, 56.039676388760334],
            [92.769017493292537, 56.039792461687171],
            [92.77371015590586, 56.044369051373664],
            [92.774174447613177, 56.044833343080995],
            [92.774191029459871, 56.045281052941625],
            [92.77400862914628, 56.04577850834233],
            [92.773594082979031, 56.046159890816206],
            [92.770543023188026, 56.047602511478246],
            [92.768122073571263, 56.048514513046207],
            [92.762666646010189, 56.049642078621154],
            [92.760906000378, 56.049968270769376],
            [92.755369024779839, 56.051202628386783],
            [92.753864284065259, 56.051649348286425],
            [92.747304555012661, 56.053600808900612],
            [92.743260564342236, 56.054682341289222],
            [92.742061474085318, 56.054846922304876],
            [92.738158552856902, 56.054846922304876],
            [92.737382670925953, 56.054940968599539],
            [92.733785400155185, 56.056939452361078],
            [92.729153620143137, 56.058350146780995],
            [92.726120627140332, 56.059079005564612],
            [92.720948080933965, 56.06013702637955],
            [92.719607921235053, 56.060348630542535],
            [92.717632949047172, 56.061195047194481],
            [92.716974624984545, 56.061735813388786],
            [92.715117210664999, 56.061994440699102],
            [92.710273826489953, 56.061594743946785],
            [92.706723578866516, 56.061242070341812],
            [92.704301886778993, 56.061171535620815],
            [92.698306435494359, 56.061571232373126],
            [92.678133505289622, 56.063969412886976],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: 3, name: 'Маршрут_3' },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [92.93917075190744, 56.075187372471923],
            [92.932305372397209, 56.073306446578705],
            [92.929295890968064, 56.072318960484772],
            [92.921254932774559, 56.069732687381595],
            [92.902868882168377, 56.064230979143957],
            [92.892053558282385, 56.060845312536166],
            [92.884482831562195, 56.05858820146431],
            [92.8838715306469, 56.058164993138334],
            [92.8838715306469, 56.057271553339056],
            [92.884859016740833, 56.055531696887833],
            [92.888679647461473, 56.048560515296131],
            [92.886598873192028, 56.04612706742175],
            [92.884012600088923, 56.045703859095802],
            [92.877744662040129, 56.04450999707052],
            [92.870581304269962, 56.043133703795235],
            [92.864412857301161, 56.041956392680198],
            [92.859139830053678, 56.041193627732454],
            [92.851479016882806, 56.040430862784703],
            [92.841198271934886, 56.039137478742873],
            [92.839457178032418, 56.038880460119188],
            [92.838288157840779, 56.038581986878761],
            [92.83747564735296, 56.038059658708022],
            [92.836862119025426, 56.037454421303828],
            [92.836447572858162, 56.036940384056436],
            [92.835104443276251, 56.035945473255019],
            [92.834184150784949, 56.035729909248047],
            [92.833156076290152, 56.035705036478014],
            [92.831904146865043, 56.035655290937946],
            [92.830088434652481, 56.035273908464092],
            [92.826987629321422, 56.034793034910074],
            [92.82090209158612, 56.034179506582511],
            [92.815098445244558, 56.03315143208772],
            [92.81416986182991, 56.0328529588473],
            [92.81201422176018, 56.031858048045883],
            [92.810952983572008, 56.031625902192225],
            [92.807968251167779, 56.032057030206168],
            [92.80153449465196, 56.033350414248005],
            [92.793243571306874, 56.036003509718441],
            [92.787605743432209, 56.038656605188869],
            [92.783891409773602, 56.040911736338735],
            [92.780210239808397, 56.042901557941548],
            [92.776429578763029, 56.044957706931129],
            [92.775136194721199, 56.045488326025215],
            [92.774108120226401, 56.045024034317883],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: 4, name: 'Маршрут_4' },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [92.922395244097245, 56.051605264085737],
            [92.922183639934261, 56.0531805395213],
            [92.920702410793339, 56.054191537188906],
            [92.91677597799125, 56.056660252423754],
            [92.913578403972778, 56.058541178316972],
            [92.907641731622377, 56.055496429527338],
            [92.900858642619951, 56.052122518706369],
            [92.895498003824272, 56.049371664587554],
            [92.892747149705457, 56.047937458593971],
            [92.891218897417218, 56.047279134531351],
            [92.890184388175939, 56.047373180826007],
            [92.889902249291964, 56.047608296562665],
            [92.889479040965995, 56.048266620625284],
            [92.888961786345348, 56.049089525703572],
            [92.888538578019379, 56.049136548850896],
          ],
        ],
      },
    },
  ],
};
