import { apiClient } from '@api/client';
import { IMutation, IQuery } from '@api/gql/ag-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

import {
  EFileStatuses,
  EFileTypes,
  FilesApi,
  GetProjectFilesFx,
  SetStateFilePayload,
  UploadFile,
  initFileStore,
} from '@features/ag-projects-registry/store/files/store';

const getTypeFile = (key: number): EFileTypes => {
  switch (key) {
    case 1:
      return EFileTypes.graph;
    case 2:
      return EFileTypes.transportAreas;
    case 3:
      return EFileTypes.centersOfTransportAreas;
    case 4:
      return EFileTypes.transportZones;
    case 5:
      return EFileTypes.centersOfTransportZones;
    case 6:
      return EFileTypes.indicatorsOfTransportAreasAndTravelParameters;
    case 7:
      return EFileTypes.dataOnSuburbanRailwayRoutes;
    case 8:
      return EFileTypes.graphStops;
    default:
      return EFileTypes.graph;
  }
};

// Файл залит успешно
sample({
  clock: UploadFile.done,
  fn: payload => {
    if (!payload.result?.fileInfo?.id) throw Error('File not uploaded');

    return {
      key: getTypeFile(payload.params.type),
      state: {
        status: EFileStatuses.success,
        id: payload.result.fileInfo.id,
        error: payload.result.fileInfo.error,
      },
    } as SetStateFilePayload;
  },
  target: FilesApi.setStateFile,
});

// Ошибка при заливке файла
sample({
  clock: UploadFile.fail,
  fn: payload => {
    return {
      key: getTypeFile(payload.params.type),
      state: {
        status: EFileStatuses.error,
        id: null,
      },
    } as SetStateFilePayload;
  },
  target: FilesApi.setStateFile,
});

// Эффект заливки файла
UploadFile.use(async params => {
  const response = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation UploadFileMutation($file: Upload!, $type: Int!) {
        uploadFile(file: $file, type: $type) {
          fileInfo {
            id
            file
          }
          ok
        }
      }
    `,
    variables: {
      file: params.file,
      type: params.type,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });
  return response.data?.uploadFile || null;
});

sample({
  clock: GetProjectFilesFx.done,
  fn: payload =>
    payload.result?.fileSet.reduce(
      (acc, file) => ({
        ...acc,
        [getTypeFile(+file.type.slice(2))]: {
          id: file.id,
          status: !file.error ? EFileStatuses.success : EFileStatuses.error,
          error: file.error,
        },
      }),
      initFileStore,
    ),
  target: FilesApi.setFiles,
});

GetProjectFilesFx.use(async params => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query GetProjectById($id: String!) {
        getProjectById(id: $id) {
          fileSet {
            type
            id
            file
            error
          }
        }
      }
    `,
    variables: {
      id: params.id,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });
  return response.data?.getProjectById;
});
