import { createApi, createStore } from 'effector';

const data: GraphParamsType[] = [
  {
    id: 1,
    name: 'Линия 1',
    year: 2028,
    regionFrom: 'Санкт-Петербургский',
    regionTo: 'Новгородский',
    expressTrainTime: 0.5,
    nightTrainTime: 2.5,
    dayTrainTime: null,
    suburbanTrainTime: 3,
    expressTrainCost: 1120,
    nightTrainCost: 988.7,
    dayTrainCost: null,
    suburbanTrainCost: 780.3,
    expressTrainFrequency: 16,
    nightTrainFrequency: 1,
    dayTrainFrequency: null,
    suburbanTrainFrequency: 3,
  },
  {
    id: 2,
    name: 'Линия 1',
    year: 2028,
    regionFrom: 'Санкт-Петербургский',
    regionTo: 'Новгородский',
    expressTrainTime: 0.5,
    nightTrainTime: 2.5,
    dayTrainTime: null,
    suburbanTrainTime: 3,
    expressTrainCost: 1120,
    nightTrainCost: 988.7,
    dayTrainCost: null,
    suburbanTrainCost: 780.3,
    expressTrainFrequency: 16,
    nightTrainFrequency: 1,
    dayTrainFrequency: null,
    suburbanTrainFrequency: 3,
  },
  {
    id: 3,
    name: 'Линия 1',
    year: 2028,
    regionFrom: 'Санкт-Петербургский',
    regionTo: 'Новгородский',
    expressTrainTime: 0.5,
    nightTrainTime: 2.5,
    dayTrainTime: null,
    suburbanTrainTime: 3,
    expressTrainCost: 1120,
    nightTrainCost: 988.7,
    dayTrainCost: null,
    suburbanTrainCost: 780.3,
    expressTrainFrequency: 16,
    nightTrainFrequency: 1,
    dayTrainFrequency: null,
    suburbanTrainFrequency: 3,
  },
];

const initState: TableGraphParamsType = {
  graphs: data,
};

export const $GraphParameterTable =
  createStore<TableGraphParamsType>(initState);

export const GraphParameterTableApi = createApi($GraphParameterTable, {
  updateAllGraphs: (store, graphs) => ({
    ...store,
    graphs,
  }),
  reset: () => initState,
});

type GraphParamsType = Record<string, string | number | null | undefined>;

type TableGraphParamsType = {
  /** Графы для таблицы параметров */
  graphs: GraphParamsType[];
};
