import { sample } from 'effector';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';

import { EFilterMapLayout } from '@components/MapFilters';

import {
  layerIdKey,
  layerLayoutKey,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import {
  $FilterMap,
  $FilterRegions,
  SetMapFeaturesTypes,
  changeMap,
  setMapFeatures,
} from '@features/passenger-traffic/stores';

// Контроль рендера фич автобусных остановок
sample({
  clock: changeMap,
  source: { FilterMap: $FilterMap, FilterRegions: $FilterRegions },
  fn: ({ FilterMap, FilterRegions }) => {
    const layer: SetMapFeaturesTypes = {
      params: {
        layout: EMapFeatureLayout.railwayStation,
      },
      features: [],
    };

    // В фильтре отключено отображение всех жд путей
    if (!FilterMap.railwayRoot) return layer;

    // Не выбрано отображение существующих структур
    if (!FilterMap.actual) return layer;

    // Находимся не на слое инфраструктуры
    if (FilterMap.layout === EFilterMapLayout.PassengerFlows) return layer;

    // Установлен поинт А и Б
    if (FilterRegions.selectedRegionA && FilterRegions.selectedRegionB) {
      const endRegions = [
        ...FilterRegions.selectedRegionA.railwaystationSet.map(
          station => station.id,
        ),
        ...FilterRegions.selectedRegionB.railwaystationSet.map(
          station => station.id,
        ),
      ];

      FilterRegions.directions.stations.forEach(station => {
        const isIntermediate = !endRegions.includes(station.id);
        // Если флаг промежуточных не установлен, пропускать промежуточные
        if (!FilterMap.intermediateCorrespondents && isIntermediate) return;
        layer.features.push(
          new Feature({
            geometry: new Point(fromLonLat(station.geometry.coordinates)),
            [layerIdKey]: station.id,
            [layerLayoutKey]: EMapFeatureLayout.railwayStation,
            intermediate: isIntermediate,
          }),
        );
      });
      return layer;
    }

    // Пока глобально отображать нечего
    return layer;
  },
  target: setMapFeatures,
});
