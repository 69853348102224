export const SOC_ECO_TH_6_3 = [
  {
    id: 'year',
    label: 'Год',
  },
  {
    id: 'population',
    label: 'Численность населения, тыс. чел.',
  },
  {
    id: 'reducingCarbonDioxideEmissions',
    label: 'Сокращение объёмов выбросов CO2, тонн',
  },
  {
    id: 'areaDisturbedLands',
    label: 'Площадь нарушенных земель, га.',
  },
];
