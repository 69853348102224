import { useUnit } from 'effector-react';
import { useCallback, useMemo } from 'react';

import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  MenuItem,
  Palette,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';

import { FormLabel } from '@components/FormLabel';
import { typographyWhitCircleStyle } from '@components/MapFilters/utils';

import { CashedField } from '@features/ag-forecast/components/Inspector/TpuInspector/CashedField';
import { EVehicleAg } from '@features/ag-forecast/constants/EVehicleAg';
import { VEHICLE_LABELS } from '@features/ag-forecast/constants/VehicleLabels';
import { $TransportCategories } from '@features/ag-forecast/stores/lists/transportCategories';
import { $Vehicles } from '@features/ag-forecast/stores/lists/vehicles';
import {
  $EditorMap,
  EditorMapApi,
} from '@features/ag-forecast/stores/map/editor';
import { $UI } from '@features/ag-forecast/stores/ui';

export const TpuGraphInspector = () => {
  const $vehicles = useUnit($Vehicles);
  const $transportCategories = useUnit($TransportCategories);
  const { selectedTpuGraph, graphs } = useUnit($EditorMap);
  const { updateSelectedTpuGraphParams, removeGraph } = useUnit(EditorMapApi);
  const { editTPUMode } = useUnit($UI);

  const selectedTpuGraphItem = useMemo(() => {
    return graphs.find(graph => graph.id === selectedTpuGraph);
  }, [graphs, selectedTpuGraph]);

  const changeNameHandler = useCallback(
    (value: string) => {
      updateSelectedTpuGraphParams({ name: value });
    },
    [updateSelectedTpuGraphParams],
  );

  const changeLineNumHandler = useCallback(
    (value: number) => {
      updateSelectedTpuGraphParams({ laneNum: value });
    },
    [updateSelectedTpuGraphParams],
  );

  const changeOneWayHandler = useCallback(
    (value: boolean) => {
      updateSelectedTpuGraphParams({ oneway: value });
    },
    [updateSelectedTpuGraphParams],
  );

  const changeYearHandler = useCallback(
    (value: number) => {
      updateSelectedTpuGraphParams({ year: value });
    },
    [updateSelectedTpuGraphParams],
  );

  const changeCostHandler = useCallback(
    (value: number) => {
      updateSelectedTpuGraphParams({ cost: value });
    },
    [updateSelectedTpuGraphParams],
  );

  const changeParkingCoHandler = useCallback(
    (value: number) => {
      updateSelectedTpuGraphParams({ parkingCo: value });
    },
    [updateSelectedTpuGraphParams],
  );

  const changeFreeSpeedHandler = useCallback(
    (value: number) => {
      updateSelectedTpuGraphParams({ freeSpeed: value });
    },
    [updateSelectedTpuGraphParams],
  );

  const changeFrequencyWeekdaysHandler = useCallback(
    (value: number) => {
      updateSelectedTpuGraphParams({ frequencyWeekdays: value });
    },
    [updateSelectedTpuGraphParams],
  );

  const changeFrequencyWeekendsHandler = useCallback(
    (value: number) => {
      updateSelectedTpuGraphParams({ frequencyWeekend: value });
    },
    [updateSelectedTpuGraphParams],
  );

  const changeCapacityHandler = useCallback(
    (value: number) => {
      updateSelectedTpuGraphParams({ capacity: value });
    },
    [updateSelectedTpuGraphParams],
  );

  const changeCapacityHourHandler = useCallback(
    (value: number) => {
      updateSelectedTpuGraphParams({ capacityHour: value });
    },
    [updateSelectedTpuGraphParams],
  );

  const changeCategoryHandler = useCallback(
    (event: SelectChangeEvent) => {
      updateSelectedTpuGraphParams({ category: event.target.value });
    },
    [updateSelectedTpuGraphParams],
  );

  const changeVehiclesHandler = useCallback(
    (vehicleId: EVehicleAg, isChecked: boolean) => {
      updateSelectedTpuGraphParams({
        vehicles: isChecked
          ? [...(selectedTpuGraphItem?.vehicles || []), vehicleId]
          : selectedTpuGraphItem?.vehicles.filter(v => v !== vehicleId),
      });
    },
    [selectedTpuGraphItem?.vehicles, updateSelectedTpuGraphParams],
  );

  if (!selectedTpuGraph || !selectedTpuGraphItem) return null;

  return (
    <Stack
      height="100%"
      direction="column"
      justifyContent="space-between"
    >
      <Stack
        gap={3}
        pr={3}
        mr={-3}
        sx={{
          overflowY: 'auto',
        }}
      >
        <Stack>
          <Typography
            variant="subtitle2"
            fontSize="16px"
            mb={3}
          >
            Новый элемент графа
          </Typography>
          <Stack gap={2}>
            <CashedField
              id="tpuGraphName"
              title="Наименование"
              placeholder="Введите название участка графа"
              value={selectedTpuGraphItem.name}
              onBlur={value => changeNameHandler(value as string)}
              disabled={!editTPUMode}
            />
            <FormLabel
              id="category"
              title="Категория"
            >
              <Select
                value={selectedTpuGraphItem.category}
                onChange={changeCategoryHandler}
                disabled={!editTPUMode}
              >
                {$transportCategories &&
                  $transportCategories.map(category => (
                    <MenuItem
                      key={category}
                      value={category}
                      disabled={!editTPUMode}
                    >
                      {category}
                    </MenuItem>
                  ))}
              </Select>
            </FormLabel>
          </Stack>
        </Stack>
        <Divider />
        <Stack>
          <Typography variant="subtitle2">
            Разрешенные виды транспорта
          </Typography>
          <Stack
            ml={2}
            mt={1}
          >
            {($vehicles || []).map(vehicle => (
              <FormControlLabel
                key={vehicle.id}
                checked={selectedTpuGraphItem.vehicles.includes(
                  vehicle.vehicleId,
                )}
                onChange={(_, checked) =>
                  changeVehiclesHandler(vehicle.vehicleId, checked)
                }
                disabled={!editTPUMode}
                control={<Checkbox disabled={!editTPUMode} />}
                label={
                  <Typography
                    sx={({ palette }) => {
                      const [a, b] = vehicleToPalette(
                        vehicle.vehicleId,
                        palette,
                      );
                      return typographyWhitCircleStyle(a, b);
                    }}
                    variant={'body2'}
                  >
                    {VEHICLE_LABELS[vehicle.vehicleId]}
                  </Typography>
                }
              />
            ))}
          </Stack>
        </Stack>
        <Divider />

        <Stack gap={2}>
          <Typography variant="subtitle2">Свойства участка графа</Typography>
          <Stack
            ml={1}
            gap={2}
          >
            <CashedField
              id="lane_num"
              title="Число полос/путей *"
              placeholder="Введите число полос на участке дороги"
              value={selectedTpuGraphItem.laneNum}
              onBlur={value => changeLineNumHandler(Number(value))}
              disabled={!editTPUMode}
            />
            <FormLabel
              id="oneway"
              title="Односторонее движение"
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Checkbox
                id="oneway"
                value={selectedTpuGraphItem.oneway}
                onChange={value => changeOneWayHandler(value.target.checked)}
                disabled={!editTPUMode}
              />
            </FormLabel>
            <CashedField
              id="year"
              title="Год ввода в эксплуатацию *"
              placeholder="Введите планируемый год ввода в эксплуатацию"
              value={selectedTpuGraphItem.year}
              onBlur={value => changeYearHandler(Number(value))}
              disabled={!editTPUMode}
            />
            <CashedField
              id="cost"
              title="Затраты на стоимость дорог"
              placeholder="Введите затраты на стоимость дорог"
              value={selectedTpuGraphItem.cost}
              onBlur={value => changeCostHandler(Number(value))}
              disabled={!editTPUMode}
            />
            <CashedField
              id="parking_co"
              title="Стоимость парковки на отрезке"
              placeholder="Введите стоимость паркевки на участке дороги"
              value={selectedTpuGraphItem.parkingCo}
              onBlur={value => changeParkingCoHandler(Number(value))}
              disabled={!editTPUMode}
            />
            <CashedField
              id="free_speed"
              title="Максимальная разрешенная скорость"
              placeholder="Введите разрешенную скорость на участке дороги"
              value={selectedTpuGraphItem.freeSpeed}
              onBlur={value => changeFreeSpeedHandler(Number(value))}
              disabled={!editTPUMode}
            />
            <CashedField
              id="frequency_weekdays"
              title="Частота сообщения в будний день"
              placeholder="Введите частоту сообщения в будний день"
              value={selectedTpuGraphItem.frequencyWeekdays}
              onBlur={value => changeFrequencyWeekdaysHandler(Number(value))}
              disabled={!editTPUMode}
            />
            <CashedField
              id="frequency_weekend"
              title="Частота сообщения в выходной день"
              placeholder="Введите частоту сообщения в выходной день"
              value={selectedTpuGraphItem.frequencyWeekend}
              onBlur={value => changeFrequencyWeekendsHandler(Number(value))}
              disabled={!editTPUMode}
            />
            <CashedField
              id="capacity"
              title="Пропускная способность"
              placeholder="Введите пропускную способность участка дороги"
              value={selectedTpuGraphItem.capacity}
              onBlur={value => changeCapacityHandler(Number(value))}
              disabled={!editTPUMode}
            />
            <CashedField
              id="capacity_hour"
              title="Провозная способность ж/д отрезка, в час"
              placeholder="Введите провозную возможность участка дороги"
              value={selectedTpuGraphItem.capacityHour}
              onBlur={value => changeCapacityHourHandler(Number(value))}
              disabled={!editTPUMode}
            />
          </Stack>
        </Stack>
      </Stack>
      {editTPUMode && (
        <Stack
          gap={2}
          mt={2}
        >
          <Divider />
          <Button
            variant="text"
            color="error"
            onClick={() => removeGraph(selectedTpuGraph)}
          >
            Удалить граф
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

const vehicleToPalette = (vehicle: EVehicleAg, palette: Palette) => {
  switch (vehicle) {
    case EVehicleAg.auto:
      return [palette.customs.autoV2, palette.customs.autoV2];
    case EVehicleAg.avia:
      return [palette.customs.otherTransport, palette.customs.otherTransport];
    case EVehicleAg.funicular:
      return [palette.customs.otherTransport, palette.customs.otherTransport];
    case EVehicleAg.metro:
      return [palette.customs.metro, palette.customs.metro];
    case EVehicleAg.bus:
      return [palette.customs.bus, palette.customs.bus];
    case EVehicleAg.pedestrain:
      return [palette.customs.otherTransport, palette.customs.otherTransport];
    case EVehicleAg.ropeWay:
      return [palette.customs.otherTransport, palette.customs.otherTransport];
    case EVehicleAg.trolleybus:
      return [palette.customs.trolleybus, palette.customs.trolleybus];
    case EVehicleAg.suburbanRailway:
      return ['white', palette.customs.train];
    case EVehicleAg.tram:
      return [palette.customs.expressways, palette.customs.expressways];
    case EVehicleAg.waterWay:
      return [palette.customs.waterTransport, palette.customs.waterTransport];
    case EVehicleAg.monoRailWay:
      return [palette.customs.otherTransport, palette.customs.otherTransport];
    default:
      return [palette.customs.otherTransport, palette.customs.otherTransport];
  }
};
